import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getCategories, resetState } from "store/slices/filtersSlice";
import { FILTERS_BACKGROUND_COLOR, FILTER_TEXT_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

function FilterByCategory() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.filters);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleChange = useCallback(
    (_, newValue) => {
      let _params = params;
      if (newValue?.id) _params.category = newValue?.id;
      else if (!newValue && _params?.category) delete _params.category;
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(getCategories());
    }, 250);
    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
        dispatch(resetState());
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (params?.category && !selectedCategory && categories.length) {
      const category = categories.find(
        (category) => category.id === params?.category
      );
      if (category) {
        setSelectedCategory(() => category);
      }
    }
  }, [dispatch, categories, params?.category, selectedCategory]);

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(stores) => stores.name}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      options={categories}
      noOptionsText="No Options Available"
      value={selectedCategory}
      onChange={handleChange}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Category"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.031rem",
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByCategory);
