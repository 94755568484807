import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CategoryDescriptionSection from "components/Category/CategoryForm/CategoryDescriptionSection";
import CategoryMediaSection from "components/Category/CategoryForm/CategoryMediaSection";
import LanguageToggleButtons from "components/LanguageToggleButtons";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearAliExpressCategories,
  getAliExpressCategories,
  resetCategoriesState,
  resetCategory,
  submitCategory,
  updateCategory,
} from "store/slices/categoriesSlice";
import { resetLanguageState } from "store/slices/languageTranslatorSlice";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import MapAeCategorySection from "./MapAeCategorySection";

function CategoryForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const category = useSelector((state) => state.categories?.category);
  const loading = useSelector((state) => state.categories?.categoryLoading);
  const translatedData = useSelector(
    (state) =>
      state.languageTranslation?.translatedData ??
      state.categories?.category?.lang?.ar ??
      null
  );
  const flattenedCategories = useSelector(
    (state) => state.filters?.flattenedCategories
  );

  const selectedLanguage = useSelector((state) => state.UI?.language ?? "En");
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!params.id) {
      dispatch(resetCategory());
    }
    if (selectedLanguage === "Ar" && !translatedData) {
      setData({
        text: {
          name: category?.name,
          description: category?.description,
        },
        lang: "ar",
      });
    }
  }, [category, dispatch, params.id, selectedLanguage, translatedData]);

  const initialValues = useMemo(
    () => ({
      name:
        selectedLanguage === "Ar" ? translatedData?.name : category?.name ?? "",
      description:
        selectedLanguage === "Ar"
          ? parseEditorValue(translatedData?.description)
          : parseEditorValue(category?.description) ?? "",
      commission: category?.commission ?? "",
      mainImage: category?.mainImage ?? "",
      bannerImage: category?.bannerImage ?? "",
      bannerImageAr:
        selectedLanguage === "Ar"
          ? category?.lang?.ar?.bannerImage
          : translatedData?.bannerImage ?? "",
      wideBannerImage: category?.wideBannerImage ?? "",
      wideBannerImageAr:
        selectedLanguage === "Ar"
          ? category?.lang?.ar?.wideBannerImage
          : translatedData?.wideBannerImage ?? "",
      id: category?.id ?? "",
      platformIds: category?.ae_id ?? [],
    }),
    [category, translatedData, selectedLanguage]
  );

  const findCategoryTree = useCallback(
    (categoryId) => {
      const foundCategory = flattenedCategories.find(
        (category) => category.value === categoryId
      );
      return foundCategory?.categoryTree ?? [];
    },
    [flattenedCategories]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (params?.id) {
        let formValues = { ...values };
        let updatedValues = reduceObject(formValues, initialValues);
        const data = { ...updatedValues, id: params.id };

        let redirectionUrl = "/products/categories";
        const categoryTree = findCategoryTree(params?.id);
        if (categoryTree?.length) {
          const parentNodes = categoryTree.slice(0, -1);
          parentNodes.forEach((node) => {
            redirectionUrl += `/${node}`;
          });
        }

        if (Object.entries(data ?? {}).length)
          if (selectedLanguage === "Ar") {
            const _data = {
              bannerImageAr: values?.bannerImageAr,
              wideBannerImageAr: values?.wideBannerImageAr,
              id: params.id,
              lang: {
                ar: {
                  name: values?.name,
                  description: values?.description,
                },
              },
            };

            dispatch(updateCategory(_data))
              .unwrap()
              .then(() => {
                dispatch(resetCategoriesState());
                dispatch(resetLanguageState());
                navigate(redirectionUrl);
              });
          } else
            dispatch(updateCategory(data))
              .unwrap()
              .then(() => {
                dispatch(resetCategoriesState());
                navigate(redirectionUrl);
              });
        else {
          navigate(redirectionUrl);
          toast.info("No changes made");
        }
      } else if (params?.parentId) {
        let subCategoryData = {
          name: values.name,
          description: values.description,
          commission: values.commission,
          mainCategoryId: params?.parentId,
          mainImage: values.mainImage,
          bannerImage: values.bannerImage,
          wideBannerImage: values.wideBannerImage,
          id: values.id,
        };
        dispatch(submitCategory(subCategoryData))
          .unwrap()
          .then(() => {
            navigate("/products/categories");
            dispatch(resetCategoriesState());
          });
      } else {
        dispatch(submitCategory(values))
          .unwrap()
          .then(() => {
            navigate("/products/categories");
            dispatch(resetCategoriesState());
          });
      }
    },
    [
      params.id,
      params?.parentId,
      initialValues,
      findCategoryTree,
      selectedLanguage,
      dispatch,
      navigate,
    ]
  );

  useEffect(() => {
    if (category && !category?.subCategories?.length) {
      dispatch(getAliExpressCategories());
      return () => dispatch(clearAliExpressCategories());
    }
  }, [dispatch, category]);

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} py={1}>
        <CustomBreadcrumbs />
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.4rem" }}
          fontWeight="500"
          paddingTop={1}
          paddingBottom={3}
        >
          {params?.id
            ? "Update Category"
            : params?.parentId
            ? "Add Sub Category"
            : "Add Category"}{" "}
          {category?.name}
        </Typography>
        {params?.id ? <LanguageToggleButtons data={data} /> : null}
      </Box>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the category such as name and description"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Name
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("name")}
                              error={Boolean(
                                formik.touched.name && formik.errors.name
                              )}
                              helperText={
                                formik.touched.name && !!formik.errors.name
                                  ? formik.errors.name
                                  : "Enter a descriptive name of the category here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Commission (%)
                            </Typography>
                            <StyledTextField
                              disabled={selectedLanguage === "Ar"}
                              fullWidth
                              type="number"
                              {...formik.getFieldProps("commission")}
                              error={Boolean(
                                formik.touched.commission &&
                                  formik.errors.commission
                              )}
                              inputProps={{
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                min: 0,
                                onInput: (e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  );
                                },
                              }}
                              helperText={
                                formik.touched.commission &&
                                !!formik.errors.commission
                                  ? formik.errors.commission
                                  : "Enter the commission of the category here"
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
              {/* Description Section */}
              <Grid item xs={12} lg={12}>
                <CategoryDescriptionSection formik={formik} />
              </Grid>

              {/* Category Media Section */}

              <Grid item xs={12} lg={12}>
                <CategoryMediaSection formik={formik} />
              </Grid>

              {/* Map Ali Express Category Section */}

              {category && !category?.subCategories?.length && (
                <Grid item xs={12} lg={12}>
                  <MapAeCategorySection formik={formik} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disabled={!formik.isValid || loading}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 45, xl: 55 },
                    }}
                  >
                    {loading ? "Submitting" : "Submit"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 45, xl: 55 },
                      backgroundColor: "#ffffff",
                    }}
                    onClick={() => formik.resetForm()}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(CategoryForm);
const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("Required*").max(50, "Name is too long"),
  commission: Yup.number()
    .min(0, "Commission can not be a negative number")
    .max(100, "Commission must be less than or equal to 100"),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(15, "Description is too short")
    .max(750, "Description is too long"),
  mainImage: Yup.mixed().required("Main Image is required"),
  bannerImage: Yup.mixed(),
  wideBannerImage: Yup.mixed(),
  bannerImageAr: Yup.mixed(),
  wideBannerImageAr: Yup.mixed(),
  platformIds: Yup.array().of(Yup.string()),
});
