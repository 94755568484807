import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import VideoThumbnail from "components/Videos/VideoForm/videoThumbnailForm";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getVideoBySlug, updateVideo } from "store/slices/videosSlice";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function EditVideoForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const video = useSelector((state) => state.videos.video);
  const slug = useParams().slug;

  const initialValues = useMemo(
    () => ({
      title: video?.title ?? "",
      videoThumbnail: video?.thumbnail ?? "",
      previewThumbnail: "",
    }),
    [video?.thumbnail, video?.title]
  );

  const handleSubmit = useCallback(
    (values) => {
      const _id = video?.id;
      const data = {
        title: values.title,
      };
      const formData = new FormData();
      formData.append("photo", values.previewThumbnail);

      if (initialValues.title !== values.title) {
        dispatch(updateVideo({ data, _id }))
          .then(() => {
            navigate("/videos");
            toast.success("Updated");
          })
          .catch(() => {
            toast.error("Error updating video");
          });
      }

      if (values.previewThumbnail) {
        dispatch(updateVideo({ formData, _id }))
          .unwrap()
          .then(() => {
            navigate("/videos");
            toast.success("Updated");
          })
          .catch(() => {
            toast.error("Error updating video");
          });
      }

      navigate("/videos");
    },
    [dispatch, initialValues.title, navigate, video.id]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(getVideoBySlug(slug));
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, slug]);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Box paddingTop={2} paddingBottom={1} px={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        mb={2}
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        py={1}
      >
        Update Video {video?.title ?? ""}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the video such as title"
                >
                  <CardContent>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      gap={3}
                      p={{ xs: 1, md: 1.5 }}
                    >
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Title
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="text"
                          {...formik.getFieldProps("title")}
                          error={Boolean(
                            formik.touched.title && formik.errors.title
                          )}
                          helperText={
                            formik.touched.title && !!formik.errors.title
                              ? formik.errors.title
                              : "Enter title to help customer find your video"
                          }
                        />
                      </Stack>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
              <Grid item xs={12} lg={12}>
                <VideoThumbnail formik={formik} id={video.id} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(EditVideoForm);

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Title should be at least 10 characters long")
    .max(100, "Title is too long"),
  videoThumbnail: Yup.string().required("Video Thumbnail Image is required"),
});
