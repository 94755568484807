import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ProductBulkUpload from "components/Product/ProductBulkUpload";
import ProductDataGrid from "components/Product/ProductDataGrid";
import ProductFilters from "components/Product/ProductFilters";
import AddProductForm from "components/Product/ProductForm/AddProductForm";
import EditProduct from "components/Product/ProductForm/EditProduct";
import ShopifyToken from "components/Product/ProductForm/Shopify/ShopifyForm";
import ProductStatusesButtons from "components/Product/ProductStatusesButtons";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetProductState } from "store/slices/productsSlice";

function Products({ create, edit, bulk, shopify }) {
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetProductState()), [dispatch]);

  if (create) {
    return <AddProductForm />;
  }

  if (edit) {
    return <EditProduct />;
  }
  if (bulk) {
    return <ProductBulkUpload />;
  }
  if (shopify) {
    return <ShopifyToken />;
  }

  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
        paddingBottom={2}
      >
        Products
      </Typography>
      <ProductFilters />
      <ProductStatusesButtons />
      <ProductDataGrid />
    </Box>
  );
}

Products.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  bulk: PropTypes.bool,
  shopify: PropTypes.bool,
};

Products.defaultProps = {
  create: false,
  edit: false,
  bulk: false,
  shopify: false,
};

export default memo(Products);
