import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardTitle from "components/CardTitle";
import StyledCard from "components/Dashboard/StyledCardContent";
import { memo } from "react";
import { useSelector } from "react-redux";
import KeyNumberTile from "./KeyNumberTile";

function KeyNumbers() {
  const results = useSelector((state) => state.dashboard.results);
  const data = useSelector((state) => state.dashboard.revenue.data);

  return (
    <Card sx={{ p: "1rem", minHeight: "190px" }}>
      <Box pb={2}>
        <CardTitle title="Overall Statistics" />
      </Box>
      <StyledCard sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <KeyNumberTile count={results?.totalUsers} title={"Users"} />
        <KeyNumberTile
          title="Revenue Generated"
          count={data?.[0]?.totalRevenue?.[0]?.profit ?? 0}
        />
        <KeyNumberTile count={results?.totalsales} title={"Sales"} />
        <KeyNumberTile
          count={results?.productStats?.totalProducts}
          title={"SKUs"}
        />
        <KeyNumberTile count={results?.videos?.totalVideos} title={"Videos"} />
        <KeyNumberTile count={results?.videos?.totalViews} title={"Views"} />
      </StyledCard>
    </Card>
  );
}
export default memo(KeyNumbers);
