import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import BusinessIcon from "@mui/icons-material/Business";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import WebIcon from "@mui/icons-material/Web";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardInsights,
  getRevenueByDate,
} from "store/slices/dashboardSlice";
import {
  KEY_NUMBERS_BACKGROUND_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
} from "styles/colors";
import splitCamelCase from "utils/helpers/splitCamelCase";
import useQueryParams from "utils/hooks/useQueryParams";
// import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
// import MovingIcon from "@mui/icons-material/Moving";
// import PeopleIcon from "@mui/icons-material/People";
// import PersonIcon from "@mui/icons-material/Person";
// import TourIcon from "@mui/icons-material/Tour";

const KEYWORDS = {
  screenPageViews: "totalImpressions",
  sessions: "sessionsStarted",
  eventCount: "eventCount",
  addToCarts: "addToCarts",
  "keyEvents:add_shipping_info": "shippingInfoAdded",
  paymentInfoAdded: "paymentInfoAdded",
  ecommercePurchases: "purchases",
  // avgBasketValue: "avgBasketValue",
  // bounceRate: "bounceRate",
  // totalUsers: "totalUsers",
  // registeredUsers: "registeredUsers",
  // unregisteredUsers: "unregisteredUsers",
  // newUsers: "newUsers",
  // sessionsPerUser: "sessionsPerUser",
  // averageSessionDuration: "avgSessionDuration",
};

function DashboardAnalytics() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { insights } = useSelector((state) => state.dashboard.insights);
  const avgBasketValue = useSelector(
    (state) =>
      state.dashboard.revenueByDate?.data?.order?.[0]?.averageOrders ?? 0
  );

  const getKeywordIcon = useCallback((title) => {
    switch (title) {
      case "screenPageViews":
        return <WebIcon />;
      case "totalUsers":
        return <PeopleOutlineIcon />;
      case "sessions":
        return <TroubleshootIcon />;
      case "eventCount":
        return <EmojiFlagsIcon />;
      case "addToCarts":
        return <AddShoppingCartIcon />;
      case "keyEvents:add_shipping_info":
        return <BusinessIcon />;
      case "paymentInfoAdded":
        return <PaymentsIcon />;
      case "ecommercePurchases":
        return <ShoppingBagIcon />;
      case "avgBasketValue":
        return <MonetizationOnIcon />;
      // case "bounceRate":
      //   return <MovingIcon />;
      // case "sessionsPerUser":
      //   return <TourIcon />;
      // case "averageSessionDuration":
      //   return <HistoryToggleOffIcon />;
      // case "newUsers":
      //   return <PersonIcon />;
      // case "activeUsers":
      //   return <PeopleIcon />;
      default:
        return;
    }
  }, []);

  useEffect(() => {
    const data = {
      keywords: [...Object.keys(KEYWORDS)]
        .filter((keyword) => keyword !== "paymentInfoAdded")
        .map((keyword) => ({
          name: keyword,
        })),

      ...(params?.from && { from: params.from }),
      ...(params?.to && { to: params.to }),
    };

    dispatch(getDashboardInsights(data));
  }, [dispatch, params.from, params.to]);

  useEffect(() => {
    dispatch(
      getRevenueByDate({
        ...(params?.from && { from: params.from }),
        ...(params?.to && { to: params.to }),
      })
    );
  }, [dispatch, params.from, params.to]);

  return (
    <Card
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="space-between"
        gap={3}
      >
        <CardTitle title="Funnel Analytics" />

        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-around"
          gap={3}
        >
          {Object.entries(KEYWORDS).map(([key, name]) => (
            <Tile
              key={key}
              name={splitCamelCase(name)}
              value={
                (key === "paymentInfoAdded"
                  ? insights?.["ecommercePurchases"]
                  : insights?.[key]) ?? 0
              }
              icon={getKeywordIcon(key)}
              showTime={key === "averageSessionDuration"}
              showPercentage={key === "bounceRate"}
            />
          ))}
          <Tile
            key={"avgBasketValue"}
            name={"Avg Basket Value"}
            value={avgBasketValue ?? 0}
            icon={<PointOfSaleIcon />}
          />
        </Box>
      </Box>
    </Card>
  );
}

export default memo(DashboardAnalytics);

function Tile({ name, value, icon, showTime, showPercentage }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let str = "";

    if (hours) str += `${hours}h `;
    if (minutes) str += `${minutes}m `;
    if (remainingSeconds) str += `${remainingSeconds}s`;

    return str;
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={KEY_NUMBERS_BACKGROUND_COLOR}
        borderRadius="50%"
        height="auto"
        width="60px"
        sx={{
          aspectRatio: "1/1",
          "& .MuiSvgIcon-root": {
            color: "primary.light",
            fontSize: {
              xs: "1.5rem",
              md: isOpen ? "1.375rem" : "1.25rem",
              xl: "1.5rem",
              xxl: isOpen ? "1.75rem" : "2.125rem",
            },
          },
        }}
      >
        {icon}
      </Box>
      {!showTime && !showPercentage && (
        <NumberFormatter
          sx={{
            color: STATS_NUMBER_FORMATTER_COLOR,
            fontSize: { md: "1.25rem", xxl: "1.813rem" },
            fontWeight: "bold",
          }}
          number={Math.round(Number(value))}
        />
      )}
      {showTime && (
        <Typography
          fontSize={{ md: "1.25rem", xxl: "1.813rem" }}
          fontWeight="bold"
          color={STATS_NUMBER_FORMATTER_COLOR}
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {Math.round(Number(value))
            ? formatTime(Math.round(Number(value)))
            : 0}
        </Typography>
      )}
      {showPercentage && (
        <Typography
          fontSize={{ md: "1.25rem", xxl: "1.813rem" }}
          fontWeight="bold"
          color={STATS_NUMBER_FORMATTER_COLOR}
        >
          {`${Math.round(Number(value))}%`}
        </Typography>
      )}
      <Typography
        fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
        color="#6e6b7b"
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}

Tile.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
  showTime: PropTypes.bool,
  showPercentage: PropTypes.bool,
};

Tile.defaultProps = {
  name: "",
  value: 0,
  icon: null,
  showTime: false,
  showPercentage: false,
};
