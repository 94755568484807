import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import styled from "@mui/material/styles/styled";
import CardVideoStats from "components/Dashboard/CardVideoStats";
import LineChart from "components/Dashboard/LineChart";
import SpeedoMeter from "components/Dashboard/SpeedoMeter";
import StatsCard from "components/Dashboard/StatsCard";
import MonthPicker from "components/Pickers/MonthPicker";
import dayjs from "dayjs";
import PageTitle from "layout/PageTitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyAnalytics } from "store/slices/dashboardSlice";
import getTargetValue from "utils/helpers/getTargetValue";
import useQueryParams from "utils/hooks/useQueryParams";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
}));

export default function SupplierDashboard() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const results = useSelector((state) => state.dashboard).results;
  const { loading, data: monthlyAnalytics } = useSelector(
    (state) => state.dashboard.monthlyAnalytics
  );

  useEffect(() => {
    dispatch(
      getMonthlyAnalytics(
        params?.month
          ? dayjs(params?.month).startOf("month").format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD")
      )
    );
  }, [dispatch, params?.month]);

  return (
    <Box py={4} display="flex" flexDirection="column" gap={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageTitle title="Dashboard" loading={loading} />
        <MonthPicker />
      </Box>
      <Grid container spacing={2}>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Orders"}
            currentMonth={monthlyAnalytics?.orders?.currMonthTotalOrders}
            target={getTargetValue(
              monthlyAnalytics?.orders?.currMonthTotalOrders,
              1.5
            )}
            lastMonth={monthlyAnalytics?.orders?.prevMonthTotalOrders}
          />
        </Grid>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Sales"}
            currentMonth={monthlyAnalytics?.orders?.currMonthSales}
            target={getTargetValue(
              monthlyAnalytics?.orders?.currMonthSales,
              1.5
            )}
            lastMonth={monthlyAnalytics?.orders?.prevMonthSales}
          />
        </Grid>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Products"}
            currentMonth={monthlyAnalytics?.products?.currMonthProducts}
            target={getTargetValue(
              monthlyAnalytics?.products?.currMonthProducts,
              1.5
            )}
            lastMonth={monthlyAnalytics?.products?.prevMonthProducts}
          />
        </Grid>
        <Grid item xl={3} sm={6} md={6} lg={3}>
          <StatsCard
            title={"Videos"}
            currentMonth={monthlyAnalytics?.videos?.currMonthVideos}
            target={getTargetValue(
              monthlyAnalytics?.videos?.currMonthVideos,
              1.5
            )}
            lastMonth={monthlyAnalytics?.videos?.prevMonthVideos}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} lg={12} xl={8}>
          <Item>
            <LineChart isSupplier />
          </Item>
        </Grid>
        <Grid item xs={3} sm={12} md={12} lg={12} xl={4}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={6}>
              <CardVideoStats
                title={"Videos"}
                totalVideos={results?.videos?.totalVideos}
                currentMonth={monthlyAnalytics?.videos?.currMonthVideos}
                lastMonth={monthlyAnalytics?.videos?.prevMonthVideos}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={3} xl={6}>
              <CardVideoStats
                title={"Views"}
                totalVideos={results?.videos?.totalViews}
                currentMonth={monthlyAnalytics?.videos?.currMonthViews}
                lastMonth={monthlyAnalytics?.videos?.prevMonthViews}
              />
            </Grid>
            <Grid item md={12} lg={6} xl={12}>
              <Item>
                <SpeedoMeter />
              </Item>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
