import { CURRENCY } from "utils/constants/constants";

export const formatCurrency = (number = 0, locale = "en-US") => {
  const hasFraction = number % 1 !== 0;
  const fractionDigits = hasFraction ? 2 : 0;

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: CURRENCY,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(number);
};

export default formatCurrency;
