import PropTypes from "prop-types";
import { memo } from "react";
import ProductDetailCard from "./ProductDetailCard";

function ProductDetail({ packageDetail }) {
  return (
    <>
      {packageDetail?.map((packageItem, i) => (
        <ProductDetailCard packageItem={packageItem} key={i} />
      ))}
    </>
  );
}

ProductDetail.propTypes = {
  packageDetail: PropTypes.array.isRequired,
};

export default memo(ProductDetail);
