import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import useMediaQuery from "@mui/material/useMediaQuery";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import placeholderImg from "assets/images/imagePlaceholder.png";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteGroupBuyProduct, getGroupBuy } from "store/slices/groupBuySlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { ACTIVE_COLOR } from "styles/colors";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const dispatch = useDispatch();
  const { results, loading, totalPages } = useSelector(
    (state) => state.groupBuy
  );

  const params = useQueryParams();
  const navigate = useNavigate();

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const StyledDel = styled("del")(() => ({}));

  const fetchData = useCallback(() => {
    dispatch(getGroupBuy(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchData]);

  const handleClickOpen = useCallback(
    (groupBuyId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(groupBuyId));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 60,
        flex: 0.2,
      },
      {
        field: "image",
        headerName: "Image",
        headerAlign: "center",
        align: "center",
        minWidth: 120,
        flex: 0.8,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.productId?.mainImage
                  ? params?.row?.productId?.mainImage
                  : placeholderImg
              }
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 6,
                objectFit: "contain",
              }}
              alt="group-buy_image"
            />
          </Box>
        ),
      },
      {
        field: "productName",
        headerName: "Name/Details",
        minWidth: 180,
        flex: 2,
        sortable: false,
        valueGetter: (params) => params?.row?.productId?.productName,
        type: "string",
        renderCell: (params) => (
          <Box width="100%">
            <Tooltip
              title={
                <p style={{ margin: 0, fontSize: "14px" }}>
                  {params?.row?.productId?.productName}
                </p>
              }
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  color: "#000000",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box !important",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: "normal",
                }}
              >
                {params?.row?.productId?.productName}
              </Typography>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: "groupSalePrice",
        headerName: "Price",
        minWidth: 90,
        flex: 1,
        renderCell: (params) => (
          <Box>
            <CurrencyFormatter number={params?.row?.groupSalePrice} />
            <StyledDel sx={{ color: "error.dark" }}>
              <CurrencyFormatter
                number={params?.row?.productId?.regularPrice}
              />
            </StyledDel>
          </Box>
        ),
      },
      {
        field: "startDate",
        headerName: "Time Duration",
        headerAlign: "center",
        align: "center",
        minWidth: 120,
        flex: 1.5,
        renderCell: (params) => (
          <Box>
            <Typography color={ACTIVE_COLOR}>
              {dayjs(params?.row?.startDate).format("YYYY/MM/DD [at] hh:mm:ss")}
            </Typography>
            <Typography>
              {dayjs(params?.row?.endDate).format("YYYY/MM/DD [at] hh:mm:ss")}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        headerAlign: "center",
        align: "center",
        minWidth: 70,
        flex: 0.8,
        renderCell: (params) => (
          <Chip
            sx={{
              backgroundColor: statusBadges(
                params?.row?.status ? "active" : "finished"
              ).bgColor,
              color: statusBadges(params?.row?.status ? "active" : "finished")
                .color,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.status}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        minWidth: 80,
        flex: 0.8,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Button
              variant="text"
              className="button-styles"
              onClick={() => navigate(`update/${params.id}`)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </Button>
            <Button
              variant="text"
              sx={{
                minWidth: "20px",
              }}
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="delete" className="icon-size" />
            </Button>
          </Box>
        ),
      },
    ],
    [isTablet, isLaptop, isLed, navigate, handleClickOpen]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteGroupBuyProduct} />
    </>
  );
}

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "22px", width: "22px" },
};
