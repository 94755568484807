import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import grey from "@mui/material/colors/grey";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import InsightCard from "./InsightCard";
import InsightsLineChart from "./InsightsLineChart";

function OrdersInsights({ insights }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const ordersGraph = useSelector((state) => state.insights.ordersGraph);
  const revenue = useSelector((state) => state.insights.revenue);

  const getKeywordIcon = useCallback((title) => {
    switch (title) {
      case "addToWishlist":
        return <FavoriteBorderIcon />;
      case "addToCarts":
        return <AddShoppingCartIcon />;
      case "cartToViewRate":
        return <ProductionQuantityLimitsIcon />;
      case "checkouts":
        return <ShoppingCartCheckoutIcon />;
      default:
        return;
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <Grid container spacing={2}>
            {["addToWishlist", "addToCarts", "cartToViewRate", "checkouts"].map(
              (keyword) => (
                <Grid item xs={6} xl={6} key={keyword}>
                  <InsightCard
                    keyword={keyword}
                    value={insights?.[keyword]}
                    icon={getKeywordIcon(keyword)}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <InsightsLineChart
            title="Orders"
            data={ordersGraph}
            isAnalyticalData={false}
          />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: "1rem" }}>
            <Box pb={2}>
              <CardTitle title="Abandoned Cart" />
            </Box>
            <CardContent
              sx={{
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    fontSize={{
                      xs: "0.9rem",
                      lg: "1rem",
                      xl: "1rem",
                      xxl: "1.15rem",
                    }}
                    color={grey[700]}
                  >
                    Total abandoned carts:{" "}
                    <span style={{ fontWeight: "bold", color: "black" }}>
                      {revenue?.abandonedCart?.[0]?.count ?? 0}
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    fontWeight={400}
                    fontSize={{
                      xs: "0.9rem",
                      lg: "1rem",
                      xl: "1rem",
                      xxl: "1.15rem",
                    }}
                    color={grey[700]}
                  >
                    Sales Value:{" "}
                    <span style={{ fontWeight: "bold", color: "black" }}>
                      {revenue?.abandonedCart?.[0]?.totalAbandoned
                        ? Number(
                            revenue?.abandonedCart?.[0]?.totalAbandoned
                          ).toLocaleString()
                        : 0}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

OrdersInsights.propTypes = {
  insights: PropTypes.object.isRequired,
};

export default OrdersInsights;
