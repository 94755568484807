import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import { useSelector } from "react-redux";

function BasicInformationSection({ formik }) {
  const flattenedCategories = useSelector(
    (state) => state?.filters?.flattenedCategories ?? []
  );

  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="Please enter the basic information of the product such as name and description"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product Name
            </Typography>
            <StyledTextField
              fullWidth
              type="text"
              placeholder="Enter Product Name"
              {...formik.getFieldProps("name")}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={
                formik.touched.name && !!formik.errors.name
                  ? formik.errors.name
                  : "Enter a product name to help customer find your product"
              }
            />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product SKU
            </Typography>
            <StyledTextField
              fullWidth
              type="text"
              placeholder="Enter Product SKU"
              {...formik.getFieldProps("sku")}
              error={Boolean(formik.touched.sku && formik.errors.sku)}
              helperText={
                formik.touched.sku && !!formik.errors.sku
                  ? formik.errors.sku
                  : "Enter unique Product SKU"
              }
            />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Product Category
            </Typography>
            <Typography variant="body1" color="text.secondary" fontSize="1rem">
              There are 3 levels categories, select according to the product
            </Typography>
            <Autocomplete
              name="category"
              options={flattenedCategories}
              value={formik.values.category}
              onChange={(_, category) => {
                formik.setFieldValue("category", category);
              }}
              getOptionLabel={(option) => option.label}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  {...params}
                  label="Category"
                  error={Boolean(
                    formik.touched.category && formik.errors.category
                  )}
                  helperText={
                    formik.touched.category && !!formik.errors.category
                      ? formik.errors.category
                      : "Please select a category for the product"
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
            />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

BasicInformationSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BasicInformationSection, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.name === nextProps.formik.values.name &&
    prevProps.formik.values.sku === nextProps.formik.values.sku &&
    prevProps.formik.values.category === nextProps.formik.values.category &&
    prevProps.formik.errors.name === nextProps.formik.errors.name &&
    prevProps.formik.errors.sku === nextProps.formik.errors.sku &&
    prevProps.formik.errors.category === nextProps.formik.errors.category &&
    prevProps.formik.touched.name === nextProps.formik.touched.name &&
    prevProps.formik.touched.sku === nextProps.formik.touched.sku &&
    prevProps.formik.touched.category === nextProps.formik.touched.category
  );
}
