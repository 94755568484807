import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LanguageToggleButtons from "components/LanguageToggleButtons";
import MediaSection from "components/Product/ProductForm/MediaSection";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetLanguageState } from "store/slices/languageTranslatorSlice";
import {
  getAdminProducts,
  resetProductState,
  updateProduct,
} from "store/slices/productsSlice";
import {
  volumeUnits,
  warrantyPeriodUnits,
  warrantyTypes,
} from "utils/constants/productForm";
import parseEditorValue from "utils/helpers/parseEditorValue";
import {
  expandAttributeByName,
  expandColorAttribute,
  expandRamAttribute,
  expandRomAttribute,
  expandSizeAttribute,
  expandWeightAttribute,
  reduceAttributes,
  reducePackageInfo,
  reduceWarranty,
} from "utils/helpers/productForm";
import * as Yup from "yup";
import BasicInformationSection from "./BasicInformationSection";
import DeliverySection from "./DeliverySection";
import DescriptionSection from "./DescriptionSection";
import PriceSection from "./PriceSection";
import ProductActiveStatusSection from "./ProductActiveStatusSection";
import ProductLocation from "./ProductLocation";
import ProductOptionsSections from "./ProductOptionsSections";
import ServiceSection from "./ServiceSection";
import StockControlSection from "./StockControlSection";
import AddVariants from "./Variant/AddVariantSection";

const EditProductForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const loading = useSelector((state) => state.products.productLoading);
  const product = useSelector((state) => state.products.product);
  const [data, setData] = useState(null);
  const flattenedCategories = useSelector(
    (state) => state.filters.flattenedCategories
  );

  const translatedData = useSelector(
    (state) =>
      state?.languageTranslation?.translatedData ??
      state?.products?.product?.lang?.ar ??
      null
  );
  const selectedLanguage = useSelector((state) => state.UI?.language ?? "En");

  const initialValues = useMemo(
    () => ({
      // Basic Information
      id: product?.id ?? "",
      name:
        selectedLanguage === "Ar"
          ? translatedData?.productName
          : product?.productName ?? "",
      sku: product?.sku?.length >= 19 ? "" : product?.sku,
      category:
        flattenedCategories?.find(
          (ctg) => ctg?.value === product?.category?.id
        ) ?? null,
      region: product?.region ?? [],
      origin: product?.origin ?? "",
      // Active Status
      active: product?.active ?? false,
      // Pricing
      price: product?.regularPrice ?? "",
      onSale: product?.onSale ?? false,
      salePrice: product?.salePrice ?? "",
      // Description
      description:
        selectedLanguage === "Ar"
          ? parseEditorValue(translatedData?.description)
          : product?.description
          ? parseEditorValue(product?.description)
          : "",
      // Stock Control
      quantity: product?.quantity ?? "",
      // Product Variants
      productVariants: product?.attributes ?? [],
      // Product Options
      attributes: product?.attributes?.map((obj) => obj?.name) ?? [],
      color: product?.attributes
        ? expandColorAttribute(product?.attributes)
        : [],
      size: product?.attributes
        ? expandSizeAttribute(product?.attributes, product?.categoryTree)
        : [],
      weight: product?.attributes
        ? expandWeightAttribute(product?.attributes)
        : [],
      ram: product?.attributes ? expandRamAttribute(product?.attributes) : [],
      rom: product?.attributes ? expandRomAttribute(product?.attributes) : [],
      pack: expandAttributeByName(product?.attributes, "pack"),
      pieces: expandAttributeByName(product?.attributes, "pieces"),
      strap: expandAttributeByName(product?.attributes, "strap"),
      mah: expandAttributeByName(product?.attributes, "mah"),
      length: expandAttributeByName(product?.attributes, "length"),
      height: expandAttributeByName(product?.attributes, "height"),
      width: expandAttributeByName(product?.attributes, "width"),
      litre: expandAttributeByName(product?.attributes, "litre"),
      ml: expandAttributeByName(product?.attributes, "ml"),
      // Media
      mainImage: product?.mainImage ?? "",
      // Delivery
      packageVolumeUnit:
        volumeUnits.find(
          (unit) => unit.value === product?.packageInfo?.volume?.unit
        ) ?? null,
      packageHeight: product?.packageInfo?.volume?.height ?? "",
      packageWidth: product?.packageInfo?.volume?.width ?? "",
      packageLength: product?.packageInfo?.volume?.length ?? "",
      packageWeightUnit:
        product?.packageInfo?.weight &&
        product?.packageInfo?.weight?.toString()?.includes(".")
          ? "g"
          : "kg",
      packageWeight:
        product?.packageInfo?.weight &&
        product?.packageInfo?.weight?.toString()?.includes(".")
          ? product?.packageInfo?.weight * 1000
          : product?.packageInfo?.weight ?? "",
      // Service
      warrantyType:
        warrantyTypes?.find(
          (type) => type?.value === product?.warranty?.warrantyType
        ) ?? null,
      warrantyPeriod:
        warrantyPeriodUnits?.find(
          (unit) => unit?.value === product?.warranty?.warrantyPeriod?.unit
        ) ?? null,
      warrantyTenure: product?.warranty?.warrantyPeriod?.tenure ?? "",
    }),
    [selectedLanguage, flattenedCategories, product, translatedData]
  );

  useEffect(() => {
    if (selectedLanguage === "Ar") {
      setData({
        text: {
          productName: product?.productName,
          description: product?.description,
        },
        lang: "ar",
      });
    }
  }, [dispatch, product, selectedLanguage]);

  const handleSubmit = useCallback(
    (values) => {
      const _data = {
        productName: values.name.trim(),
        sku: values.sku.trim(),
        category: values.category?.value,
        origin: values?.origin,
        region: values?.region,
        active: values?.active,
        regularPrice: +values?.price,
        description: values?.description?.trim(),
        quantity: +values?.quantity,
        onSale: values?.onSale,
        ...(values?.salePrice && { salePrice: values.salePrice }),
        ...(values?.attributes?.length && { isVariable: true }),
        attributes: reduceAttributes(values),
        packageInfo: reducePackageInfo(values),
        warranty: reduceWarranty(values),
      };

      if (selectedLanguage === "Ar") {
        const _data = {
          lang: {
            ar: {
              productName: values.name.trim(),
              description: values?.description?.trim(),
            },
          },
        };
        dispatch(updateProduct({ productId: values.id, body: _data }))
          .unwrap()
          .then(() => {
            navigate("/products");
            dispatch(resetProductState());
            dispatch(resetLanguageState());
            dispatch(getAdminProducts());
          });
      } else
        dispatch(updateProduct({ productId: values.id, body: _data }))
          .unwrap()
          .then(() => {
            navigate("/products");
            dispatch(resetProductState());
            dispatch(getAdminProducts());
          });
    },
    [selectedLanguage, dispatch, navigate]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h1"
          color="primary"
          fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
          fontWeight={600}
          paddingTop={1}
          paddingBottom={2}
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          Edit Product {product?.productName ?? ""}
        </Typography>
        <LanguageToggleButtons data={data} />
      </Box>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={3}>
              {/* Basic Information Section */}
              <Grid item xs={12} lg={7.5}>
                <BasicInformationSection formik={formik} />
              </Grid>

              {/* Pricing Section */}
              <Grid item xs={12} lg={4.5}>
                <ProductActiveStatusSection formik={formik} />
                <PriceSection formik={formik} />
              </Grid>

              {/* Location Section */}
              <Grid item xs={12} lg={7.5}>
                <ProductLocation formik={formik} />
              </Grid>

              {/* Stock Control Section */}
              <Grid item xs={12} lg={4.5}>
                <StockControlSection formik={formik} />
              </Grid>

              {/* Description Section */}
              <Grid item xs={12} lg={7.5}>
                <DescriptionSection formik={formik} />
              </Grid>

              {/* Product Options Section */}
              <Grid item xs={12} lg={7.5}>
                <ProductOptionsSections formik={formik} />
              </Grid>

              {/* Variants Section */}
              {product?.attributes?.length !== 0 && (
                <Grid item xs={12} lg={12}>
                  <AddVariants formik={formik} />
                </Grid>
              )}

              {/* Product Media Section */}
              <Grid item xs={12}>
                <MediaSection formik={formik} handleDisabled={setIsDisabled} />
              </Grid>

              {/* Product Delivery Section */}
              <Grid item xs={12} lg={7.5}>
                <DeliverySection formik={formik} />
              </Grid>

              {/* Product Service Section */}
              <Grid item xs={12} lg={4.5}>
                <ServiceSection formik={formik} />
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={formik.handleSubmit}
                    disabled={isDisabled || loading}
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Save as Draft
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

EditProductForm.displayName = "EditProductForm";

export default memo(EditProductForm);

const validationSchema = Yup.object().shape({
  // Basic Information Section
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(6, "Name is too short")
    .max(200, "Name is too long"),
  sku: Yup.string()
    .trim()
    .required("Required*")
    .min(1, "SKU is too short")
    .max(18, "SKU is too long"),
  category: Yup.object().required("Required*").nullable(),

  // Location
  origin: Yup.string().required("Product Origin is required"),
  region: Yup.array()
    .min(1, "Please select at least one region")
    .required("Region is required"),

  // Pricing Section
  price: Yup.number("Enter a valid number")
    .required("Required*")
    .min(1, "Price must be greater than 0")
    .max(9999999, "Price is too high"),
  onSale: Yup.boolean(),
  salePrice: Yup.number().when("onSale", {
    is: (value) => value,
    then: Yup.number()
      .required("Required")
      .min(1, "Sale price must be greater than 0")
      .max(999999, "Price is too high")
      .lessThan(
        Yup.ref("price"),
        "Sale price must be lower than the regular price"
      ),
    otherwise: Yup.number().notRequired().max(999999, "Price is too high"),
  }),
  // Description Section
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(80, "Description must be at least 80 characters long")
    .max(2000, "Description cannot exceed 2000 characters"),
  // Stock Control Section
  quantity: Yup.number("Enter a valid number")
    .required("Required*")
    .integer("Must be an integer")
    .min(0, "Quantity must be greater  or equal to 0")
    .max(9999, "Quantity is too high"),

  // Product Options Section
  attributes: Yup.array().of(Yup.string()),
  color: Yup.array().when("attributes", {
    is: (array) => array.includes("color"),
    then: Yup.array().min(1, "Please select at least one color option"),
    otherwise: Yup.array().notRequired(),
  }),
  size: Yup.array().when("attributes", {
    is: (array) => array.includes("size"),
    then: Yup.array().min(1, "Please select at least one size option"),
    otherwise: Yup.array().notRequired(),
  }),
  weight: Yup.array().when("attributes", {
    is: (array) => array.includes("weight"),
    then: Yup.array().min(1, "Please select at least one weight option"),
    otherwise: Yup.array().notRequired(),
  }),
  pack: Yup.array().when("attributes", {
    is: (array) => array.includes("pack"),
    then: Yup.array().min(1, "Please create at least one appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  pieces: Yup.array().when("attributes", {
    is: (array) => array.includes("pieces"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  strap: Yup.array().when("attributes", {
    is: (array) => array.includes("strap"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  mah: Yup.array().when("attributes", {
    is: (array) => array.includes("mah"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  length: Yup.array().when("attributes", {
    is: (array) => array.includes("length"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  height: Yup.array().when("attributes", {
    is: (array) => array.includes("height"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  width: Yup.array().when("attributes", {
    is: (array) => array.includes("width"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  litre: Yup.array().when("attributes", {
    is: (array) => array.includes("litre"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),
  ml: Yup.array().when("attributes", {
    is: (array) => array.includes("ml"),
    then: Yup.array().min(1, "Please create at least appropriate option"),
    otherwise: Yup.array().notRequired(),
  }),

  // Product Media Section
  mainImage: Yup.string().required("Required*"),

  // Product Delivery Section
  packageVolumeUnit: Yup.object().nullable(),
  packageLength: Yup.number().when("packageVolumeUnit", {
    is: (unit) => !!unit?.value && unit?.value !== "none",
    then: Yup.number()
      .required("Required*")
      .positive("Length must be greater than 0")
      .moreThan(0, "Length should be greater than 0")
      .max(999, "Too long"),
    otherwise: Yup.number().notRequired(),
  }),
  packageWidth: Yup.number().when("packageVolumeUnit", {
    is: (unit) => !!unit?.value && unit?.value !== "none",
    then: Yup.number()
      .required("Required*")
      .positive("Width should be greater than 0")
      .moreThan(0, "Width should be greater than 0")
      .max(999, "Too wide"),
    otherwise: Yup.number().notRequired(),
  }),
  packageHeight: Yup.number().when("packageVolumeUnit", {
    is: (unit) => !!unit?.value && unit?.value !== "none",
    then: Yup.number()
      .required("Required*")
      .positive("Height should be greater than 0")
      .moreThan(0, "Height should be greater than 0")
      .max(999, "Too tall"),
    otherwise: Yup.number().notRequired(),
  }),
  packageWeight: Yup.number()
    .when("packageWeightUnit", {
      is: (unit) => unit === "g",
      then: Yup.number()
        .required("Required*")
        .positive("Weight should be greater than 0")
        .integer("Value must be an integer")
        .moreThan(0, "Weight should be greater than 0")
        .max(9999, "Too heavy"),
      otherwise: Yup.number().notRequired(),
    })
    .when("packageWeightUnit", {
      is: (unit) => unit === "kg",
      then: Yup.number()
        .required("Required*")
        .positive("Weight should be greater than 0")
        .moreThan(0, "Weight should be greater than 0")
        .max(99, "Too heavy"),
      otherwise: Yup.number().notRequired(),
    }),
  // packageWeight: Yup.string()
  //   .required("Required*")
  //   .matches(/^(?!0\d*$)\d+(\.\d+)?$/, {
  //     message: "Invalid weight",
  //   }),

  // Product Service Section
  warrantyType: Yup.object().nullable(),
  warrantyPeriod: Yup.object()
    .when("warrantyType", {
      is: (type) => !!type?.value && type?.value !== "none",
      then: Yup.object().required("Required*").nullable(),
      otherwise: Yup.object().notRequired().nullable(),
    })
    .nullable(),
  warrantyTenure: Yup.number()
    .when(["warrantyType", "warrantyPeriod"], {
      is: (type, unit) =>
        !!type?.value && type?.value !== "none" && unit?.value === "day",
      then: Yup.number()
        .required("Required*")
        .integer("Value must be an integer")
        .min(1, "Minimum should be greater than 0")
        .max(365, "Maximum should be less than or equal to 365"),
      otherwise: Yup.number().notRequired(),
    })
    .when(["warrantyType", "warrantyPeriod"], {
      is: (type, unit) =>
        !!type?.value && type?.value !== "none" && unit?.value === "month",
      then: Yup.number()
        .required("Required*")
        .integer("Value must be an integer")
        .min(1, "Minimum should be greater than 0")
        .max(12, "Maximum should be less than or equal to 12"),
      otherwise: Yup.number().notRequired(),
    })
    .when(["warrantyType", "warrantyPeriod"], {
      is: (type, unit) =>
        !!type?.value && type?.value !== "none" && unit?.value === "year",
      then: Yup.number()
        .required("Required*")
        .integer("Value must be an integer")
        .min(1, "Minimum should be greater than 0")
        .max(25, "Maximum should be less than or equal to 25"),
      otherwise: Yup.number().notRequired(),
    }),
});
