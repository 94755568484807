import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import propTypes from "prop-types";
import { memo, useState } from "react";
import { createStaticRanges, DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR, FILTERS_BACKGROUND_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const DateRangeFilter = ({ onChange, onClear, hideDefaultRange }) => {
  const navigate = useNavigate();
  const params = useQueryParams();
  const { from = "", to = "" } = params;
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: from
        ? new Date(from)
        : new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: to ? new Date(to) : new Date(),
      key: "selection",
    },
  ]);

  const handleClose = () => setOpen(false);

  const handleApply = () => {
    setRange(range);
    onChange(range[0]);
    handleClose();
  };

  const handleClear = () => {
    const _params = { ...params };
    if (_params.from) delete _params.from;
    if (_params.to) delete _params.to;
    navigate({
      search: createSearchParams(_params).toString(),
    });
    setRange([
      {
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    onClear && onClear();
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setOpen(true)}
        endIcon={
          <HighlightOffIcon
            sx={{
              "&:hover": {
                color: "primary.main",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleClear();
            }}
          />
        }
        sx={{
          height: "44px",
          color: FILTER_TEXT_COLOR,
          minWidth: "fit-content",
          textTransform: "none",
          fontSize: "1.063rem",
          fontWeight: 400,
          background: FILTERS_BACKGROUND_COLOR,
        }}
      >
        {hideDefaultRange && !(from || to)
          ? "Select date"
          : `${dayjs(range?.[0]?.startDate).format("DD/MM/YYYY")} - ${dayjs(
              range?.[0]?.endDate
            ).format("DD/MM/YYYY")}`}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <DateRangePicker
            staticRanges={customRanges}
            onChange={(item) => {
              setRange([item.selection]);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            maxDate={new Date()}
            direction="vertical"
          />
          <Box alignSelf="end">
            <Button
              onClick={handleClose}
              variant="outlined"
              className="AddButtons"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApply}
              variant="contained"
              className="AddButtons"
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

DateRangeFilter.propTypes = {
  onChange: propTypes.func.isRequired,
  onClear: propTypes.func,
  hideDefaultRange: propTypes.bool,
};

export default memo(DateRangeFilter);

const modalStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  "& .AddButtons": {
    height: "44px",
    textTransform: "none",
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
    },
    cursor: "pointer",
  },
};

const customRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: dayjs().startOf("day").toDate(),
      endDate: dayjs().endOf("day").toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(dayjs().startOf("day"), "day") &&
        dayjs(range.endDate).isSame(dayjs().endOf("day"), "day")
      );
    },
  },
  {
    label: "Yesterday",
    range: () => ({
      startDate: dayjs().subtract(1, "day").startOf("day").toDate(),
      endDate: dayjs().subtract(1, "day").endOf("day").toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(
          dayjs().subtract(1, "day").startOf("day"),
          "day"
        ) &&
        dayjs(range.endDate).isSame(
          dayjs().subtract(1, "day").endOf("day"),
          "day"
        )
      );
    },
  },
  {
    label: "This Week",
    range: () => {
      const startOfWeek = dayjs().startOf("week").toDate(); // Sunday
      const maxDate = dayjs().toDate();
      const endDate = dayjs().endOf("week").isAfter(maxDate)
        ? maxDate
        : dayjs().endOf("week").toDate();

      return {
        startDate: startOfWeek,
        endDate: endDate,
      };
    },
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(dayjs().startOf("week"), "day") &&
        dayjs(range.endDate).isSame(dayjs(), "day")
      );
    },
  },
  {
    label: "Last Week",
    range: () => ({
      startDate: dayjs().subtract(1, "week").startOf("week").toDate(),
      endDate: dayjs().subtract(1, "week").endOf("week").toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(
          dayjs().subtract(1, "week").startOf("week"),
          "day"
        ) &&
        dayjs(range.endDate).isSame(
          dayjs().subtract(1, "week").endOf("week"),
          "day"
        )
      );
    },
  },
  {
    label: "This Month",
    range: () => ({
      startDate: dayjs().startOf("month").toDate(),
      endDate: dayjs().isSame(dayjs().endOf("month"), "day")
        ? dayjs().toDate()
        : dayjs().endOf("month").toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(dayjs().startOf("month"), "day") &&
        dayjs(range.endDate).isSame(dayjs(), "day")
      );
    },
  },
  {
    label: "Last Month",
    range: () => ({
      startDate: dayjs().subtract(1, "month").startOf("month").toDate(),
      endDate: dayjs().subtract(1, "month").endOf("month").toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(
          dayjs().subtract(1, "month").startOf("month"),
          "day"
        ) &&
        dayjs(range.endDate).isSame(
          dayjs().subtract(1, "month").endOf("month"),
          "day"
        )
      );
    },
  },
  {
    label: "Start of Year",
    range: () => ({
      startDate: dayjs().startOf("year").toDate(),
      endDate: dayjs().toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(dayjs().startOf("year"), "day") &&
        dayjs(range.endDate).isSame(dayjs(), "day")
      );
    },
  },
  {
    label: "Previous Year",
    range: () => ({
      startDate: dayjs().subtract(1, "year").startOf("year").toDate(),
      endDate: dayjs().subtract(1, "year").endOf("year").toDate(),
    }),
    isSelected: (range) => {
      return (
        dayjs(range.startDate).isSame(
          dayjs().subtract(1, "year").startOf("year"),
          "day"
        ) &&
        dayjs(range.endDate).isSame(
          dayjs().subtract(1, "year").endOf("year"),
          "day"
        )
      );
    },
  },
]);
