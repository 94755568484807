import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { InputAdornment, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import { getUser, resetUserState, updateUser } from "store/slices/usersSlice";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import UpdatePasswordForm from "./UpdatePasswordForm";

function UserForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams().id;
  const { user, loading } = useSelector((state) => state.users);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const initialValues = useMemo(
    () => ({
      name: user.fullname ?? "",
      email: user.email ?? "",
      phone: user.phone ?? "",
      role: user.role ?? "",
      refCode: user.refCode ?? "",
    }),
    [user.email, user.fullname, user.phone, user.refCode, user.role]
  );

  const handleSubmit = useCallback(
    (values) => {
      const sellerData = {
        fullname: values.name,
        email: values.email,
        phone: values.phone,
      };

      let formValues = { ...sellerData };
      let userValues = { ...user };
      let updatedValues = reduceObject(formValues, userValues);

      if (Object.keys(updatedValues).length === 0) {
        return navigate("/users/sellers");
      } else {
        dispatch(updateUser({ id, updatedValues }))
          .unwrap()
          .then(() => {
            navigate("/users/sellers");
          })
          .catch(() => {
            toast.error("Something went wrong");
          });
      }
    },
    [dispatch, id, navigate, user]
  );

  useEffect(() => {
    return () => dispatch(resetUserState());
  }, [dispatch]);

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        py={1}
        mb={1}
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.4rem" }}
      >
        Update User {user?.fullname ?? ""}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <StyledMuiCard
              title="User Information"
              subTitle="Please enter the basic information of user such as name , email, password and role."
            >
              <CardContent>
                <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Full Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("name")}
                        error={Boolean(
                          formik.touched.name && formik.errors.name
                        )}
                        helperText={
                          formik.touched.name && !!formik.errors.name
                            ? formik.errors.name
                            : "Enter a full name of the user here"
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Email
                      </Typography>
                      <StyledTextField
                        fullWidth
                        size={isSmall ? "small" : "large"}
                        type="email"
                        {...formik.getFieldProps("email")}
                        error={!!formik.touched.email && !!formik.errors.email}
                        helperText={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : "Enter the valid email address of the user here."
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Ref Code
                      </Typography>
                      <StyledTextField
                        fullWidth
                        disabled
                        type="text"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("refCode")}
                        error={Boolean(
                          formik.touched.refCode && formik.errors.refCode
                        )}
                        helperText={
                          formik.touched.refCode && !!formik.errors.refCode
                            ? formik.errors.refCode
                            : "Enter a reference code"
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Phone
                      </Typography>
                      <StyledTextField
                        fullWidth
                        label="Phone Number"
                        type="tel"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("phone")}
                        error={!!formik.touched.phone && !!formik.errors.phone}
                        helperText={formik.touched.phone && formik.errors.phone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIphoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Role
                      </Typography>
                      <StyledTextField
                        fullWidth
                        select
                        disabled
                        size={isSmall ? "small" : "large"}
                        type="text"
                        {...formik.getFieldProps("role")}
                        error={Boolean(
                          formik.touched.role && formik.errors.role
                        )}
                        helperText={
                          formik.touched.role && !!formik.errors.role
                            ? formik.errors.role
                            : "Please define the role of the user here"
                        }
                      >
                        <MenuItem value="user">User</MenuItem>
                        <MenuItem value="requested seller">
                          Requested Seller
                        </MenuItem>
                        <MenuItem value="supplier">Seller</MenuItem>
                      </StyledTextField>
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Box mt={5}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={formik.handleSubmit}
                        sx={{
                          fontWeight: "bold",
                          minWidth: { md: 100, xl: 250 },
                          height: { xs: 50, xl: 55 },
                        }}
                        disabled={loading}
                      >
                        Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </StyledMuiCard>
          </Form>
        )}
      </Formik>
      <UpdatePasswordForm userId={id} />
    </Box>
  );
}

export default memo(UserForm);

export function loadSeller({ params }) {
  const userId = params?.id;
  store.dispatch(getUser(userId ?? ""));
  return null;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("*Required")
    .min(3, "Name is too short")
    .max(50, "Name is too long"),
  email: Yup.string()
    .email("Email is invalid")
    .trim()
    .required("*Required")
    .max(80, "Email is too long")
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
  role: Yup.string().required("Required*"),
  refCode: Yup.string(),
  phone: Yup.string()
    .matches(/^((\+92)?(0)?)(3)([0-9]{9})$/gm, "Invalid phone number")
    .required("*Required"),
});
