import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PageTitle from "layout/PageTitle";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addProduct } from "store/slices/productsSlice";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function AddProductForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.products?.productLoading);
  const userRole = useSelector((state) => state.auth?.userRole);
  const sellerDetail = useSelector(
    (state) => state.stores?.store ?? state?.auth?.data?.user?.sellerDetail
  );
  const sellerId = useSelector((state) => state.products?.sellerId);

  const initialValues = useMemo(
    () => ({
      name: "",
    }),
    []
  );

  useEffect(() => {
    if (!sellerDetail && userRole !== "admin") {
      toast.error("Create your store to add products");
      navigate("/store");
    }
  }, [navigate, sellerDetail, userRole]);

  const handleSubmit = useCallback(
    (values) => {
      let data = {};
      if (userRole === "admin") {
        data = {
          productName: values.name,
          userId: sellerId ? sellerId : "",
        };
      } else {
        data = {
          productName: values.name,
        };
      }
      dispatch(addProduct(data))
        .unwrap()
        .then((response) => {
          response.id && navigate(`/products/update/${response?.id}`);
        });
    },
    [dispatch, navigate, sellerId, userRole]
  );

  return (
    <Box py={2} px={1}>
      <Box display="flex" flexWrap="wrap" alignItems="flex-end" gap={3} my={1}>
        <CustomBreadcrumbs />
      </Box>
      <PageTitle title="Add Product" />
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={7.5}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the product such as name"
                >
                  <CardContent>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      gap={3}
                      p={{ xs: 1, md: 1.5 }}
                    >
                      <Stack direction="column" gap={1}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Product Name
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="text"
                          placeholder="Enter Product Name"
                          {...formik.getFieldProps("name")}
                          error={Boolean(
                            formik.touched.name && formik.errors.name
                          )}
                          helperText={
                            formik.touched.name && !!formik.errors.name
                              ? formik.errors.name
                              : "Enter product name to help customer find your product"
                          }
                        />
                      </Stack>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={formik.handleSubmit}
                        disabled={loading}
                        sx={{
                          ml: "auto",
                          fontWeight: "bold",
                          width: { md: 100, lg: 175, xl: 250 },
                          height: { xs: 50, xl: 55 },
                        }}
                      >
                        {loading ? "Adding Product ..." : "Add Product"}
                      </Button>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(AddProductForm);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Product Name is required")
    .min(10, "Name should be at least 10 characters long")
    .max(100, "Name should not exceed 100 characters"),
});
