import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import TermsConditions from "components/Terms&Conditions";
import { useFormik } from "formik";
import { parsePhoneNumber } from "libphonenumber-js";
import { useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userSignup } from "store/slices/authSlice";
import * as Yup from "yup";
import StyledTextField from "./StyledTextField";

export default function SignUpForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validationSchema = useMemo(() => {
    Yup.addMethod(Yup.string, "phoneNumber", function (message) {
      return this.test(
        "phoneNumber",
        message || "Invalid phone number",
        function (value) {
          if (!value) {
            return true;
          }
          let number = value;
          if (number[0] !== "+") number = "+" + number;
          const phoneNumber = parsePhoneNumber(number);
          return phoneNumber ? phoneNumber.isValid() : false;
        }
      );
    });

    return Yup.object().shape({
      name: Yup.string()
        .required("Required*")
        .matches(
          /^[a-zA-Z._-\s]+$/,
          "Only alphabets, spaces, periods (.), underscores (_), and hyphens (-) are allowed"
        )
        .min(3, "Name is too short")
        .max(32, "Name cannot exceed 32 characters"),
      phone: Yup.string()
        .trim()
        .required("Required*")
        .phoneNumber("Please enter a valid phone number"),
      email: Yup.string()
        .email("Email is invalid")
        .trim()
        .required("*Required")
        .max(80, "Email is too long")
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Invalid Email"),
      password: Yup.string()
        .trim()
        .required("Required*")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,16}$/,
          "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character, and must be between 8 and 16 characters long."
        ),
      confirmPassword: Yup.string()
        .required("Required*")
        .trim()
        .oneOf([Yup.ref("password"), null], "Passwords do not match"),
      termsAndConditions: Yup.boolean().oneOf(
        [true],
        "Accept Terms and Conditions is required"
      ),
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsAndConditions: false,
    },
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting, setErrors }) => {
      const data = {
        fullname: values.name.trim(),
        phone: values.phone,
        email: values.email.trim(),
        password: values.password,
      };
      dispatch(userSignup(data))
        .unwrap()
        .then(() => {
          navigate("/verification");
        })
        .catch((error) => {
          if (
            error === "Error: Email already taken" ||
            error === "Email already taken"
          ) {
            return setErrors({ email: "Email already taken" });
          }
          if (
            error === "Error: Phone number already taken" ||
            error === "Phone number already taken"
          )
            return setErrors({ phone: "Phone number already taken" });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          alignContent="center"
          justifyContent="center"
          minHeight="100vh"
          maxWidth={{ sm: "250px", md: "450px", lg: "400px", xl: "535px" }}
          m="auto"
          py={2}
          gap={{ xs: 1.75, md: 2.5, xl: 6 }}
        >
          <Typography
            variant="h3"
            component="h2"
            fontSize={{ xs: "1rem", sm: "1.5rem", md: "2.75rem", lg: "3rem" }}
            size={isSmall ? "small" : "large"}
          >
            Create Account
          </Typography>
          <StyledTextField
            fullWidth
            type="text"
            label="Enter Your Name"
            size={isSmall ? "small" : "large"}
            {...formik.getFieldProps("name")}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={
              formik.touched.phone && formik.errors.phone
                ? {
                    "& .react-tel-input .form-control": {
                      borderColor: "error.main",
                    },
                  }
                : {}
            }
          >
            <PhoneInput
              country={"sa"}
              value={formik.values.phone}
              onlyCountries={["pk", "sa"]}
              countryCodeEditable={false}
              enableSearch={true}
              onChange={(formattedPhoneNumber) => {
                let _phoneNumber = formattedPhoneNumber
                  .split(" ")
                  .join("")
                  .replace(/-/g, "");

                if (_phoneNumber[0] !== "+") {
                  _phoneNumber = "+" + _phoneNumber;
                }

                formik.setFieldValue("phone", _phoneNumber);
              }}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
              placeholder="Phone No"
              searchPlaceholder="Search country here..."
              searchStyle={{
                borderRadius: "5px",
                padding: "6px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                fontSize: "16px",
              }}
              containerStyle={{
                direction: "ltr",
              }}
              inputStyle={{
                width: "100%",
              }}
            />
            <FormHelperText error={formik.touched.phone && formik.errors.phone}>
              {formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : ""}
            </FormHelperText>
          </Box>
          <StyledTextField
            fullWidth
            type="email"
            label="Email Address"
            size={isSmall ? "small" : "large"}
            {...formik.getFieldProps("email")}
            error={!!formik.touched.email && !!formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            size={isSmall ? "small" : "large"}
            {...formik.getFieldProps("password")}
            error={!!formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            fullWidth
            label="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            size={isSmall ? "small" : "large"}
            {...formik.getFieldProps("confirmPassword")}
            error={
              !!formik.touched.confirmPassword &&
              !!formik.errors.confirmPassword
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Stack direction="column">
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    value={formik.values.termsAndConditions}
                    color="primary"
                  />
                }
                label="By signing up I agree with"
                {...formik.getFieldProps("termsAndConditions")}
                sx={{
                  mr: 1,
                }}
              />
              <TermsConditions />
            </Stack>
            {formik.touched?.termsAndConditions &&
              formik.errors?.termsAndConditions && (
                <Typography variant="body2" color="error" fontSize="0.8rem">
                  {formik.errors?.termsAndConditions}
                </Typography>
              )}
          </Stack>
          <Button
            variant="contained"
            type="submit"
            size={isSmall ? "small" : "large"}
            sx={{
              fontWeight: "bold",
              fontSize: { sm: "0.75rem", md: "1.2rem", xl: "1.25rem" },
            }}
            disabled={formik.isSubmitting}
          >
            Sign up
          </Button>
        </Stack>
      </form>
    </Container>
  );
}
