import Box from "@mui/material/Box";
import CardTitle from "components/CardTitle";
import { memo } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { useSelector } from "react-redux";
import {
  SPEEDOMETER_SEGMENT_COLOR,
  SPEEDOMETER_TEXT_COLOR,
  STATS_DARK_GREEN_COLOR,
  STATS_LIGHT_GREEN_COLOR,
  STATS_ORANGE_SECONDARY,
  STATS_PRIMARY_RED,
  STATS_YELLOW_COLOR,
} from "styles/colors";
import getTargetValue from "utils/helpers/getTargetValue";
import numberFormatter from "utils/helpers/numberFormatter";

function SpeedoMeter() {
  const monthlyAnalytics = useSelector(
    (state) => state.dashboard.monthlyAnalytics.data
  );
  let maxValue = getTargetValue(
    monthlyAnalytics?.orders?.averageOrderValue,
    1.5
  );

  if (maxValue === 0) {
    maxValue = 1000;
  }

  return (
    <Box p={2} height="100%">
      <CardTitle title="Avg. order value" />
      <Box
        textAlign="center"
        sx={{
          "& .label": {
            "& .segment-value": {
              ":first-child": {
                transform: "rotate(0deg) translate(-125px, 15px)",
              },
              ":last-child": {
                transform: "rotate(0deg) translate(120px, 15px)",
              },
            },
          },
        }}
      >
        <ReactSpeedometer
          forceRender={true}
          height={157}
          width={250}
          needleHeightRatio={0.8}
          minValue={0}
          maxValue={maxValue}
          value={monthlyAnalytics?.orders?.averageOrderValue ?? 0}
          currentValueText={
            monthlyAnalytics?.orders?.averageOrderValue > 1000
              ? numberFormatter(monthlyAnalytics?.orders?.averageOrderValue)
              : Number(
                  monthlyAnalytics?.orders?.averageOrderValue
                ).toLocaleString()
          }
          labelFontSize={"14px"}
          valueTextFontSize={"36px"}
          valueTextFontWeight={"bold"}
          paddingHorizontal={17}
          paddingVertical={17}
          valueTextColor={SPEEDOMETER_TEXT_COLOR}
          maxSegmentLabels={5}
          customSegmentStops={[
            0,
            Math.round(maxValue * 0.2),
            Math.round(maxValue * 0.4),
            Math.round(maxValue * 0.6),
            Math.round(maxValue * 0.8),
            Math.round(maxValue),
          ]}
          segmentColors={[
            STATS_PRIMARY_RED,
            STATS_ORANGE_SECONDARY,
            STATS_YELLOW_COLOR,
            STATS_LIGHT_GREEN_COLOR,
            STATS_DARK_GREEN_COLOR,
          ]}
          ringWidth={57}
          needleTransitionDuration={3333}
          needleTransition="easeElastic"
          needleColor={SPEEDOMETER_SEGMENT_COLOR}
          textColor={SPEEDOMETER_TEXT_COLOR}
          segmentValueFormatter={(value) => {
            return numberFormatter(value);
          }}
        />
      </Box>
    </Box>
  );
}

export default memo(SpeedoMeter);
