import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FOOTER_ICON_COLOR } from "styles/colors";

const Footer = () => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography
        sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
        px="5px"
        lineHeight={1.21}
      >
        COPYRIGHT © 2023 MySouq. All rights reserved (v 0.2)
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}
          px="5px"
          lineHeight={1.21}
        >
          Project By MySouq
        </Typography>
        <FavoriteBorderIcon sx={{ color: FOOTER_ICON_COLOR }} />
      </Box>
    </Box>
  );
};

export default Footer;
