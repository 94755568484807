import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

function CardTitle({ title, color }) {
  return (
    <Typography
      fontWeight="bold"
      fontSize={{
        xs: "0.9rem",
        lg: "1rem",
        xl: "1rem",
        xxl: "1.15rem",
      }}
      color={color ?? "#3f3f3f"}
    >
      {title}
    </Typography>
  );
}

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default CardTitle;
