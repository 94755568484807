import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LinearProgress from "@mui/material/LinearProgress";
import linearProgressClasses from "@mui/material/LinearProgress/linearProgressClasses";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import CartIcon from "assets/icons/CartIcon.svg";
import OrderBox from "assets/icons/OrderBox.svg";
import ProductBox from "assets/icons/ProductBox.svg";
import VideoIcon from "assets/icons/VideoIcon.svg";
import CardTitle from "components/CardTitle";
import StyledCard from "components/Dashboard/StyledCardContent";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  STATS_CARD_TEXT_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
  STATS_PRIMARY_RED,
} from "styles/colors";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "7.4px",
  borderRadius: 5,
  mt: "8px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#0ea552",
  },
}));

function StatsCard({ title, currentMonth, target, lastMonth }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const Progress = useMemo(() => {
    return target !== 0 && currentMonth !== 0
      ? `${Math.round((100 / target) * currentMonth)}%`
      : "0%";
  }, [currentMonth, target]);

  const difference = useMemo(() => {
    if (!isNaN(currentMonth) && !isNaN(lastMonth)) {
      return Math.abs(currentMonth - lastMonth);
    } else {
      return 0;
    }
  }, [currentMonth, lastMonth]);

  const imageIcon = useCallback((title) => {
    switch (title) {
      case "Orders":
        return OrderBox;
      case "Sales":
        return CartIcon;
      case "Products":
        return ProductBox;
      case "Videos":
        return VideoIcon;
      default:
        return;
    }
  }, []);

  return (
    <Card sx={{ minHeight: { md: "200px", lg: "170px", xxl: "220px" } }}>
      <StyledCard>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <CardTitle title={title} />
            <NumberFormatter
              sx={{
                fontSize: { md: "3.188rem", lg: "2rem", xxl: "3.188rem" },
                fontWeight: "bold",
                color: STATS_NUMBER_FORMATTER_COLOR,
                height: { md: "55px", lg: "30px", xxl: "55px" },
              }}
              number={currentMonth}
            />
            <Typography
              sx={{
                fontSize: "0.875rem",
                lineHeight: "30px",
                color: STATS_CARD_TEXT_COLOR,
              }}
            >
              This month
            </Typography>
          </Box>
          <Box
            backgroundColor="#eafafd"
            borderRadius="50%"
            minWidth={{
              sm: "70px",
              md: isOpen ? "70px" : "115px",
              lg: "70px",
              xxl: "115px",
            }}
            height="100%"
            minHeight={{
              sm: "70px",
              md: isOpen ? "70px" : "115px",
              lg: "70px",
              xxl: "115px",
            }}
            width="auto"
            sx={{
              backgroundImage: `url(${imageIcon(title)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: {
                sm: "35px",
                md: isOpen ? "35px" : "60px",
                lg: "35px",
                xxl: "60px",
              },
              backgroundPosition: "center",
            }}
          ></Box>
        </Box>
        <Box display={!isOpen ? "flex" : "content"} gap={0.7}>
          <Box display="flex" gap={0.7} alignItems="center">
            <img
              src={currentMonth > lastMonth ? ArrowUp : ArrowDown}
              alt="arrow"
            />
            <NumberFormatter
              number={difference}
              color={currentMonth > lastMonth ? "#0ea552" : STATS_PRIMARY_RED}
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "1.063rem",
                color: STATS_CARD_TEXT_COLOR,
                lineHeight: 2.06,
              }}
            >
              vs last month
            </Typography>
          </Box>
        </Box>
        <Box width="100%" height="7px" mr={1} mt={1}>
          <BorderLinearProgress
            variant="determinate"
            value={currentMonth !== 0 ? (currentMonth * 100) / target : 0}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={1}>
          <Typography
            sx={{ color: STATS_CARD_TEXT_COLOR, fontSize: "0.75rem" }}
          >
            {Progress}
          </Typography>
          <Typography
            sx={{ color: STATS_CARD_TEXT_COLOR, fontSize: "0.75rem" }}
          >
            Actual vs Target
          </Typography>
          <NumberFormatter
            sx={{ color: STATS_CARD_TEXT_COLOR, fontSize: "0.75rem" }}
            number={target}
          />
        </Box>
      </StyledCard>
    </Card>
  );
}

export default memo(StatsCard);

StatsCard.propTypes = {
  title: PropTypes.string.isRequired,
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lastMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
