import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ChatRooms from "components/Chat/ChatRooms";
import Conversation from "components/Chat/Conversation";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { refreshUserTokens } from "store/slices/authSlice";
import {
  appendMessage,
  prependMessages,
  setActiveRoom,
  setMessages,
  setRooms,
} from "store/slices/chatSlice";
import { BORDER_LIGHT } from "styles/colors";
import { getResponseData } from "utils/helpers/apiDataHelpers";
import {
  isRefreshTokenExpired,
  isTokenExpired,
} from "utils/helpers/isTokenExpired";

function Chat() {
  const socket = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.auth?.data?.user?.id ?? "");
  const rooms = useSelector((state) => state.chat.rooms ?? []);
  const activeRoom = useSelector((state) => state.chat.activeRoom ?? {});

  const socketInitializer = useCallback(async () => {
    if (isRefreshTokenExpired()) {
      return;
    }

    if (isTokenExpired()) {
      await dispatch(refreshUserTokens());
    }

    socket.current = io(`${process.env.REACT_APP_CHAT_SERVER_URL}/message`, {
      reconnectionAttempts: 3,
      reconnectionDelay: 1000 * 60,
      reconnectionDelayMax: 1000 * 60,
      reconnection: true,
      transports: ["websocket"],
      auth: {
        token: localStorage.getItem("token"),
      },
    });
    socket.current.on("connect", () => {
      socket.current.on("room-join", (response) => {
        socket.current.emit("allUnread", {
          userId,
        });
        dispatch(
          setMessages({
            messages: response.messages,
            roomId: response.roomId,
            userId,
          })
        );
      });
      socket.current.emit("allUnread", {
        userId,
      });
      socket.current.on("allUnread", (rooms) => {
        dispatch(setRooms(rooms));
      });
      socket.current.on("newChatMessage", (message) => {
        dispatch(appendMessage({ message, userId }));
        socket.current.emit("allUnread", {
          userId,
        });
      });
      socket.current.on("messages", (response) => {
        const responseData = getResponseData(response);
        dispatch(
          prependMessages({
            ...responseData,
            userId,
          })
        );
      });
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (socket.current && activeRoom._id) {
      socket.current.emit("room-join", {
        customerId: activeRoom.customerId,
        brandName: activeRoom.brandName,
        storeId: activeRoom.storeId,
        sellerId: activeRoom.sellerId,
        isSeller: true,
      });
    }
  }, [
    activeRoom.storeId,
    activeRoom.sellerId,
    activeRoom.brandName,
    activeRoom.customerId,
    activeRoom._id,
  ]);

  useEffect(() => {
    rooms.length && !activeRoom._id && dispatch(setActiveRoom(rooms[0]));
  }, [activeRoom._id, dispatch, rooms]);

  useEffect(() => {
    !socket.current && socketInitializer();
    return () => {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = null;
      }
    };
  }, [socketInitializer]);

  return (
    <Box pt={4} px={{ xs: 0, md: 1 }}>
      <Paper sx={{ py: 2, px: { xs: 1, lg: 2 } }}>
        <Grid container spacing={2} height="75vh">
          <Grid
            item
            xs={4}
            lg={3}
            sx={{
              height: "100%",
              borderRight: `1px solid ${BORDER_LIGHT}`,
            }}
          >
            <ChatRooms />
          </Grid>
          <Grid
            item
            xs={8}
            lg={9}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <Conversation socket={socket.current} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Chat;
