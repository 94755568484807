import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAdminProducts, getProductById } from "store/slices/productsSlice";
import useDebounce from "utils/hooks/useDebounceValue";

function ProductSelect({ formik }) {
  const dispatch = useDispatch();
  const _params = useParams();
  const { results } = useSelector((state) => state?.products);
  const product = useSelector((state) => state.products.product);
  const [searchValue, setSearchValue] = useState("");
  const deferredValue = useDebounce(searchValue, 700);

  const handleChange = useCallback((value) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    if (deferredValue) {
      const data = {
        name: "productName",
        productType: "main",
        value: deferredValue,
      };
      dispatch(getAdminProducts(data));
    }
  }, [deferredValue, dispatch]);

  useEffect(() => {
    if (_params?.id && formik?.values?.productId) {
      dispatch(getProductById(formik?.values?.productId));
    }
  }, [_params?.id, dispatch, formik?.values?.productId]);

  return (
    <Grid item xs={4} lg={4}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography
          variant="h3"
          fontWeight="bold"
          fontSize="1.25rem"
          color="text.secondary"
        >
          Product Name
        </Typography>
        {_params?.id ? (
          <StyledTextField
            disabled
            value={product?.productName ?? ""}
            helperText={"Enter product name here"}
          />
        ) : (
          <Autocomplete
            options={results || []}
            name="productId"
            onChange={(_, option) => {
              formik.setFieldValue("productId", option?.id);
              formik.setFieldValue("couponType", "product");
              formik.setFieldValue("productQuantity", option?.quantity);
            }}
            onBlur={formik.handleBlur}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => (option ? option.productName : "")}
            renderInput={(params) => (
              <StyledTextField
                fullWidth
                onChange={(e) => handleChange(e.target.value)}
                {...params}
                label="Product name"
                error={Boolean(
                  formik.touched.productId && formik.errors.productId
                )}
                helperText={
                  formik.touched.productId && !!formik.errors.productId
                    ? formik.errors.productId
                    : "Enter product name here"
                }
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.productName}
              </li>
            )}
          />
        )}
      </Box>
    </Grid>
  );
}

ProductSelect.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductSelect);
