import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import eyeIcon from "assets/icons/eyeIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteBanner,
  getBannerSets,
  updateBannerStatus,
} from "store/slices/bannersSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { results, loading, totalPages } = useSelector(
    (state) => state.banners
  );

  // const get_banners = useCallback(() => {
  //   dispatch(getBannerSets(params));
  // }, [dispatch, params]);

  const onChange = useCallback(
    (row) => {
      dispatch(
        updateBannerStatus({
          id: row.id,
          active: !row.active,
        })
      );
    },
    [dispatch]
  );

  const handleClickOpen = useCallback(
    (bannerId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(bannerId));
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(getBannerSets(params));
  }, [dispatch, params]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
      },
      {
        field: "bannerName",
        headerName: "Name",
        minWidth: 100,
        flex: 0.8,
        renderCell: (params) => (
          <Typography
            textTransform="capitalize"
            sx={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            {params?.row?.bannerName}
          </Typography>
        ),
      },
      {
        field: "location",
        headerName: "Location",
        minWidth: 40,
        flex: 0.6,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.location}
          </Typography>
        ),
      },
      {
        field: "device",
        headerName: "Platform",
        minWidth: 100,
        flex: 0.5,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.device}
          </Typography>
        ),
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 80,
        flex: 0.3,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.type}
          </Typography>
        ),
      },
      {
        field: "active",
        headerName: "Active",
        type: "actions",
        minWidth: 50,
        flex: 0.4,
        renderCell: (params) => (
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <StyledSwitch
                checked={params?.row?.active}
                onChange={() => onChange(params.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 80,
        flex: 0.8,
        renderCell: (params) => (
          <Box display="flex" gap="2px">
            <Tooltip title="View">
              <IconButton
                size="small"
                onClick={() =>
                  navigate(`${params.id}/${params?.row?.device}/banners`)
                }
              >
                <img src={eyeIcon} alt="delete" width={19} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => navigate(`${params.id}/update`)}
              >
                <img src={WritingIcon} alt="edit" width={19} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="delete" width={19} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Banner">
              <IconButton
                size="small"
                onClick={() =>
                  navigate(`${params.id}/${params?.row?.device}/banners/create`)
                }
              >
                <AddIcon width={19} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [onChange, navigate, handleClickOpen]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteBanner} />
    </>
  );
}
