import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import AuthPageCenterObject from "assets/images/AuthPageCenterObject.png";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout } from "store/slices/authSlice";

export default function AuthSideContent() {
  const dispatch = useDispatch();
  const pagePathName = useLocation().pathname;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  const logoutButton =
    pagePathName.includes("phone-number") ||
    pagePathName.includes("verification");
  const loginButton =
    pagePathName.includes("signup") ||
    pagePathName.includes("recover-password");

  const handleRedirect = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Box display="flex" justifyContent="center" pb={2}>
        <img
          src={AuthPageCenterObject}
          alt="Girl holding laptop for online shopping"
          height="auto"
          width={
            isSmall ? "250px" : isMedium ? "300px" : isLarge ? "325px" : "100%"
          }
        />
      </Box>
      <Typography
        component="h3"
        color="white"
        fontSize={{
          sm: "1.25rem",
          md: "2rem",
          lg: "2.5rem",
          xl: "3rem",
        }}
        lineHeight={1}
      >
        WELCOME TO MySouq
      </Typography>
      <Typography
        variant="h1"
        color="white"
        fontSize={{
          sm: "2.8rem",
          md: "4.5rem",
          lg: "5.5rem",
          xl: "6.5rem",
        }}
        fontWeight="bold"
        lineHeight={1}
      >
        ADMIN PANEL
      </Typography>
      <Typography
        component="h4"
        color="white"
        fontSize={{
          sm: "0.7rem",
          md: "1.1rem",
          lg: "1.4rem",
          xl: "1.65rem",
        }}
      >
        {`KSA'S 1ST VIDEO BASED SOCIAL SELLING PLATFORM`}
      </Typography>
      <Box
        pt={2}
        sx={{
          minWidth: { sm: "250px", md: "450px", lg: "400px", xl: "535px" },
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          size={isSmall ? "small" : "large"}
          component={Link}
          to={loginButton || logoutButton ? "/login" : "/signup"}
          onClick={() => handleRedirect()}
          sx={{
            fontWeight: "bold",
            fontSize: {
              sm: "0.75rem",
              md: "1.2rem",
              xl: "1.25rem",
            },
            color: "primary.main",
            textTransform: "capitalize !important",
            backgroundColor: "white",
            minHeight: "60px",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          {logoutButton ? "Log out" : loginButton ? "Log In" : "Sign Up"}
        </Button>
      </Box>
    </Stack>
  );
}
