import {
  closestCenter,
  DndContext,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import ListIcon from "@mui/icons-material/List";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  dragDropCategories,
  getCategories,
} from "store/slices/categoriesSlice";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";
import DraggableRow from "./DraggableRow";

const CategoryDataGrid = () => {
  const dispatch = useDispatch();
  const results = useSelector((state) => state.categories.results);
  const loading = useSelector((state) => state.categories.loading);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));
  const [rows, setRows] = useState(results);
  const [layoutMode, setLayoutMode] = useState(false);
  const [value, setValue] = useState("LIST");
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (results && results.length > 0) {
      setRows(results);
    }
  }, [results]);

  const handleLayoutMode = (event, newValue) => {
    setValue(newValue);

    switch (newValue) {
      case "LIST":
        setLayoutMode(false);
        break;
      case "SORT":
        setLayoutMode(true);
        break;
      default:
        break;
    }
  };

  const handleSave = () => {
    if (hasUnsavedChanges) {
      const data = rows.map((item, index) => ({
        index: index + 1,
        categoryId: item.id,
      }));
      dispatch(dragDropCategories(data))
        .unwrap()
        .then(() => {
          dispatch(getCategories());
          setHasUnsavedChanges(false);
        });
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleDiscard = () => {
    setHasUnsavedChanges(false);
    dispatch(getCategories());
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setRows((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const updatedRows = arrayMove(items, oldIndex, newIndex);
        const updatedWithIndexes = updatedRows.map((item, index) => ({
          ...item,
          index: index,
        }));

        setHasUnsavedChanges(true);
        return updatedWithIndexes;
      });
    }
  };

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" marginBottom={3}>
        <Box sx={{ backgroundColor: FILTERS_BACKGROUND_COLOR }}>
          <Tabs
            value={value}
            onChange={handleLayoutMode}
            aria-label="category layout modes"
          >
            <Tab
              label="List"
              value="LIST"
              icon={<ListIcon />}
              iconPosition="start"
              sx={{ minHeight: 0 }}
            />
            <Tab
              label="Sort"
              value="SORT"
              icon={<SwapVertIcon />}
              iconPosition="start"
              sx={{ minHeight: 0 }}
            />
          </Tabs>
        </Box>
      </Box>

      {layoutMode === false ? (
        <Grid container alignItems="center" my={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={1}>
            <Typography fontWeight={600}>Sr</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={600}>Image</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography fontWeight={600}>Name/Details</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={600}>Commission</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography fontWeight={600}>Featured</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontWeight={600} textAlign="center">
              Actions
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Grid container marginBottom={5}>
          <SortableContext items={rows}>
            {rows.map((item, index) => (
              <DraggableRow
                key={item.id ?? index}
                row={item}
                index={index}
                loading={loading}
                layoutMode={layoutMode}
              />
            ))}
          </SortableContext>
        </Grid>
      </DndContext>

      {hasUnsavedChanges && (
        <Box
          bgcolor="white"
          padding={2}
          display="flex"
          flexDirection="column"
          sx={{
            borderRadius: "8px",
            position: "fixed",
            bottom: 16,
            right: 16,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
          }}
        >
          <Typography>
            You have unsaved changes. You cannot switch to another section until
            you save or discard the changes.
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={1} gap={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ fontSize: "15px" }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleDiscard}
              sx={{ fontSize: "15px", fontWeight: "bold" }}
            >
              Discard
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default memo(CategoryDataGrid);
