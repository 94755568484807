import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteMasterTable,
  getMasterTable,
} from "store/slices/masterTableSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: table,
    loading,
    totalPages,
  } = useSelector((state) => state.table);

  const handleClickOpen = useCallback(
    (tableId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(tableId));
    },
    [dispatch]
  );

  const fetchTable = useCallback(() => {
    dispatch(getMasterTable(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchTable();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchTable]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
      },
      {
        field: "label",
        headerName: "Label",
        minWidth: 175,
        flex: 1,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.label}
          </Typography>
        ),
      },
      {
        field: "key",
        headerName: "Name",
        minWidth: 175,
        flex: 1,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">{params?.row?.key}</Typography>
        ),
      },
      {
        field: "keyValue",
        headerName: "Value",
        minWidth: 150,
        flex: 0.75,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography textOverflow="ellipsis" overflow="hidden" maxWidth="100%">
            {params?.row?.keyValue}
          </Typography>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        type: "actions",
        minWidth: 100,
        flex: 0.5,
        sortable: false,
        disableColumnMenu: true,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.active).color,
              bgcolor: statusBadges(params?.row?.active).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.active ? "Active" : "Inactive"}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 150,
        flex: 0.75,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" pr={1}>
            <IconButton
              size="small"
              onClick={() => navigate(`${params.id}/update`)}
            >
              <img src={WritingIcon} alt="edit" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="edit" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleClickOpen, navigate]
  );
  return (
    <>
      {table ? (
        <>
          <StyledDataGrid
            rows={table}
            columns={columns}
            loading={loading}
            totalPages={totalPages}
          />
          <ConfirmDelete deleteAction={deleteMasterTable} />
        </>
      ) : (
        <Typography display="flex" justifyContent="center" mt={4}>
          No record found
        </Typography>
      )}
    </>
  );
}
