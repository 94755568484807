import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import StoreDescriptionSection from "components/Stores/UpdateStore/StoreDescriptionSection";
import StoreMediaSection from "components/Stores/UpdateStore/StoreMediaSection";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getAllCities,
  getAllMarkets,
  getCategories,
  resetState,
} from "store/slices/filtersSlice";
import { createStore, getProvinces } from "store/slices/storesSlice";
import flatMarketArray from "utils/helpers/flatMarketArray";
import * as Yup from "yup";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const GENERAL_CATEGORY_ID = process.env.REACT_APP_GENERAL_CATEGORY_ID;

function CreateStore() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const markets = useSelector((state) =>
    flatMarketArray(state?.filters.markets ?? [])
  );
  const provinces = useSelector((state) => state.stores.provinces);

  const categories = useSelector((state) =>
    state?.filters?.categories.map((category) => {
      const _category = { ...category };
      if (_category.subcategories) {
        delete _category.subcategories;
      }
      return _category;
    })
  );
  const [countriesArray] = useState([
    { code: "PK", label: "Pakistan" },
    { code: "SA", label: "KSA" },
  ]);

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  const handleCountryInput = useCallback(
    (value) => {
      if (value) {
        dispatch(getProvinces(value));
      } else {
        setCities(() => []);
        setAreas(() => []);
      }
    },
    [dispatch]
  );

  const handleProvinceInput = useCallback(
    (value) => {
      if (value) {
        const selectedProvince = provinces.find(
          (province) => province.province === value
        );
        setCities(() => selectedProvince?.cities ?? []);
      } else {
        setCities(() => []);
        setAreas(() => []);
      }
    },
    [provinces]
  );

  const handleCityInput = useCallback(
    (value) => {
      if (value) {
        const selectedCity = cities.find((city) => city.city === value);
        setAreas(() => selectedCity?.areas ?? []);
      } else {
        setAreas(() => []);
      }
    },
    [cities]
  );

  const initialValues = useMemo(
    () => ({
      storeName: "",
      address: "",
      city: "",
      description: "",
      categories: [],
      storeImage: "",
      logo: "",
      // cityCode: "",
      market: "",
      marketId: "",
      country: "",
      province: "",
      area: "",
      zipCode: "",
    }),
    []
  );

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        brandName: values?.storeName,
        description: values?.description,
        market: values?.market?.value ?? values?.marketId,
        address: values?.address,
        categories: values?.categories,
        city: values?.city,
        country:
          values?.country === "PK"
            ? "pak"
            : values?.country === "SA"
            ? "ksa"
            : values?.country,
        province: values?.province,
        area: values?.area,
        zipCode: values?.zipCode,
      };

      const image = values?.storeImage ?? "";
      const logo = values?.logo ?? "";
      dispatch(createStore({ data, image, logo }))
        .unwrap()
        .then(() => navigate("/"));
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllCities());
    dispatch(getAllMarkets());
    return () => dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllCities());
    dispatch(getAllMarkets());
    return () => dispatch(resetState());
  }, [dispatch]);

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} py={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        pt={1}
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.4rem" }}
      >
        Create Store
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the store such as name and description"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Store Name
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("storeName")}
                              error={Boolean(
                                formik.touched.v && formik.errors.storeName
                              )}
                              helperText={
                                formik.touched.storeName &&
                                !!formik.errors.storeName
                                  ? formik.errors.storeName
                                  : "Enter a descriptive name of the store here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Address
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("address")}
                              error={Boolean(
                                formik.touched.address && formik.errors.address
                              )}
                              helperText={
                                formik.touched.address &&
                                !!formik.errors.address
                                  ? formik.errors.address
                                  : "Enter a descriptive address of the store here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Country
                            </Typography>
                            <Autocomplete
                              name="country"
                              options={countriesArray}
                              value={countriesArray.find(
                                (country) =>
                                  country.code === formik.values.country
                              )}
                              onChange={(_, value) => {
                                formik.setFieldValue(
                                  "country",
                                  value?.code || ""
                                );
                                if (!value?.code) {
                                  formik.setFieldValue("province", "");
                                  formik.setFieldValue("city", "");
                                  formik.setFieldValue("area", "");
                                }
                                handleCountryInput(value?.code || "");
                              }}
                              onBlur={formik.handleBlur}
                              isOptionEqualToValue={(option, value) =>
                                option.code === value.code
                              }
                              renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  {...params}
                                  hiddenLabel={true}
                                  error={Boolean(
                                    formik.touched.country &&
                                      formik.errors.country
                                  )}
                                  helperText={
                                    formik.touched.country &&
                                    formik.errors.country
                                      ? formik.errors.country
                                      : "Please select a country for the store"
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.code}>
                                  {option.label}
                                </li>
                              )}
                            />
                          </Stack>
                        </Grid>

                        {/* Province Autocomplete */}
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Province
                            </Typography>
                            <Autocomplete
                              name="province"
                              options={provinces}
                              value={
                                provinces.find(
                                  (province) =>
                                    province.province === formik.values.province
                                ) || null
                              }
                              onChange={(_, value) => {
                                formik.setFieldValue(
                                  "province",
                                  value?.province || ""
                                );
                                if (!value?.province) {
                                  formik.setFieldValue("city", "");
                                  formik.setFieldValue("area", "");
                                }
                                handleProvinceInput(value?.province || "");
                              }}
                              onBlur={formik.handleBlur}
                              isOptionEqualToValue={(option, value) =>
                                option.province === value.province
                              }
                              disabled={!formik.values.country}
                              getOptionLabel={(option) => option.province || ""}
                              renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  {...params}
                                  hiddenLabel={true}
                                  error={Boolean(
                                    formik.touched.province &&
                                      formik.errors.province
                                  )}
                                  helperText={
                                    formik.touched.province &&
                                    formik.errors.province
                                      ? formik.errors.province
                                      : "Please select a province for the store"
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.province}>
                                  {option.province}
                                </li>
                              )}
                            />
                          </Stack>
                        </Grid>

                        {/* City Autocomplete */}
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              City
                            </Typography>
                            <Autocomplete
                              name="city"
                              options={cities}
                              value={
                                cities.find(
                                  (city) => city.city === formik.values.city
                                ) || null
                              }
                              onChange={(_, value) => {
                                formik.setFieldValue("city", value?.city || "");
                                if (!value?.city) {
                                  formik.setFieldValue("area", "");
                                }
                                handleCityInput(value?.city || "");
                              }}
                              onBlur={formik.handleBlur}
                              isOptionEqualToValue={(option, value) =>
                                option.city === value.city
                              }
                              disabled={!formik.values.province}
                              getOptionLabel={(option) => option.city || ""}
                              renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  {...params}
                                  hiddenLabel={true}
                                  error={Boolean(
                                    formik.touched.city && formik.errors.city
                                  )}
                                  helperText={
                                    formik.touched.city && formik.errors.city
                                      ? formik.errors.city
                                      : "Please select a city for the store"
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.city}>
                                  {option.city}
                                </li>
                              )}
                            />
                          </Stack>
                        </Grid>

                        {/* Area Autocomplete */}
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Area
                            </Typography>
                            <Autocomplete
                              name="area"
                              options={areas}
                              value={
                                areas.find(
                                  (area) => area === formik.values.area
                                ) || null
                              }
                              onChange={(_, value) => {
                                formik.setFieldValue("area", value || "");
                              }}
                              onBlur={formik.handleBlur}
                              disabled={!formik.values.city}
                              renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  {...params}
                                  hiddenLabel={true}
                                  error={Boolean(
                                    formik.touched.area && formik.errors.area
                                  )}
                                  helperText={
                                    formik.touched.area && formik.errors.area
                                      ? formik.errors.area
                                      : "Please select an area for the store"
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option}>
                                  {option}
                                </li>
                              )}
                            />
                          </Stack>
                        </Grid>

                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Zip Code
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="number"
                              {...formik.getFieldProps("zipCode")}
                              error={Boolean(
                                formik.touched.zipCode && formik.errors.zipCode
                              )}
                              helperText={
                                formik.touched.zipCode &&
                                !!formik.errors.zipCode
                                  ? formik.errors.zipCode
                                  : "Enter zip code here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Market
                            </Typography>
                            <Autocomplete
                              name="market"
                              options={markets}
                              value={formik.values.market}
                              onChange={(_, value) => {
                                formik.setFieldValue("market", value);
                              }}
                              onBlur={formik.handleBlur}
                              isOptionEqualToValue={(option, value) =>
                                option.label === value
                              }
                              renderInput={(params) => (
                                <StyledTextField
                                  fullWidth
                                  {...params}
                                  hiddenLabel={true}
                                  error={Boolean(
                                    formik.touched.market &&
                                      formik.errors.market
                                  )}
                                  helperText={
                                    formik.touched.market &&
                                    !!formik.errors.market
                                      ? formik.errors.market
                                      : "Please select a market for the store"
                                  }
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.value}>
                                  {option.label}
                                </li>
                              )}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Categories
                            </Typography>
                            <Autocomplete
                              multiple
                              name="categories"
                              id="checkboxes-tags-demo"
                              disableCloseOnSelect
                              options={categories ?? []}
                              value={
                                formik.values?.categories?.length
                                  ? categories?.filter((category) =>
                                      formik.values?.categories?.includes(
                                        category?.id
                                      )
                                    )
                                  : []
                              }
                              onChange={(_, dropdownCategories) => {
                                if (dropdownCategories.length > 3) {
                                  return;
                                }
                                if (dropdownCategories.length > 2) {
                                  let newCategory = dropdownCategories?.filter(
                                    (obj) =>
                                      !formik.values.categories.includes(obj.id)
                                  );
                                  if (newCategory.length) {
                                    newCategory = newCategory[0];
                                  } else {
                                    newCategory = null;
                                  }

                                  if (
                                    (formik.values.categories.includes(
                                      GENERAL_CATEGORY_ID
                                    ) &&
                                      newCategory.id !== GENERAL_CATEGORY_ID) ||
                                    (!formik.values.categories.includes(
                                      GENERAL_CATEGORY_ID
                                    ) &&
                                      newCategory.id === GENERAL_CATEGORY_ID)
                                  ) {
                                    formik.setFieldValue(
                                      "categories",
                                      dropdownCategories?.map(
                                        (category) => category?.id
                                      )
                                    );
                                  }
                                } else {
                                  formik.setFieldValue(
                                    "categories",
                                    dropdownCategories?.map(
                                      (category) => category?.id
                                    )
                                  );
                                }
                              }}
                              onBlur={formik.handleBlur}
                              getOptionLabel={(option) => option?.name}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    disabled={
                                      selected &&
                                      formik.values.categories.length >= 3
                                    }
                                  />
                                  {option.name}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Categories"
                                  placeholder="Category"
                                  error={Boolean(
                                    formik.touched.categories &&
                                      formik.errors.categories
                                  )}
                                  helperText={
                                    formik.touched.categories &&
                                    formik.errors.categories
                                      ? formik.errors.categories
                                      : "You can add at most 3 categories, one general and any two of the desired categories"
                                  }
                                />
                              )}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>

              {/*Store Description Section */}
              <Grid item xs={12} lg={12}>
                <StoreDescriptionSection formik={formik} />
              </Grid>
              {/*Store Media Section */}
              <Grid item xs={12} lg={12}>
                <StoreMediaSection formik={formik} />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    disabled={formik.isSubmitting}
                    onClick={() => formik.resetForm()}
                    variant="outlined"
                    color="primary"
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(CreateStore);

const validationSchema = Yup.object().shape({
  storeName: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 10 characters long")
    .max(100, "Name is too long"),
  address: Yup.string().required("Required*"),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(10, "Description must be at least 10 characters long")
    .max(1500, "Description is too long"),
  storeImage: Yup.string().required("Store Banner Image is required"),
  logo: Yup.string().required("Logo Image is required"),
  province: Yup.string().required("Province is required"),
  city: Yup.string().required("City is required"),
  country: Yup.string().required("Country is required"),
  area: Yup.string().required("Area is required"),
  zipCode: Yup.string().required("Zip code is required"),
});
