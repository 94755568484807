export const flatAePlatformCategoriesLeafNodes = (categories) => {
  let flatArray = [];
  categories.forEach((level_0) => {
    if (level_0.subCategories) {
      level_0.subCategories.forEach((level_1) => {
        flatArray.push({
          value: level_1.id,
          platformId: level_1?.platformId,
          mappedWithCategories: level_1.mappedWithCategory,
          label: `${level_0.name} --> ${level_1.name}`,
        });
        if (level_1.subCategories) {
          level_1.subCategories.forEach((level_2) => {
            flatArray.push({
              value: level_2.id,
              platformId: level_2?.platformId,
              mappedWithCategories: level_2.mappedWithCategory,
              label: `${level_0.name} --> ${level_1.name} --> ${level_2.name}`,
            });
          });
        }
      });
    }
  });
  return flatArray;
};

export default flatAePlatformCategoriesLeafNodes;
