import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import Twitter from "assets/icons/twitter-x.svg";
import placeholderImg from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import NumberFormatter from "components/NumberFormatter";
import StyledDataGrid from "components/StyledGrid";
import StyledSwitch from "components/ToggleButton";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import {
  addFeaturedVideo,
  deleteVideo,
  getVideos,
  removeFeaturedVideo,
} from "store/slices/videosSlice";
import { FACEBOOK, PRIMARY, WHATSAPP } from "styles/colors";
import convertSlugToUrl from "utils/helpers/convertSlugToUrl";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const isLed = useMediaQuery((theme) => theme.breakpoints.up("xl"));

  const { results, loading, totalPages } = useSelector((state) => state.videos);

  const fetchVideos = useCallback(() => {
    dispatch(getVideos(params));
  }, [dispatch, params]);

  const updateVideosRow = useCallback(
    (slug) => {
      navigate(`/videos/${slug}/update`);
    },
    [navigate]
  );

  const handleChange = useCallback(
    (row) => {
      if (row.featured === false) {
        dispatch(
          addFeaturedVideo({
            id: row.id,
            featured: true,
          })
        )
          .unwrap()
          .then(() => fetchVideos());
      } else {
        dispatch(
          removeFeaturedVideo({
            id: row.id,
            featured: false,
          })
        )
          .unwrap()
          .then(() => fetchVideos());
      }
    },
    [dispatch, fetchVideos]
  );

  const handleClickOpen = useCallback(
    (userId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(userId));
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchVideos();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchVideos]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.2,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
      },
      {
        field: "slug",
        headerName: "",
        minWidth: 30,
        flex: 0.1,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <Box
            component="a"
            href={convertSlugToUrl(params?.row?.slug)}
            target="_blank"
            rel="noreferrer"
          >
            <PlayCircleIcon
              fontSize="large"
              sx={{
                background: PRIMARY,
                px: "3px",
                color: "white",
                borderRadius: "18px",
              }}
            />
          </Box>
        ),
      },
      {
        field: "thumbnail",
        headerName: "Thumbnail",
        type: "actions",
        align: "center",
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        minWidth: 100,
        flex: 0.8,
        renderCell: (params) => (
          <Box>
            <img
              src={
                params?.row?.thumbnail ? params?.row?.thumbnail : placeholderImg
              }
              height={
                isTablet ? "70px" : isLaptop ? "80px" : isLed ? "85px" : "113px"
              }
              width={
                isTablet
                  ? "70px"
                  : isLaptop
                  ? "80px"
                  : isDesktop
                  ? "85px"
                  : "113px"
              }
              style={{
                borderRadius: "10px",
                marginTop: 4,
                objectFit: "contain",
              }}
              alt={params?.row?.title}
            />
          </Box>
        ),
      },
      {
        field: "title",
        headerName: "Title/Details",
        minWidth: 120,
        flex: 1,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <Box
            sx={{
              maxHeight: "70px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
            }}
          >
            <Tooltip
              title={
                <p style={{ margin: 0, fontSize: "14px" }}>
                  {params?.row?.title}
                </p>
              }
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  wordBreak: "break-all",
                }}
              >
                {params?.row?.title}
              </Typography>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: "totalViews",
        headerName: "BG Views",
        minWidth: 80,
        flex: 0.7,
        align: "center",
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <NumberFormatter
            number={params?.row?.totalViews ?? 0}
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ),
      },
      {
        field: "fbTotalViews",
        headerName: "FB Views",
        minWidth: 80,
        flex: 0.7,
        align: "center",
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <NumberFormatter
            number={params?.row?.fbTotalViews ?? 0}
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
        ),
      },
      {
        field: "featured",
        headerName: "Featured",
        minWidth: 40,
        headerAlign: "center",
        flex: 0.6,
        align: "center",
        type: "number",
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <FormControlLabel
            control={
              <StyledSwitch
                checked={params?.row?.featured}
                onChange={() => handleChange(params?.row)}
              />
            }
          />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        minWidth: 80,
        flex: 0.6,
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => updateVideosRow(params?.row?.slug)}
              >
                <img src={WritingIcon} alt="edit" className="icon-size" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => handleClickOpen(params?.id)}
              >
                <img src={deleteIcon} alt="delete" />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: "share",
        headerName: "Share",
        minWidth: 150,
        flex: 1.2,
        align: "center",
        headerAlign: "center",
        filterable: false,
        disableColumnFilter: true,
        editable: false,
        sortable: false,
        renderCell: (params) => (
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <TwitterShareButton
              url={convertSlugToUrl(params?.row?.slug)}
              title={params?.row?.title}
            >
              <img src={Twitter} alt="icon" width="22px" height="22px" />
            </TwitterShareButton>
            <FacebookShareButton
              url={convertSlugToUrl(params?.row?.slug)}
              quote={params?.row?.title}
              href={params?.row?.thumbnail}
            >
              <FacebookOutlinedIcon
                fontSize="large"
                sx={{
                  background: "white",
                  color: FACEBOOK,
                }}
              />
            </FacebookShareButton>
            <WhatsappShareButton
              url={convertSlugToUrl(params?.row?.slug)}
              title={params?.row?.title}
              media={params?.row?.thumbnail}
            >
              <WhatsAppIcon
                fontSize="large"
                sx={{
                  background: "white",
                  color: WHATSAPP,
                  borderRadius: "18px",
                }}
              />
            </WhatsappShareButton>
          </Box>
        ),
      },
    ],
    [
      isTablet,
      isLaptop,
      isLed,
      isDesktop,
      handleChange,
      updateVideosRow,
      handleClickOpen,
    ]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteVideo} />
    </>
  );
}

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
