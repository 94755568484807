import ChatIcon from "@mui/icons-material/Chat";
import Content from "assets/icons/Content .svg";
import Dashboard from "assets/icons/Dashboard.svg";
import Notifications from "assets/icons/Notifications.svg";
import Orders from "assets/icons/Orders.svg";
import Products from "assets/icons/Products.svg";
import Users from "assets/icons/Users.svg";
import Videos from "assets/icons/Videos.svg";
import Stores from "assets/icons/shop.svg";

import USER_ROLE from "./userRole";

const SideBarListItems = [
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
    title: "Dashboard",
    path: "/dashboard",
    icon: Dashboard,
    children: [
      {
        roles: [USER_ROLE.ADMIN],
        title: "Insights",
        path: "/insights",
      },
    ],
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
    title: "Products",
    path: "/products",
    icon: Products,
    children: [
      {
        roles: [USER_ROLE.ADMIN],
        title: "Categories",
        path: "/categories",
      },
      {
        roles: [USER_ROLE.ADMIN],
        title: "Group Buy",
        path: "/group-buy",
      },
      {
        roles: [USER_ROLE.ADMIN],
        title: "Collections",
        path: "/collections",
      },
      {
        roles: [USER_ROLE.ADMIN],
        title: "Vouchers",
        path: "/vouchers",
      },
    ],
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
    title: "Videos",
    path: "/videos",
    icon: Videos,
    children: [
      {
        roles: [USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
        title: "Video Upload",
        path: "/upload",
      },
    ],
  },
  {
    roles: [USER_ROLE.ADMIN],
    title: "Stores",
    path: "/stores",
    icon: Stores,
  },
  {
    roles: [USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
    title: "Store",
    path: "/store",
    icon: Stores,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
    title: "Orders",
    path: "/orders",
    icon: Orders,
    children: [
      {
        roles: [USER_ROLE.ADMIN],
        title: "Full Orders",
        path: "/full-orders",
      },
      {
        roles: [
          USER_ROLE.ADMIN,
          USER_ROLE.SUPPLIER,
          USER_ROLE.REQUESTED_SELLER,
        ],
        title: "Returns",
        path: "/returns",
      },
    ],
  },
  {
    // disabled: true,
    roles: [USER_ROLE.ADMIN],
    title: "Content",
    path: "/content/banner-set",
    icon: Content,
    children: [
      {
        roles: [USER_ROLE.ADMIN],
        title: "Banners",
        path: "/banner-set",
      },
    ],
  },
  {
    roles: [USER_ROLE.ADMIN],
    title: "Users",
    path: "/users",
    icon: Users,
    children: [
      {
        roles: [USER_ROLE.ADMIN],
        title: "Requested Sellers",
        path: "/requested-sellers",
      },
      {
        roles: [USER_ROLE.ADMIN],
        title: "Sellers",
        path: "/sellers",
      },
    ],
  },
  {
    roles: [USER_ROLE.ADMIN],
    title: "Master Table",
    path: "/master-table",
    icon: Orders,
  },
  {
    roles: [USER_ROLE.SUPPLIER, USER_ROLE.REQUESTED_SELLER],
    title: "Chat",
    path: "/chat",
    icon: <ChatIcon sx={{ width: "33px", height: "34px" }} />,
  },
  {
    roles: [USER_ROLE.ADMIN],
    title: "Notifications",
    path: "/notifications",
    icon: Notifications,
  },
];

export default SideBarListItems;
