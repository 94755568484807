import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";

function StockControlSection({ formik }) {
  return (
    <StyledMuiCard
      title="Stock Control"
      subTitle="Enter the stock of the products"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Quantity in Stock
          </Typography>
          <StyledTextField
            fullWidth
            type="number"
            {...formik.getFieldProps("quantity")}
            placeholder="0"
            py={2}
            error={Boolean(formik.touched.quantity && formik.errors.quantity)}
            helperText={
              formik.touched.quantity && !!formik.errors.quantity
                ? formik.errors.quantity
                : "This quantity would be ignored if variants are added"
            }
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
          />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

StockControlSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(StockControlSection, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.quantity === nextProps.formik.values.quantity &&
    prevProps.formik.errors.quantity === nextProps.formik.errors.quantity &&
    prevProps.formik.touched.quantity === nextProps.formik.touched.quantity
  );
}
