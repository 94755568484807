import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CategoryDataGrid from "components/Category/CategoryDataGrid";
import CategoryForm from "components/Category/CategoryForm";
import CategoryHeaderComponents from "components/Category/CategoryHeaderComponents/index";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { store } from "store/configureStore";
import {
  getCategories,
  getCategory,
  setCategory,
} from "store/slices/categoriesSlice";
import { getCategories as getFiltersCategories } from "store/slices/filtersSlice";

function Categories({ create, edit, addSubCategory }) {
  const category = useSelector((state) => state.categories.categoryName);
  if (create || edit || addSubCategory) {
    return <CategoryForm />;
  }

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        {category === undefined ? "All" : `${category}`} Categories
      </Typography>
      <CategoryHeaderComponents />
      <CategoryDataGrid />
    </Box>
  );
}

Categories.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
  addSubCategory: PropTypes.bool,
};

Categories.defaultProps = {
  create: false,
  edit: false,
  addSubCategory: false,
};

export default Categories;

export function loadCategories({ params }) {
  let data = {
    params: {
      platform: "bazaarGhar",
    },
  };
  if (params.lvl2 || params.lvl3) {
    data = {
      id: params.lvl3 ?? params.lvl2,
    };
  }
  return store.dispatch(getCategories(data));
}
export function loadCategory({ params }) {
  let category = store
    .getState()
    .categories.results.find((c) => c.id === params.id);

  if (category) {
    store.dispatch(setCategory(category));
  } else {
    store.dispatch(getCategory(params.id));
  }

  if (store.getState().filters.flattenedCategories.length === 0) {
    store.dispatch(getFiltersCategories());
  }

  return null;
}
