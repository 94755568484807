import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import eyeIcon from "assets/icons/eyeIcon.svg";
import WritingNote from "assets/images/WritingNote.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFullOrders } from "store/slices/fullOrdersSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

function FullOrdersDataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { results, loading, totalPages } = useSelector(
    (state) => state.fullOrders
  );

  const fetchOrders = useCallback(() => {
    dispatch(getFullOrders(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchOrders();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchOrders]);

  const columns = [
    {
      field: "serialNumber",
      headerName: "Sr",
      minWidth: 60,
      flex: 0.2,
    },
    {
      field: "OrderDetailId",
      headerName: "Order ID",
      minWidth: 160,
      flex: 1.1,
      renderCell: (params) => (
        <Typography>{params?.row?.OrderDetailId}</Typography>
      ),
    },
    {
      field: "customer",
      headerName: "Customer Name",
      minWidth: 100,
      flex: 0.8,
      sortable: false,
      renderCell: (params) => (
        <Typography
          textTransform="capitalize"
          sx={{
            textTransform: "capitalize",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {params?.row?.customer?.fullname}
        </Typography>
      ),
    },
    {
      field: "orderAddress",
      headerName: "City",
      minWidth: 100,
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <Typography
          sx={{
            textTransform: "capitalize",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {params?.row?.orderAddress?.city}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      minWidth: 100,
      flex: 0.6,
      renderCell: (params) => (
        <Typography>
          {dayjs(params?.row?.createdAt).format("DD/MM/YYYY")}
        </Typography>
      ),
    },

    {
      field: "payable",
      headerName: "Payable",
      minWidth: 100,
      flex: 0.7,
      renderCell: (params) => (
        <CurrencyFormatter
          number={params?.row?.payable}
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      type: "actions",
      minWidth: 40,
      flex: 0.5,
      renderCell: (params) => (
        <Chip
          sx={{
            backgroundColor: statusBadges(params?.row?.status).bgColor,
            color: statusBadges(params?.row?.status).color,
            borderRadius: "5px",
            height: "26px",
            textTransform: "capitalize",
            minWidth: "75px",
            fontSize: "12px",
          }}
          variant="contained"
          label={params?.row?.status}
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" sx={actionStyles}>
          <Tooltip title="View">
            <IconButton size="small" onClick={() => navigate(params.row.id)}>
              <img src={eyeIcon} alt="view" className="icon-size" />
            </IconButton>
          </Tooltip>
          {params?.row?.orderNote && (
            <Tooltip title={params.row.orderNote}>
              <IconButton variant="text" className="button-styles">
                <img src={WritingNote} alt="edit" className="icon-size" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <StyledDataGrid
      rows={results}
      columns={columns}
      loading={loading}
      totalPages={totalPages}
    />
  );
}

export default memo(FullOrdersDataGrid);

const actionStyles = {
  "& .icon-size": { height: "25px", width: "25px" },
};
