import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import grey from "@mui/material/colors/grey";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { STATS_NUMBER_FORMATTER_COLOR } from "styles/colors";
import splitCamelCase from "utils/helpers/splitCamelCase";

function InsightCard({
  keyword,
  value,
  icon,
  isSingleRowed,
  showTime,
  showPercentage,
}) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let str = "";

    if (hours) str += `${hours}h `;
    if (minutes) str += `${minutes}m `;
    if (remainingSeconds && !hours) str += `${remainingSeconds}s`;

    return str;
  };

  return (
    <Card
      sx={{
        p: "1.5rem",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1}
        sx={{
          minHeight: {
            xs: "90px",
            lg: isOpen ? "100px" : "90px",
            xl: isSingleRowed || isOpen ? "100px" : "190px",
            xxl: isSingleRowed ? "125px" : "200px",
          },
        }}
      >
        <Box>
          <Typography
            variant="h6"
            component="h3"
            fontWeight="bold"
            color={grey[700]}
            sx={{
              fontSize: {
                xs: "0.9rem",
                lg: "1rem",
                xl: "1rem",
                xxl: "1.15rem",
              },
            }}
          >
            {splitCamelCase(keyword)}
          </Typography>
          {!showTime && !showPercentage && (
            <NumberFormatter
              sx={{
                fontSize: {
                  xs: "3rem",
                  lg: isOpen ? "2rem" : "2rem",
                  xl: "2.25rem",
                  xxl: "2.5rem",
                },
                fontWeight: "bold",
                color: STATS_NUMBER_FORMATTER_COLOR,
                height: { md: "55px", lg: "30px", xxl: "55px" },
              }}
              number={Math.round(Number(value) ?? 0)}
            />
          )}
          {showTime && (
            <Typography
              fontSize={{
                xs: "3rem",
                lg: isOpen ? "2rem" : "2rem",
                xl: "2.25rem",
                xxl: "2.5rem",
              }}
              fontWeight="bold"
              color={STATS_NUMBER_FORMATTER_COLOR}
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {Math.round(Number(value))
                ? formatTime(Math.round(Number(value)))
                : 0}
            </Typography>
          )}
          {showPercentage && (
            <Typography
              fontSize={{
                xs: "3rem",
                lg: isOpen ? "2rem" : "2rem",
                xl: "2.25rem",
                xxl: "2.5rem",
              }}
              fontWeight="bold"
              color={STATS_NUMBER_FORMATTER_COLOR}
            >
              {`${Number(Number(value * 100).toFixed(2))}%`}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="#eafafd"
          borderRadius="50%"
          minWidth={{
            xs: "70px",
            lg: isOpen ? "80px" : "70px",
            xl: "90px",
            xxl: isSingleRowed ? "100px" : "115px",
          }}
          height="100%"
          width="auto"
          sx={{
            aspectRatio: "1/1",
            "& .MuiSvgIcon-root": {
              fontSize: {
                xs: "2.75rem",
                lg: isOpen ? "2.5rem" : "2rem",
                xl: "2.5rem",
                xxl: isSingleRowed ? "2.75rem" : "3.25rem",
              },
              color: "primary.main",
            },
          }}
        >
          {icon}
        </Box>
      </Stack>
    </Card>
  );
}

InsightCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  keyword: PropTypes.string.isRequired,
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.number).isRequired,
  isSingleRowed: PropTypes.bool,
  showTime: PropTypes.bool,
  showPercentage: PropTypes.bool,
};

InsightCard.defaultProps = {
  keyword: "",
  value: 0,
  isSingleRowed: false,
  showTime: false,
  showPercentage: false,
};

export default InsightCard;
