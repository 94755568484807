import "@fontsource/inter";
import dayjs from "dayjs";
import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.min.css";
import { store } from "store/configureStore";
import { logout } from "store/slices/authSlice";
import "styles/global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Helper function to check token expiry
const isRefreshTokenExpired = () => {
  const expiryTime = localStorage.getItem("refresh-token-expires");
  if (!expiryTime) return false;
  return dayjs().isAfter(expiryTime);
};

if (isRefreshTokenExpired()) {
  // Clear auth data from local storage and Redux store
  store.dispatch(logout());

  // Redirect to the login page
  window.location.href = "/";
} else {
  // Proceed with rendering the app
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
