import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DatePicker from "components/Pickers/DatePicker";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo } from "react";

function TimeDuration({ formik }) {
  return (
    <StyledMuiCard
      title="Time Duration"
      subTitle="Enter start and end date of the offer."
    >
      <CardContent>
        <Stack
          direction="column"
          gap={3}
          py={{ xs: 1, md: 1.5 }}
          paddingLeft={1.5}
        >
          <Box display="flex" gap={3}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Start Date
              </Typography>
              <DatePicker
                name="startDate"
                value={dayjs(formik.values.startDate)}
                onBlur={formik.handleBlur}
                disabled={!formik?.values?.productId}
                onChange={(value) =>
                  formik.setFieldValue("startDate", value.toString(), true)
                }
                error={Boolean(
                  formik.touched.startDate && formik.errors.startDate
                )}
                helperText={
                  formik.touched.startDate && !!formik.errors.startDate
                    ? formik.errors.startDate
                    : "Select activation time for the deal."
                }
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                End Date
              </Typography>
              <DatePicker
                name="endDate"
                value={dayjs(formik.values.endDate)}
                error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                onBlur={formik.handleBlur}
                disabled={!formik?.values?.productId}
                onChange={(value) =>
                  formik.setFieldValue("endDate", value.toString(), true)
                }
                helperText={
                  formik.touched.endDate && !!formik.errors.endDate
                    ? formik.errors.endDate
                    : "Select expiry time for the deal."
                }
              />
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

TimeDuration.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(TimeDuration);
