import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import removeDuplicateObjectsById from "utils/helpers/removeDuplicateObjectsById";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

const initialState = {
  loading: true,
  notificationLoading: false,
  results: [],
  page: 0,
  limit: 10,
  totalPages: 0,
  totalResults: 0,
  error: "",
  unreadNotificationsCount: 0,
};

export const sendNotification = createAsyncThunk(
  "notifications/sendNotification",
  async (values = {}, { rejectWithValue }) => {
    try {
      const response = await http.post(`/push-notification/send-one`, values);
      const responseData = getResponseData(response);
      toast.success("Notification sent successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const sendNotificationToAll = createAsyncThunk(
  "notifications/sendNotificationToAll",
  async (values = {}, { rejectWithValue }) => {
    try {
      const response = await http.post(`/push-notification/send`, values);
      const responseData = getResponseData(response);
      toast.success("Notification sent successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoadingState: (state, action) => {
      state.loading = action.payload;
    },
    setUnreadNotificationsCount: (state, action) => {
      state.unreadNotificationsCount = action.payload;
    },
    setNotificationsData: (state, action) => {
      if (state.page < action?.payload?.page) {
        state.results = removeDuplicateObjectsById([
          ...state.results,
          ...action.payload.results,
        ]);
        state.page = action.payload?.page;
      }
      state.loading = false;
      state.limit = action.payload?.limit;
      state.totalPages = action.payload?.totalPages;
      state.totalResults = action.payload?.totalResults;
    },
    prependNotification: (state, action) => {
      state.results = [action.payload, ...state.results];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendNotification.pending, (state) => {
        state.notificationLoading = true;
        state.error = false;
      })
      .addCase(sendNotification.fulfilled, (state, action) => {
        state.results = action.payload;
        state.notificationLoading = false;
      })
      .addCase(sendNotification.rejected, (state) => {
        state.notificationLoading = false;
        state.error = true;
      })
      .addCase(sendNotificationToAll.pending, (state) => {
        state.notificationLoading = true;
        state.error = false;
      })
      .addCase(sendNotificationToAll.fulfilled, (state, action) => {
        state.results = action.payload;
        state.notificationLoading = false;
      })
      .addCase(sendNotificationToAll.rejected, (state) => {
        state.notificationLoading = false;
        state.error = true;
      });
  },
});

export const {
  resetState,
  setLoadingState,
  setUnreadNotificationsCount,
  setNotificationsData,
  prependNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
