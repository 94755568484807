import Box from "@mui/material/Box";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import propTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  BUTTON_HOVER,
  BUTTON_HOVER_BORDER_COLOR,
  FILTERS_BORDER_COLOR,
  FILTER_TEXT_COLOR,
} from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

const maxDate = dayjs(new Date());

export default function MonthPicker() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const [value, setValue] = useState(dayjs());
  const [error, setError] = useState(null);

  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxDate": {
        return "Date can't be greater than current date";
      }
      case "invalidDate": {
        return "Your date is not valid";
      }
      default: {
        return "";
      }
    }
  }, [error]);

  const handleAccept = useCallback(
    (newValue) => {
      if (dayjs(newValue).isValid() && newValue.$d < new Date()) {
        setValue(newValue);
        const _params = { ...params };
        _params.month = newValue.format("YYYY-MM");
        navigate({
          search: createSearchParams(_params).toString(),
        });
      }
    },
    [navigate, params]
  );

  useEffect(() => {
    if (params?.month && dayjs(params?.month).isValid())
      setValue(() => dayjs(params.month));
  }, [params]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <DatePicker
          label={"Month"}
          onError={(newError) => setError(newError)}
          views={["year", "month"]}
          openTo="year"
          format="MM/YYYY"
          value={value}
          slotProps={{
            textField: {
              size: "small",
              helperText: errorMessage,
              sx: {
                "& .MuiFormHelperText-root": {
                  fontSize: "0.8rem",
                  marginLeft: 0,
                  marginRight: 0,
                  maxWidth: 180,
                },
                "& .MuiOutlinedInput-root": {
                  height: "44px",
                  maxWidth: 180,
                  color: FILTER_TEXT_COLOR,
                  backgroundColor: "#fff",
                  "& fieldset": {
                    borderColor: FILTERS_BORDER_COLOR,
                  },
                  "&:hover": {
                    backgroundColor: BUTTON_HOVER,
                    "& fieldset": {
                      borderColor: BUTTON_HOVER_BORDER_COLOR,
                    },
                  },
                },
              },
            },
          }}
          maxDate={maxDate}
          onChange={(newValue) => setValue(newValue)}
          onAccept={handleAccept}
        />
      </Box>
    </LocalizationProvider>
  );
}

MonthPicker.propTypes = {
  onChange: propTypes.func,
};
