import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import {
  getAdminProducts,
  getShopifyProducts,
  getShopifyToken,
  resetShopify,
} from "store/slices/productsSlice";
import { openSelectProductStoreDialog } from "store/slices/uiSlice";
import { FILTERS_BACKGROUND_COLOR } from "styles/colors";
import { toast } from "utils/hooks/useToast";
import ShopifyDialog from "./ProductForm/Shopify/ShopifyDialog";
import SelectStoreDialog from "./SelectStoreDialog";

export default function AddActions() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isDelayed, setIsDelayed] = useState(false);
  const userRole = useSelector((state) => state.auth?.userRole);
  const { shopify, shopifyProducts } = useSelector((state) => state?.products);

  const handleClick = useCallback(() => {
    if (userRole !== "admin") {
      navigate("create");
    } else dispatch(openSelectProductStoreDialog());
  }, [dispatch, navigate, userRole]);

  const fetchProducts = useCallback(() => {
    if (shopify?.fetchProducts) {
      setOpen(true);
    } else {
      const data = {
        shopName: shopify?.shopName,
        categoryId: shopify?.categoryId,
      };
      dispatch(getShopifyProducts(data))
        .unwrap()
        .then(() => {
          dispatch(getAdminProducts())
            .unwrap()
            .then(() => {
              dispatch(getShopifyToken());
              toast.success("Products are imported successfully");
            });
        });
    }
  }, [
    dispatch,
    shopify?.categoryId,
    shopify?.fetchProducts,
    shopify?.shopName,
  ]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      !shopify?.token && userRole !== "admin" && dispatch(getShopifyToken());
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, shopify?.token, userRole]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(resetShopify());
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => setIsDelayed(true), 4000);
    return () => clearTimeout(timer); // Clear timeout if component unmounts
  }, []);

  return (
    <Box sx={containerStyles}>
      <Button
        startIcon={<AddIcon />}
        size="large"
        variant="contained"
        className="AddButtons"
        onClick={handleClick}
      >
        Add Product
      </Button>
      {userRole !== "admin" && (
        <Button
          sx={{
            minWidth: 170,
            maxWidth: 180,
            background: FILTERS_BACKGROUND_COLOR,
          }}
          startIcon={<AddIcon />}
          variant="outlined"
          className="AddButtons"
          onClick={() => navigate("bulk-upload")}
        >
          Bulk Upload
        </Button>
      )}
      {userRole !== "admin" && (
        <Button
          sx={{
            minWidth: 170,
            maxWidth: 180,
            background: FILTERS_BACKGROUND_COLOR,
          }}
          variant="outlined"
          className="AddButtons"
          onClick={() => navigate("shopify")}
        >
          Shopify token
        </Button>
      )}
      {isDelayed && !shopify?.fetchProducts && userRole !== "admin" && (
        <Button
          sx={{
            minWidth: 170,
            maxWidth: 180,
            background: FILTERS_BACKGROUND_COLOR,
          }}
          startIcon={
            shopifyProducts && <CircularProgress size={20} thickness={6} />
          }
          variant="outlined"
          className="AddButtons"
          onClick={fetchProducts}
        >
          Fetch Products
        </Button>
      )}
      <SelectStoreDialog />
      <ShopifyDialog handleClose={handleClose} open={open} />
    </Box>
  );
}
const containerStyles = {
  display: "flex",
  gap: 2,
  "& .AddButtons": {
    height: "44px",
    textTransform: "none",
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
    },
    cursor: "pointer",
  },
};
