import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadStoreMedia } from "services/store";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getStores = createAsyncThunk(
  "stores/getStores",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().stores;
    try {
      const response = await http.get(`/sellerDetail`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getProvinces = createAsyncThunk(
  "stores/getProvinces",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`/shop-china/address`, {
        params: {
          countryCode: data,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const createStore = createAsyncThunk(
  "stores/createStore",
  async (values, { rejectWithValue }) => {
    try {
      const response = await http.post(`/sellerDetail`, values.data);
      const responseData = getResponseData(response);
      if (typeof values.image === "object" || typeof values.logo === "object") {
        const formData = new FormData();
        if (typeof values.image === "object")
          formData.append("images", values.image);
        if (typeof values.logo === "object") {
          formData.append("sellerDetailLogo", values.logo);
        }
        await uploadStoreMedia(responseData?.id, formData);
      }
      toast.success("Store created successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAdminSellerDetailById = createAsyncThunk(
  "stores/getAdminSellerDetailById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/sellerDetail/admin/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteStore = createAsyncThunk(
  "stores/deleteStore",
  async (storeId, { getState, rejectWithValue }) => {
    const { results } = getState().stores;
    try {
      const response = await http.delete(`/sellerDetail/${storeId}`);
      const responseData = getResponseData(response);
      if (responseData?.status === 200 || response?.status === 204) {
        toast.success("Store deleted successfully");
        const returnedData = results.filter((data) => data.id !== storeId);
        return returnedData;
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const getStore = createAsyncThunk(
  "stores/getStore",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/sellerDetail/current`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateStore = createAsyncThunk(
  "stores/updateStore",
  async (values, { rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/sellerDetail/${values.id}`,
        values.data
      );
      const responseData = getResponseData(response);
      if (
        typeof values.image === "object" ||
        typeof values.sellerDetailLogo === "object"
      ) {
        const formData = new FormData();
        if (typeof values.image === "object")
          formData.append("images", values.image);
        if (typeof values.sellerDetailLogo === "object") {
          formData.append("sellerDetailLogo", values.sellerDetailLogo);
        }
        await uploadStoreMedia(values.id, formData);
      }
      if (response.status === 200) {
        toast.success("Store updated successfully");
      }

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getFacebookBusinesses = createAsyncThunk(
  "stores/getFacebookBusinesses",
  async (_, { getState, rejectWithValue }) => {
    const facebookResponse = getState().stores.facebookResponse;
    try {
      const response = await http.get(`/social/business`, {
        params: {
          fbToken: facebookResponse?.accessToken ?? "",
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getFacebookPages = createAsyncThunk(
  "stores/getFacebookPages",
  async (data, { getState, rejectWithValue }) => {
    const facebookResponse = getState().stores.facebookResponse;
    try {
      const response = await http.get(`/social/page`, {
        params: {
          fbToken: facebookResponse?.accessToken ?? "",
          userId: facebookResponse.userID,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const createFacebookCatalog = createAsyncThunk(
  "stores/createFacebookCatalog",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/catalog/create`, data);
      const responseData = getResponseData(response);
      toast.success("Catalog created successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      if (message === "product is not defined") {
        toast.error("These is no product that can be added to catalog");
      } else toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  loadingFacebook: {
    businesses: false,
    pages: false,
    catalog: false,
  },
  storeLoading: true,
  store: null,
  facebookResponse: null,
  /* TODO: Refactor facebook response after testing */
  // facebookResponse: {
  //   name: "Asad Ahmed",
  //   id: "301917068528259",
  //   userID: "301917068528259",
  //   expiresIn: 4846,
  //   accessToken:
  //     "EAAC0YxsUguoBO0BaVPZAMuMjfIZAilfUFQYiIfzY2eY0xUDfh1ZCJOhtlMvXWtc19e8Wm0nFHTZB1ZAQfg4tfkf0FdNwfRUlPwf5JHuGF0suDnY9kw0ZCAchdVVjWwxcdt70wrXo8LuZBYbzzn7gPKvEU1rlxjbrGCmPjRqxBNBFxZB5enA4FxgTf0ZAMNLr82FZB6llmkXnwqKfRbRw4oj1hwZBUrMQzwi",
  //   signedRequest:
  //     "fA-kwq3kJ6TFB9mmgQjC5cRyyNtSaTOL6bF9ERyu7OE.eyJ1c2VyX2lkIjoiMzAxOTE3MDY4NTI4MjU5IiwiY29kZSI6IkFRRDBxUUR4REs0Yk96enMzZkdLaC1ILXdqaXJXWGtYQnlDejlIajlTWmFQdjZwZVdHZGc2Z2tvaXZLbkR4dlRCdXZsc18wdGlWRmM2Y0Y3OUZ6bVVCNk1RdFNUVjBJUElka2xja1U1aWNoRVNpTEczS1lHLXdIc3NsaFZlTWlUVXp4YVhxejZOdTAyYV9keGxUTnNGdEJjXy1VS2VoWWt4Q1FIaVU4Q0owMzZsOGZmSy1YWi13NnVRbjdrbnpFWUI5S2IwMC1nUGE1em1nSlBrSVh5ZEc1YWthSFpwX0VjYkprYVlfbXJKX1RhVzBJRTZ3eTNwQkw4WndBZjduY1U0U3R4dGRvR2NValhCTGFYdHRWZk4zRWs2QmRuMUR4TlBBZjByUkZOVGx0T21LVEtuZUhnR1dOZkJ2UHphZG1CdFduclFjdG05YktvY3BxdXVQQW83WEotIiwiYWxnb3JpdGhtIjoiSE1BQy1TSEEyNTYiLCJpc3N1ZWRfYXQiOjE3MDE4NTU1NTR9",
  //   graphDomain: "facebook",
  //   data_access_expiration_time: 1709631554,
  // },
  facebookBusinesses: null,
  facebookPages: null,
  results: [],
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  provinces: [],
  error: "",
  facebookError: {
    businesses: "",
    pages: "",
    catalog: "",
  },
  translatedData: null,
};

const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    // Actions
    resetState: () => initialState,
    setStoreDetail: (state, action) => {
      state.store = action.payload;
    },
    setFacebookResponse: (state, action) => {
      state.facebookResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStores.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getStores.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getStores.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStore.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteStore.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAdminSellerDetailById.pending, (state) => {
        state.storeLoading = true;
        state.error = false;
      })
      .addCase(getAdminSellerDetailById.fulfilled, (state, action) => {
        state.storeLoading = false;
        state.store = action.payload;
      })
      .addCase(getAdminSellerDetailById.rejected, (state) => {
        state.storeLoading = false;
        state.error = true;
      })
      .addCase(updateStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        state.loading = false;
        state.sellerDetail = action.payload;
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getStore.pending, (state) => {
        state.storeLoading = true;
        state.error = "";
      })
      .addCase(getStore.fulfilled, (state, action) => {
        state.storeLoading = false;
        state.store = action.payload;
      })
      .addCase(getStore.rejected, (state, action) => {
        state.storeLoading = false;
        state.error = action.payload;
      })
      .addCase(createStore.pending, (state) => {
        state.loading = true;
      })
      .addCase(createStore.fulfilled, (state, action) => {
        state.loading = false;
        state.store = action.payload;
      })
      .addCase(createStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProvinces.fulfilled, (state, action) => {
        state.loading = false;
        state.provinces = action.payload.provinces;
      })
      .addCase(getFacebookBusinesses.pending, (state) => {
        state.loadingFacebook.businesses = true;
        state.facebookError.businesses = "";
      })
      .addCase(getFacebookBusinesses.fulfilled, (state, action) => {
        state.loadingFacebook.businesses = false;
        state.facebookBusinesses = action.payload;
      })
      .addCase(getFacebookBusinesses.rejected, (state, action) => {
        state.loadingFacebook.businesses = false;
        state.facebookError.businesses = action.payload;
      })
      .addCase(getFacebookPages.pending, (state) => {
        state.loadingFacebook.pages = true;
        state.facebookError.pages = false;
      })
      .addCase(getFacebookPages.fulfilled, (state, action) => {
        state.loadingFacebook.pages = false;
        state.facebookPages = action.payload;
      })
      .addCase(getFacebookPages.rejected, (state, action) => {
        state.loadingFacebook.pages = false;
        state.facebookError.pages = action.payload;
      })
      .addCase(createFacebookCatalog.pending, (state) => {
        state.loadingFacebook.catalog = true;
      })
      .addCase(createFacebookCatalog.fulfilled, (state) => {
        state.loadingFacebook.catalog = false;
      })
      .addCase(createFacebookCatalog.rejected, (state, action) => {
        state.loadingFacebook.catalog = false;
        state.facebookError.catalog = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, setStoreDetail, setFacebookResponse } =
  storesSlice.actions;

// Exporting default reducer
export default storesSlice.reducer;
