import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import RadialChart from "components/Dashboard/RadialChart";
import NumberFormatter from "components/NumberFormatter";
import { memo } from "react";
import { useSelector } from "react-redux";
import {
  DASHBOARD_CARD_TEXT_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
  STATS_ORANGE_SECONDARY,
  STATS_PRIMARY_BLUE,
  STATS_PRIMARY_RED,
} from "styles/colors";

function UsersCard() {
  const monthlyAnalytics = useSelector(
    (state) => state.dashboard.monthlyAnalytics.data
  );

  return (
    <Grid container height="258px">
      <Grid item xs={6} p="1rem">
        <CardTitle title="New Sign Up" />
        <Box>
          <Box display="flex" gap={4} mt={2}>
            <CircleIcon
              fontSize="small"
              style={{
                color: STATS_PRIMARY_BLUE,
              }}
            />
            <Typography
              fontSize="1.125rem"
              flex="0.6"
              color={DASHBOARD_CARD_TEXT_COLOR}
            >
              Customers
            </Typography>
            <NumberFormatter
              sx={{
                color: STATS_NUMBER_FORMATTER_COLOR,
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
              number={monthlyAnalytics?.users?.currMonthUsers?.user ?? 0}
            />
          </Box>

          <Box display="flex" gap={4} mt={2}>
            <CircleIcon
              fontSize="small"
              style={{
                color: STATS_ORANGE_SECONDARY,
              }}
            />
            <Typography
              fontSize="1.125rem"
              flex="0.6"
              color={DASHBOARD_CARD_TEXT_COLOR}
            >
              Sellers
            </Typography>
            <NumberFormatter
              sx={{
                color: STATS_NUMBER_FORMATTER_COLOR,
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
              number={monthlyAnalytics?.users?.currMonthUsers?.supplier ?? 0}
            />
          </Box>

          <Box display="flex" gap={4} mt={2}>
            <CircleIcon
              fontSize="small"
              style={{
                color: STATS_PRIMARY_RED,
              }}
            />
            <Typography
              fontSize="1.125rem"
              flex="0.6"
              color={DASHBOARD_CARD_TEXT_COLOR}
            >
              Other
            </Typography>
            <NumberFormatter
              sx={{
                color: STATS_NUMBER_FORMATTER_COLOR,
                fontSize: "1.25rem",
                fontWeight: "bold",
              }}
              number={
                monthlyAnalytics?.users?.currMonthUsers?.requestedSeller ?? 0
              }
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <RadialChart />
      </Grid>
    </Grid>
  );
}

export default memo(UsersCard);
