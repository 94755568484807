import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";

export const getInsights = createAsyncThunk(
  "insights/getInsights",
  async (data, { rejectWithValue }) => {
    const { keywords, from, to } = data;
    const _from = from ?? dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const _to = to ?? dayjs().format("YYYY-MM-DD");

    const analyticsAPI = http.post("/google-analytics", {
      from: _from,
      to: _to,
      metrics: keywords,
    });
    const usersGraphAPI = http.post("/google-analytics", {
      from: _from,
      to: _to,
      metrics: [{ name: "totalUsers" }],
      dimensions: [{ name: "date" }],
    });
    const sessionsGraphAPI = http.post("/google-analytics", {
      from: _from,
      to: _to,
      metrics: [{ name: "sessions" }],
      dimensions: [{ name: "date" }],
    });
    const viewsGraphAPI = http.post("/google-analytics", {
      from: _from,
      to: _to,
      metrics: [{ name: "screenPageViews" }],
      dimensions: [{ name: "date" }],
    });
    const ordersGraphAPI = http.get(`/analytics/ordersChart`, {
      params: {
        startDate: _from,
        endDate: _to,
        format: "day",
      },
    });
    const revenueAPI = http.get(`/analytics/revenue`, {
      params: {
        from: _from,
        to: _to,
      },
    });

    try {
      const response = await Promise.all([
        analyticsAPI,
        usersGraphAPI,
        sessionsGraphAPI,
        viewsGraphAPI,
        ordersGraphAPI,
        revenueAPI,
      ]);
      const insights = getResponseData(response[0]);
      const usersGraph = getResponseData(response[1]);
      const sessionsGraph = getResponseData(response[2]);
      const viewsGraph = getResponseData(response[3]);
      const ordersGraph = getResponseData(response[4]);
      const revenue = getResponseData(response[5]);
      return {
        insights: insights?.length ? insights[0] : insights,
        usersGraph,
        sessionsGraph,
        viewsGraph,
        ordersGraph,
        revenue: revenue?.length ? revenue[0] : revenue,
      };
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: true,
  insights: [],
  usersGraph: [],
  sessionsGraph: [],
  viewsGraph: [],
  ordersGraph: [],
  revenue: {},
  error: "",
};

const insightsSlice = createSlice({
  name: "insights",
  initialState,
  reducers: {
    // Actions
    resetInsightsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInsights.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getInsights.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.insights = action.payload.insights;
        state.usersGraph = action.payload.usersGraph;
        state.sessionsGraph = action.payload.sessionsGraph;
        state.viewsGraph = action.payload.viewsGraph;
        state.ordersGraph = action.payload.ordersGraph;
        state.revenue = action.payload.revenue;
      })
      .addCase(getInsights.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetInsightsState } = insightsSlice.actions;

// Exporting default reducer
export default insightsSlice.reducer;
