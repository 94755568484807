import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";
import formatCurrency from "utils/helpers/formatCurrency";

function CurrencyFormatter({ number, ...rest }) {
  return <Typography {...rest}>{formatCurrency(number)}</Typography>;
}

export default memo(CurrencyFormatter);
CurrencyFormatter.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

CurrencyFormatter.defaultProps = {
  number: 0,
};
