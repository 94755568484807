import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import FacebookIntegration from "components/Store/FacebookIntegration";
import CreateStore from "components/Stores/CreateStore";
import SellerStoreDataGrid from "components/Stores/SellerStoreDataGrid";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStore } from "store/slices/storesSlice";

function Store() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.stores?.storeLoading ?? false);
  const store = useSelector((state) => state.stores?.store ?? {});

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(getStore());
    }, 250);
    return () => {
      id && clearTimeout(id);
    };
  }, [dispatch]);

  if (loading) {
    return (
      <Box textAlign="center" py={5}>
        <CircularProgress />
      </Box>
    );
  }

  if (!Object.keys(store).length) {
    return <CreateStore />;
  }

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          STORE
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Box my={2}>
        <FacebookIntegration />
      </Box>
      <SellerStoreDataGrid />
    </Box>
  );
}

export default memo(Store);
