import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddActions from "components/Group_buy/AddActions";
import DataGrid from "components/Group_buy/DataGrid";
import GroupBuyForm from "components/Group_buy/GroupBuyForm";
import GroupBuyStatusBar from "components/Group_buy/GroupBuyStatusBar";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { store } from "store/configureStore";
import { getGroupBuyProduct, setGroupBuy } from "store/slices/groupBuySlice";
function GroupBuy({ create, edit }) {
  if (create || edit) {
    return <GroupBuyForm />;
  }
  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Group Buy
      </Typography>
      <AddActions />
      <GroupBuyStatusBar />
      <DataGrid />
    </Box>
  );
}

GroupBuy.propTypes = {
  create: PropTypes.bool,
  edit: PropTypes.bool,
};

GroupBuy.defaultProps = {
  create: false,
  edit: false,
};

export default GroupBuy;

export function loadGroupBuy({ params }) {
  let groupBuy = store
    .getState()
    .groupBuy.results.find((c) => c.id === params.id);
  if (groupBuy) {
    return store.dispatch(setGroupBuy(groupBuy));
  } else {
    return store.dispatch(getGroupBuyProduct(params.id));
  }
}
