import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { logout } from "store/slices/authSlice";
import { setSideBarClose, setSideBarOpen } from "store/slices/uiSlice";
import { toast } from "utils/hooks/useToast";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";

const Layout = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const navigate = useNavigate();
  const handleOnIdle = () => {
    toast.success("You have been logged out");
    dispatch(logout());
    navigate("/");
  };

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    debounce: 500,
    events: ["mousemove", "keydown", "mousedown", "scroll"],
    crossTab: true,
  });

  const handleDrawerOpen = useCallback(() => {
    dispatch(setSideBarOpen());
  }, [dispatch]);

  const handleDrawerClose = useCallback(() => {
    dispatch(setSideBarClose());
  }, [dispatch]);

  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    matches && dispatch(setSideBarClose());
  }, [matches, dispatch]);

  return (
    <Box
      sx={{ display: "flex" }}
      maxWidth="1920px"
      m="auto"
      position="relative"
      backgroundColor="#edf7fc"
    >
      <Header handleDrawerOpen={handleDrawerOpen} isOpen={isOpen} />
      <SideBar
        handleDrawerClose={handleDrawerClose}
        isOpen={isOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: { xl: "64px", lg: "64px", md: "54px", sm: "54px" },
          px: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Outlet />
        </Box>
        <Footer isOpen={isOpen} />
      </Box>
    </Box>
  );
};

export default Layout;
