import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCategories } from "store/slices/filtersSlice";
import { getProductById, resetProductState } from "store/slices/productsSlice";
import EditProductForm from "./EditProductForm";

function EditProduct() {
  const params = useParams();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.filters.categories);
  const productLoading = useSelector((state) => state.products.productLoading);
  const product = useSelector((state) => state.products.product);
  const categoriesLoading = useSelector((state) => state.filters.loading);

  useEffect(() => {
    dispatch(getProductById(params?.id));
  }, [params?.id, dispatch]);

  useEffect(() => {
    if (!categories.length) {
      dispatch(getCategories());
    }
  }, [categories, dispatch]);

  useEffect(
    () => () => {
      dispatch(resetProductState());
      dispatch(getCategories());
    },
    [dispatch]
  );

  if (!product && (productLoading || categoriesLoading))
    return (
      <Box
        height={200}
        my={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );

  if (product)
    return (
      <Box py={2} px={1}>
        <Box paddingTop={2} paddingBottom={1} px={1}>
          <CustomBreadcrumbs />
          <EditProductForm />
        </Box>
      </Box>
    );
}

EditProduct.displayName = "EditProduct";

export default memo(EditProduct);
