import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import SummaryTile from "./SummaryTile";

function SummarySection() {
  const order = useSelector((state) => state.orders.order);

  return (
    <Card>
      <CardContent
        sx={{
          p: 0,
          "&:last-Child": {
            p: 0,
          },
        }}
      >
        <Box bgcolor={CARD_HEADER_BACKGROUND} p={2}>
          <Typography variant="h6" fontWeight="bold">
            Total Summary
          </Typography>
        </Box>
        <Stack direction="column" p={2} gap={1}>
          <SummaryTile
            title="Product Total"
            value={order?.retailTotal ?? 0}
            price
          />
          <SummaryTile
            title="Discount"
            value={order?.discount ?? 0}
            price
            addBrackets
            color="primary.main"
          />
          {order?.adminDiscount > 0 && (
            <SummaryTile
              title="Admin Discount"
              value={order?.adminDiscount ?? 0}
              price
              divider
              addBrackets
              color="primary.main"
            />
          )}
          <SummaryTile title="Sub Total" value={order?.subTotal ?? 0} price />
          <SummaryTile
            title="Shipment Charges"
            value={order?.shippmentCharges ?? 0}
            price
            divider
          />
          <SummaryTile
            title="Total"
            value={order?.subTotal + order?.shippmentCharges ?? 0}
            price
            isBold
          />
          <SummaryTile
            title="Payable Amount"
            value={order?.subTotal + order?.shippmentCharges ?? 0}
            price
            divider={
              Object.values(order?.paymentTrace ?? {}).filter((value) => value)
                .length > 1
            }
          />
          {Object.values(order?.paymentTrace ?? {}).filter((value) => value)
            .length > 1 ? (
            <>
              {order?.paymentTrace?.walletPaid ? (
                <SummaryTile
                  title="Paid by Wallet"
                  value={order?.paymentTrace?.walletPaid ?? 0}
                  price
                />
              ) : null}
              {order?.paymentTrace?.cardPaid ? (
                <SummaryTile
                  title="Paid by Card"
                  value={order?.paymentTrace?.cardPaid ?? 0}
                  price
                />
              ) : null}
            </>
          ) : null}
          <SummaryTile
            title="Paid Amount"
            value={
              order?.subTotal + order?.shippmentCharges - order?.payable ?? ""
            }
            price
            color="green"
            addBrackets
          />
          <SummaryTile
            title="Payment Method"
            value={order?.paymentMethod ?? ""}
            paymentMethod
            divider
          />
          <SummaryTile
            title="Payable"
            value={order?.payable}
            isBold
            isPaid={
              (order?.paymentMethod === "card" || "wallet_card" || "wallet") &&
              order?.payable === 0
            }
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default memo(SummarySection);
