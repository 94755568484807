import { logout, refreshUserTokens } from "store/slices/authSlice";
import { getErrorData } from "utils/helpers/apiDataHelpers";

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => refreshSubscribers.push(cb);

const onTokenRefreshed = (token) => {
  refreshSubscribers.map((cb) => cb(token));
};

const interceptorConfiguration = async (instance) => {
  const store = (await import("store/configureStore"))?.store;
  instance.interceptors.request.use(
    (config) => {
      if (
        localStorage.getItem("token") &&
        localStorage.getItem("refresh-token") !== "undefined"
      ) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      //Do something with response error
      const originalRequestConfig = error?.config;
      const errorData = getErrorData(error);
      if (
        !originalRequestConfig?._retry &&
        ["401", 401, "403", 403].includes(errorData?.code)
      ) {
        if (isRefreshing) {
          return new Promise((resolve) => {
            subscribeTokenRefresh((token) => {
              originalRequestConfig.headers.Authorization = `Bearer ${token}`;
              resolve(instance(originalRequestConfig));
            });
          });
        }

        originalRequestConfig._retry = true;
        isRefreshing = true;

        try {
          const data = await store.dispatch(refreshUserTokens()).unwrap();
          const newAccessToken = data?.tokens?.access?.token;
          if (newAccessToken) {
            originalRequestConfig.headers = {
              ...originalRequestConfig.headers,
              Authorization: `Bearer ${newAccessToken}`,
            };
          }
          onTokenRefreshed(newAccessToken);
          return instance(originalRequestConfig);
        } catch (refreshError) {
          // Handle refresh failure (e.g., redirect to login)
          store.dispatch(logout());
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
          refreshSubscribers = [];
        }
      }
      return Promise.reject(error);
    }
  );
};

export default interceptorConfiguration;
