const setAuthState = (state, action) => {
  const data = action?.payload?.result ?? action?.payload;
  state.loading = false;
  state.error = null;
  state.isLoggedIn = true;
  state.token = data?.tokens?.access.token;
  state.id = data?.user?.id;
  state.fullName = data?.user?.fullname;
  state.userRole = data?.user?.role;
  state.data = data;
  localStorage?.setItem("id", data?.user?.id);
  localStorage.setItem("token", data?.tokens?.access?.token);
  localStorage.setItem("refresh-token", data?.tokens?.refresh?.token);
  localStorage.setItem("refresh-token-expires", data?.tokens?.refresh?.expires);
};

export default setAuthState;
