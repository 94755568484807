import { Autocomplete, CardContent, Grid, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { store } from "store/configureStore";
import { getCategories } from "store/slices/filtersSlice";
import {
  createShopifyToken,
  getShopifyToken,
  resetProductState,
  updateShopifyToken,
} from "store/slices/productsSlice";
import reduceObject from "utils/helpers/reduceObject";
import * as Yup from "yup";
import StyledMuiCard from "../../BulkUploadCard/StyledMuiCard";
import StyledTextField from "../StyledTextField";

function ShopifyForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { shopify, loading } = useSelector((state) => state?.products);
  const flattenedCategories = useSelector(
    (state) => state?.filters?.flattenedCategories ?? []
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      !flattenedCategories?.length && dispatch(getCategories());
    }, 500);
    return () => {
      clearTimeout(timeOut);
    };
  }, [dispatch, flattenedCategories?.length]);

  const initialValues = useMemo(
    () => ({
      token: shopify?.token ?? "",
      shopName: shopify?.shopName ?? "",
      clientId: shopify?.clientId ?? "",
      clientSecret: shopify?.clientSecret ?? "",
      category:
        flattenedCategories?.find(
          (ctg) => ctg?.value === shopify?.categoryId
        ) ?? null,
    }),
    [
      flattenedCategories,
      shopify?.categoryId,
      shopify?.clientId,
      shopify?.clientSecret,
      shopify?.shopName,
      shopify?.token,
    ]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (shopify?.id) {
        const data = {
          token: values.token,
          shopName: values.shopName,
          clientId: values.clientId,
          clientSecret: values.clientSecret,
          categoryId: values.category?.value,
        };

        let formValues = { ...data };
        let shopifyValues = { ...shopify };
        let updatedValues = reduceObject(formValues, shopifyValues);

        if (Object.keys(updatedValues).length === 0) {
          return navigate("/products");
        } else {
          dispatch(
            updateShopifyToken({ shopifyId: shopify?.id, updatedValues })
          )
            .unwrap()
            .then(() => {
              navigate("/products");
            });
        }
      } else {
        dispatch(
          createShopifyToken({
            token: values.token,
            shopName: values.shopName,
            clientId: values.clientId,
            clientSecret: values.clientSecret,
          })
        )
          .unwrap()
          .then(() => {
            navigate("/products");
          });
      }
    },
    [dispatch, navigate, shopify]
  );

  useEffect(() => {
    return () => dispatch(resetProductState());
  }, [dispatch]);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          PRODUCTS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Box display="flex" maxWidth="100%" justifyContent="space-between">
        <Typography
          variant="h6"
          fontWeight="600"
          color="primary.main"
          fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        >
          Shopify Token
        </Typography>
      </Box>
      <Box py={3}>
        <Formik
          enableReinitialize={true}
          validateOnBlur={true}
          validateOnChange={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <StyledMuiCard
                title="Shopify Information"
                subTitle="Please enter the basic information of Shopify such as Shop Name , Client Id , Client Secret , Token and Category."
              >
                <CardContent>
                  <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Shop Name
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="text"
                          size={isSmall ? "small" : "large"}
                          {...formik.getFieldProps("shopName")}
                          error={Boolean(
                            formik.touched.shopName && formik.errors.shopName
                          )}
                          helperText={
                            formik.touched.shopName && !!formik.errors.shopName
                              ? formik.errors.shopName
                              : "Enter a shop name of the shopify store"
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Client Id
                        </Typography>
                        <StyledTextField
                          fullWidth
                          size={isSmall ? "small" : "large"}
                          type="text"
                          {...formik.getFieldProps("clientId")}
                          error={
                            !!formik.touched.clientId &&
                            !!formik.errors.clientId
                          }
                          helperText={
                            formik.touched.clientId && formik.errors.clientId
                              ? formik.errors.clientId
                              : "Enter the valid client id."
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={4}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Client Secret
                        </Typography>
                        <StyledTextField
                          fullWidth
                          size={isSmall ? "small" : "large"}
                          type="text"
                          {...formik.getFieldProps("clientSecret")}
                          error={
                            !!formik.touched.clientSecret &&
                            !!formik.errors.clientSecret
                          }
                          helperText={
                            formik.touched.clientSecret &&
                            formik.errors.clientSecret
                              ? formik.errors.clientSecret
                              : "Enter the valid client Secret."
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          token
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="tel"
                          size={isSmall ? "small" : "large"}
                          {...formik.getFieldProps("token")}
                          error={
                            !!formik.touched.token && !!formik.errors.token
                          }
                          helperText={
                            formik.touched.token && formik.errors.token
                              ? formik.errors.token
                              : "Enter the valid token."
                          }
                        />
                      </Box>
                    </Grid>
                    {shopify?.id && (
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Category
                          </Typography>
                          <Autocomplete
                            name="category"
                            options={flattenedCategories}
                            value={formik.values.category}
                            onChange={(_, category) => {
                              formik.setFieldValue("category", category);
                            }}
                            getOptionLabel={(option) => option.label}
                            onBlur={formik.handleBlur}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            renderInput={(params) => (
                              <StyledTextField
                                fullWidth
                                {...params}
                                label="Category"
                                error={Boolean(
                                  formik.touched.category &&
                                    formik.errors.category
                                )}
                                helperText={
                                  formik.touched.category &&
                                  !!formik.errors.category
                                    ? formik.errors.category
                                    : "Please select a category for the products"
                                }
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props} key={option.value}>
                                {option.label}
                              </li>
                            )}
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <Box textAlign="right" mt={2} mr={1}>
                    <Button
                      disableElevation
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={loading}
                      sx={{
                        fontWeight: "bold",
                        minWidth: { md: 100, xl: 250 },
                        height: { xs: 50, xl: 55 },
                      }}
                    >
                      {shopify?.id ? "Update" : "Create"}
                    </Button>
                  </Box>
                </CardContent>
              </StyledMuiCard>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default ShopifyForm;

export function loadShopifyToken() {
  store.dispatch(getShopifyToken());
  return null;
}

const validationSchema = Yup.object().shape({
  token: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "Name is too short")
    .max(200, "Name is too long"),
  shopName: Yup.string()
    .trim()
    .required("Required*")
    .min(1, "Name is too short")
    .max(200, "Name is too long"),
  clientId: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "Name is too short")
    .max(200, "Name is too long"),
  clientSecret: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "Name is too short")
    .max(200, "Name is too long"),
  category: Yup.object().nullable(),
});
