import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import MovingIcon from "@mui/icons-material/Moving";
import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PersonIcon from "@mui/icons-material/Person";
import TourIcon from "@mui/icons-material/Tour";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import WebIcon from "@mui/icons-material/Web";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import InsightCard from "./InsightCard";
import InsightsLineChart from "./InsightsLineChart";

function UsersInsights({ insights }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const usersGraph = useSelector((state) => state.insights.usersGraph);
  const sessionsGraph = useSelector((state) => state.insights.sessionsGraph);
  const viewsGraph = useSelector((state) => state.insights.viewsGraph);

  const getKeywordIcon = useCallback((title) => {
    switch (title) {
      case "newUsers":
        return <PersonIcon />;
      case "activeUsers":
        return <PeopleIcon />;
      case "totalUsers":
        return <PeopleOutlineIcon />;
      case "sessionsPerUser":
        return <TourIcon />;
      case "sessionsStarted":
        return <TroubleshootIcon />;
      case "avgSessionDuration":
        return <HistoryToggleOffIcon />;
      case "screenPageViews":
        return <WebIcon />;
      case "bounceRate":
        return <MovingIcon />;
      default:
        return;
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <Grid container spacing={2}>
            {["newUsers", "activeUsers", "totalUsers", "sessionsPerUser"].map(
              (keyword) => (
                <Grid item xs={6} xl={6} key={keyword}>
                  <InsightCard
                    keyword={keyword}
                    value={insights?.[keyword]}
                    icon={getKeywordIcon(keyword)}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <InsightsLineChart
            title="Users"
            keyword={"totalUsers"}
            data={usersGraph}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <InsightsLineChart
            title="Sessions"
            keyword="sessions"
            data={sessionsGraph}
          />
        </Grid>
        <Grid item sm={12} xl={isOpen ? 12 : 6} xxl={6}>
          <Grid container spacing={2}>
            {[
              "sessionsStarted",
              "avgSessionDuration",
              "screenPageViews",
              "bounceRate",
            ].map((keyword) => (
              <Grid item xs={6} xl={6} key={keyword}>
                <InsightCard
                  keyword={keyword}
                  value={insights?.[keyword]}
                  icon={getKeywordIcon(keyword)}
                  showPercentage={keyword === "bounceRate"}
                  showTime={keyword === "avgSessionDuration"}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <InsightsLineChart
        title="Views"
        keyword="screenPageViews"
        data={viewsGraph}
      />
    </Box>
  );
}

UsersInsights.propTypes = {
  insights: PropTypes.object.isRequired,
};

export default UsersInsights;
