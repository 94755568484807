import PAYMENT_METHODS from "utils/constants/PaymentMethods";

export const returnPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case PAYMENT_METHODS.CASH_ON_DELIVERY:
      return "Cash on Delivery";
    case PAYMENT_METHODS.CARD:
      return "Credit/Debit Card";
    case PAYMENT_METHODS.WALLET:
      return "Wallet";
    case PAYMENT_METHODS.COD_WALLET:
      return "COD + Wallet";
    case PAYMENT_METHODS.WALLET_CARD:
      return "Wallet + Card";
    default:
      return "-";
  }
};
