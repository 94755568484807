import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import ShippingIcon from "assets/images/ShippingIcon.png";
import { memo } from "react";
import { useSelector } from "react-redux";
import { CURRENCY } from "utils/constants/constants";

function Shipment() {
  const addressType = useSelector(
    (state) => state.cart?.cartData?.user?.defaultAddress?.localType ?? "local"
  );

  return (
    <Box variant="container">
      <Typography
        variant="h3"
        color="primary"
        fontSize={{ xs: "0.75rem", sm: "1rem", md: "2rem" }}
        fontWeight="600"
        marginBottom="20px"
      >
        Shipping Method
      </Typography>
      <Card sx={{ display: "flex", padding: "30px", alignItems: "center" }}>
        <Box px="20px">
          <img src={ShippingIcon} alt="Free Shipping Icon" height="auto" />
        </Box>
        <Typography variant="h5">
          {addressType === "local"
            ? `Get Shipping in 4-6 days with charges of ${CURRENCY} 100 per package all over the Pakistan`
            : "Once the shipping charges are confirmed, our agent would share the bank details so that you may transfer the order amount"}
        </Typography>
      </Card>
    </Box>
  );
}

export default memo(Shipment);
