import http from "utils/httpRequest/http";

export const uploadDescriptionImage = (formData) => {
  return http.post("/products/uploads", formData);
};

export const uploadMainImage = (productId = "", image = null) => {
  const formData = new FormData();
  formData.append("mainImage", image);
  return http.post(`/products/${productId}`, formData);
};

export const uploadGalleryImages = (productId = "", images = []) => {
  const formData = new FormData();
  [...images]?.forEach((image) => {
    formData.append(`gallery`, image);
  });
  return http.post(`/products/${productId}`, formData);
};

export const removeGalleryImage = (productId = "", imageUrl = "") => {
  const formData = new FormData();
  formData.append("removeImages[0]", imageUrl);
  return http.post(`/products/${productId}`, formData);
};

export const uploadVideo = (productId = "", video = null) => {
  let formData = new FormData();
  formData.append("productVideo", video);
  return http.patch(`/products/video/${productId}`, formData);
};

export const deleteVideo = (productId = "", videoUrl = null) => {
  return http.patch(`/products/video/${productId}`, { videoUrl });
};
