import { Button, DialogActions, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import { memo } from "react";

function ShopifyDialog({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: { lg: "650px" } },
      }}
    >
      <DialogTitle>
        You had already imported the products from shopify store.
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default memo(ShopifyDialog);

ShopifyDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

ShopifyDialog.defaultProps = {
  create: false,
  edit: false,
  bulk: false,
  shopify: false,
};
