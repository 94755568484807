import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCities, resetState } from "store/slices/filtersSlice";

function FilterByCity() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.filters.loading);
  const provinces = useSelector((state) => state.filters?.cities?.provinces);
  const cities = provinces?.flatMap((province) =>
    province.cities?.map((cityObj) => cityObj.city)
  );

  useEffect(() => {
    dispatch(getAllCities());
    return () => dispatch(resetState());
  }, [dispatch]);

  return (
    <Autocomplete
      sx={{
        minWidth: 170,
        maxWidth: 180,
      }}
      size="small"
      getOptionLabel={(city) => city}
      isOptionEqualToValue={(option, value) => option.city === value.city}
      noOptionsText="No Options Available"
      options={cities}
      onChange={(_, newValue) => {
        navigate(newValue ? `/stores?city=${newValue}` : `/stores`);
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select City"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": { fontSize: "1.063rem", height: 44 },
            "& .MuiInputBase-root": { height: 44 },
          }}
        />
      )}
    />
  );
}

export default memo(FilterByCity);
