import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uploadCategoryImage } from "services/Category";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import flatAePlatformCategoriesLeafNodes from "utils/helpers/flatCategoriesLeafNodes";
import { toast } from "utils/hooks/useToast";

import http from "utils/httpRequest/http";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (data = {}, { rejectWithValue }) => {
    const { id = "", params = {} } = data;
    try {
      const response = await http.get(`/categories${id ? "/" + id : ""}`, {
        params: {
          limit: 1000,
          platform: params?.platform ?? "bazaarGhar",
          ...params,
        },
      });
      const responseData = getResponseData(response);

      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      if (id) {
        return {
          ...responseData,
          results: responseData?.subCategories,
        };
      } else {
        return responseData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAliExpressCategories = createAsyncThunk(
  "categories/getAliExpressCategories",
  async (params = {}, { rejectWithValue }) => {
    try {
      const response = await http.get(`/categories`, {
        params: {
          limit: 1000,
          platform: "aliExpress",
          ...params,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  },
  {
    condition: (_, { getState }) => {
      const aliExpressCategories = getState()?.categories?.aliExpressCategories;
      if (
        aliExpressCategories?.loading ||
        aliExpressCategories?.categories?.length
      ) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  }
);

export const getCategory = createAsyncThunk(
  "categories/getCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/categories/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const dragDropCategories = createAsyncThunk(
  "categories/dragDropCategories",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(`categories/index`, {
        categoriesIndexes: data,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const featuredCategory = createAsyncThunk(
  "categories/featuredCategory",
  async (item, { rejectWithValue }) => {
    const data = {
      featured: item.featured,
    };
    try {
      const response = await http.patch(`categories/${item.id}`, data);
      const responseData = getResponseData(response);

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const submitCategory = createAsyncThunk(
  "categories/submitCategory",
  async (values = {}, { rejectWithValue }) => {
    try {
      const data = {
        name: values?.name,
        description: values?.description,
        commission: values?.commission,
        mainCategory: values?.mainCategoryId,
      };
      const response = await http.post(`/categories`, data);
      const responseData = getResponseData(response);
      if (!responseData?.id) {
        throw new Error("Something went wrong!");
      }

      const formData = new FormData();

      if (typeof values.mainImage === "object") {
        formData.append("mainImage", values.mainImage);
      }
      if (typeof values.bannerImage === "object") {
        formData.append("bannerImage", values.bannerImage);
      }
      if (typeof values.bannerImageAr === "object") {
        formData.append("bannerImage", values.bannerImageAr);
      }
      if (typeof values.wideBannerImage === "object") {
        formData.append("wideBannerImage", values.wideBannerImage);
      }
      if (typeof values.wideBannerImageAr === "object") {
        formData.append("wideBannerImage", values.wideBannerImageAr);
      }

      if (
        typeof values.bannerImageAr === "object" ||
        typeof values.wideBannerImageAr === "object"
      ) {
        formData.append("lang", "ar");
      }

      if (!formData?.keys().next().done) {
        await uploadCategoryImage(responseData?.id, formData);
      }
      toast.success("Category Created Successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (categoryId, { getState, rejectWithValue }) => {
    const { results } = getState().categories;
    try {
      const response = await http.delete(`/categories/${categoryId}`);
      if (response.status === 200) {
        toast.success("Category Deleted Successfully");
        const returnedData = results.filter((data) => data.id !== categoryId);
        return returnedData;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (values = {}, { rejectWithValue }) => {
    try {
      const { id, ...data } = values;
      let _data = {
        ...(data?.index && { index: data.index }),
        ...(data?.name && { name: data.name }),
        ...(data?.description && { description: data.description }),
        ...(data?.commission && { commission: data.commission }),
        ...(data?.lang && { lang: data.lang }),
        ...(data?.platformIds && {
          platformIds: data?.platformIds,
        }),
      };
      const response = await http.patch(`/categories/${id}`, _data);
      const responseData = getResponseData(response);

      const formData = new FormData();

      if (typeof values.mainImage === "object") {
        formData.append("mainImage", values.mainImage);
      }
      if (typeof values.bannerImage === "object") {
        formData.append("bannerImage", values.bannerImage);
      }
      if (typeof values.bannerImageAr === "object") {
        formData.append("bannerImage", values.bannerImageAr);
      }
      if (typeof values.wideBannerImage === "object") {
        formData.append("wideBannerImage", values.wideBannerImage);
      }
      if (typeof values.wideBannerImageAr === "object") {
        formData.append("wideBannerImage", values.wideBannerImageAr);
      }

      if (
        typeof values.bannerImageAr === "object" ||
        typeof values.wideBannerImageAr === "object"
      ) {
        formData.append("lang", "ar");
      }

      if (!formData?.keys().next().done) {
        await uploadCategoryImage(values?.id, formData);
      }
      toast.success("Category Updated successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    loading: true,
    categoryLoading: false,
    category: null,
    results: [],
    queryParams: {},
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: null,
    categoryError: null,
    categoryName: "",
    aliExpressCategories: {
      loading: false,
      categories: [],
      flattenedCategories: [],
      error: "",
    },
  },
  reducers: {
    // Actions
    resetCategoriesState: (state) => {
      state.loading = true;
      state.category = null;
      state.results = [];
      state.queryParams = {};
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = "";
    },
    resetCategory: (state) => {
      state.loading = false;
      state.category = null;
      state.categoryError = null;
    },
    addQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    clearAliExpressCategories: (state) => {
      state.aliExpressCategories.loading = false;
      state.aliExpressCategories.error = "";
      state.aliExpressCategories.categories = [];
      state.aliExpressCategories.flattenedCategories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.loading = false;
        state.categoryName = action.payload.name;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.results = action.payload;
      })
      .addCase(deleteCategory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(submitCategory.pending, (state) => {
        state.categoryLoading = true;
        state.loading = true;
      })
      .addCase(submitCategory.fulfilled, (state, action) => {
        state.categoryLoading = false;
        state.loading = false;
        state.category = action.payload;
      })
      .addCase(submitCategory.rejected, (state, action) => {
        state.categoryLoading = false;
        state.loading = false;
        state.categoryError = action.payload;
      })
      .addCase(updateCategory.pending, (state) => {
        state.categoryLoading = true;
        state.loading = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.categoryLoading = false;
        state.loading = false;
        state.category = action.payload;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.categoryLoading = false;
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCategory.pending, (state) => {
        state.loading = true;
        state.categoryError = false;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.category = action.payload;
        state.loading = false;
      })
      .addCase(getCategory.rejected, (state) => {
        state.loading = false;
        state.categoryError = true;
      })
      .addCase(getAliExpressCategories.pending, (state) => {
        state.aliExpressCategories.loading = true;
        state.aliExpressCategories.error = "";
      })
      .addCase(getAliExpressCategories.fulfilled, (state, action) => {
        state.aliExpressCategories.loading = false;
        state.aliExpressCategories.error = "";
        state.aliExpressCategories.categories = action.payload.results;
        state.aliExpressCategories.flattenedCategories =
          flatAePlatformCategoriesLeafNodes(action.payload.results);
      })
      .addCase(getAliExpressCategories.rejected, (state, action) => {
        state.aliExpressCategories.loading = false;
        state.aliExpressCategories.error = action.payload;
      })
      .addCase(dragDropCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(dragDropCategories.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(dragDropCategories.rejected, (state) => {
        state.loading = false;
      })
      .addCase(featuredCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(featuredCategory.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(featuredCategory.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Action creators generated for each case reducer function
export const {
  resetCategoriesState,
  addQueryParams,
  setCategory,
  resetCategory,
  clearAliExpressCategories,
} = categoriesSlice.actions;

// Exporting default reducer
export default categoriesSlice.reducer;
