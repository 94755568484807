import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsMenu from "components/Header/Notifications/NotificationsMenu";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";
import { refreshUserTokens } from "store/slices/authSlice";
import {
  prependNotification,
  setLoadingState,
  setNotificationsData,
  setUnreadNotificationsCount,
} from "store/slices/notificationsSlice";
import {
  isRefreshTokenExpired,
  isTokenExpired,
} from "utils/helpers/isTokenExpired";

function Notifications() {
  const socket = useRef(null);
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.auth?.data?.user?.id ?? "");
  const unreadNotificationsCount = useSelector(
    (state) => state?.notifications?.unreadNotificationsCount
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getNotifications = useCallback(
    (page = 1) => {
      dispatch(setLoadingState(true));
      socket.current.emit("allNotifications", {
        userId,
        page,
      });
    },
    [dispatch, userId]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenNotifications = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
      getNotifications();
    },
    [getNotifications]
  );

  const socketInitializer = useCallback(async () => {
    if (isRefreshTokenExpired()) {
      return;
    }

    if (isTokenExpired()) {
      await dispatch(refreshUserTokens());
    }

    socket.current = io(
      `${process.env.REACT_APP_SOCKET_SERVER_URL}/notification`,
      {
        transports: ["websocket"],
        auth: {
          token: localStorage.getItem("token"),
        },
      }
    );
    socket.current.on("connect", () => {
      socket.current.emit("unreadNotifications", {
        userId,
      });

      socket.current.on("unreadNotifications", (data) => {
        dispatch(setUnreadNotificationsCount(data.count));
      });

      socket.current.on("allNotifications", (data) => {
        dispatch(setNotificationsData(data));
        socket.current.emit("unreadNotifications", {
          userId,
        });
      });

      socket.current.on("chineseBell", (response) => {
        dispatch(prependNotification(response));
      });
    });
  }, [dispatch, userId]);

  useEffect(() => {
    !socket.current && socketInitializer();
    return () => {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = null;
      }
    };
  }, [socketInitializer]);

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton
          size="large"
          onClick={handleOpenNotifications}
          aria-haspopup="true"
          aria-controls={open ? "notification-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          sx={{
            py: 0,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Badge badgeContent={unreadNotificationsCount} max={99} color="error">
            <NotificationsIcon
              sx={{
                color: "primary.main",
                width: "2.5rem",
                height: "2.5rem",
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        getNotifications={getNotifications}
      />
    </>
  );
}

export default memo(Notifications);
