import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo } from "react";
import DescriptionEditor from "./DescriptionEditor";

function DescriptionSection({ formik }) {
  // const [showEditor, setShowEditor] = useState(false);

  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     setShowEditor(true);
  //   }, 1500);
  //   return () => {
  //     setShowEditor(false);
  //     clearTimeout(id);
  //   };
  // }, []);

  return (
    <StyledMuiCard
      title="Product Details"
      subTitle="Enter product description and specifications. Tip: Product with more details sale more"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          p={{ xs: 1, md: 1.5 }}
        >
          <Box py={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Description
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              pt={1}
              fontSize="1rem"
            >
              Enter product details with specifications. Tip: Use bullet points
              for easy viewing
            </Typography>
          </Box>
          <Box>
            <Card>
              <DescriptionEditor formik={formik} />
            </Card>
            {formik.errors.description && (
              <Typography color="error.light" pt={1} fontSize="1rem">
                {formik.errors.description}
              </Typography>
            )}
          </Box>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

DescriptionSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(DescriptionSection, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.description ===
      nextProps.formik.values.description &&
    prevProps.formik.errors.description ===
      nextProps.formik.errors.description &&
    prevProps.formik.touched.description ===
      nextProps.formik.touched.description &&
    prevProps.formik.values.id === nextProps.formik.values.id
  );
}
