import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FullOrdersDataGrid from "components/FullOrders/FullOrdersDataGrid";
import OrderHeaderComponents from "components/FullOrders/OrderHeaderComponents";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import OrderDetailsPage from "pages/FullOrders/OrderDetailsPage";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetState } from "store/slices/fullOrdersSlice";

function FullOrders({ orderDetails }) {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  if (orderDetails) {
    return <OrderDetailsPage />;
  }

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Full Orders
      </Typography>
      <OrderHeaderComponents />
      <FullOrdersDataGrid />
    </Box>
  );
}

export default memo(FullOrders);

FullOrders.propTypes = {
  orderDetails: PropTypes.bool,
};

FullOrders.defaultProps = {
  orderDetails: false,
};
