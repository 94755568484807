import AdminDashboard from "components/Dashboard/AdminDashboard";
import SupplierDashboard from "components/Dashboard/SupplierDashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics, getRevenue } from "store/slices/dashboardSlice";
import USER_ROLE from "utils/constants/userRole";

export default function Dashboard() {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth?.userRole);

  useEffect(() => {
    dispatch(getAnalytics());
  }, [dispatch]);

  useEffect(() => {
    userRole === USER_ROLE.ADMIN && dispatch(getRevenue());
  }, [dispatch, userRole]);

  if (userRole === USER_ROLE.ADMIN) {
    return <AdminDashboard />;
  }

  return <SupplierDashboard />;
}
