import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { calculateAdminDiscount } from "utils/helpers/createOrderFormHelpers";

function OrderDetail() {
  const order = useSelector((state) => state.cart.cartData ?? {});
  const addressType = useSelector(
    (state) => state.cart?.cartData?.user?.defaultAddress?.localType ?? "local"
  );
  const packageItems = useSelector((state) => state.cart.packageItems || []);
  const discountedProducts = useSelector(
    (state) => state.cart.adminDiscountProducts
  );
  const active = useSelector((state) => state.cart?.wallet ?? false);

  const [adminDiscount, setAdminDiscount] = useState(0);

  useEffect(() => {
    packageItems && discountedProducts
      ? setAdminDiscount(calculateAdminDiscount())
      : setAdminDiscount(0);
  }, [discountedProducts, packageItems]);

  return (
    <Box padding={2}>
      <Box sx={boxStyles}>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
        >
          Product Total
        </Typography>
        <CurrencyFormatter
          style={{
            textAlign: "left",
            color: "Primary",
          }}
          number={order?.retailTotal ?? 0}
          fontSize="1.25rem"
        />
      </Box>
      <Box sx={boxStyles}>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
        >
          Discount:
        </Typography>
        <CurrencyFormatter
          textAlign="left"
          color="primary.main"
          number={order?.discount ?? 0}
          fontSize="1.25rem"
        />
      </Box>
      <Box sx={boxStyles}>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
        >
          Admin Discount:
        </Typography>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
          fontSize="1.25rem"
        >
          {adminDiscount ? `( ${adminDiscount} )` : "N/A"}
        </Typography>
      </Box>
      <Box sx={boxStyles}>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
        >
          SubTotal:
        </Typography>
        <CurrencyFormatter
          style={{
            textAlign: "left",
            color: "Primary",
          }}
          number={order?.subTotal ?? 0}
          fontSize="1.25rem"
        />
      </Box>
      <Box sx={boxStyles}>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
        >
          Shipping Charges:
        </Typography>
        {order.shippmentCharges === 0 && addressType === "local" ? (
          "Free shipping"
        ) : addressType === "international" ? (
          "Not yet calculated"
        ) : (
          <CurrencyFormatter
            style={{
              textAlign: "left",
              color: "Primary",
            }}
            number={order?.shippmentCharges ?? 0}
            fontSize="1.25rem"
          />
        )}
      </Box>
      {active && (
        <Box sx={boxStyles}>
          <Typography
            style={{
              textAlign: "left",
              color: "Primary",
            }}
          >
            Wallet:
          </Typography>
          {order?.paymentMethodTotal
            ? `( ${order.paymentMethodTotal} )`
            : "N/A"}
        </Box>
      )}
      <Box sx={boxStyles}>
        <Typography
          style={{
            textAlign: "left",
            color: "Primary",
          }}
        >
          Total:
        </Typography>
        <CurrencyFormatter
          style={{
            textAlign: "left",
            color: "Primary",
          }}
          number={order?.total ?? 0}
          fontSize="1.25rem"
        />
      </Box>

      <Divider sx={{ borderBottomWidth: "2px", bgcolor: "#000", my: 3 }} />
      <Box display="flex" justifyContent="space-between" py={1}>
        <Typography fontSize="2rem" fontWeight="bold">
          Payable:
        </Typography>
        <CurrencyFormatter
          number={order?.payable ?? 0}
          fontSize="2rem"
          textAlign="end"
          fontWeight="bold"
        />
      </Box>
    </Box>
  );
}

export default memo(OrderDetail);
const boxStyles = {
  "& p": {
    fontSize: {
      sm: "1.25rem",
      xs: "1.5rem",
    },
    justifyContent: "left",
  },
  "& h6": {
    fontSize: {
      sm: "1.25rem",
      xs: "1.5rem",
    },
    color: "secondary.main",
    textAlign: "left",
    fontWeight: "bold",
  },
  "& a": {
    fontSize: {
      sm: "1.25rem",
      xs: "1.5rem",
    },
    textDecoration: "none",
    "&:hover": { color: "secondary.main" },
    width: "fit-content",
  },
  textAlign: "justify",
  display: "flex",
  justifyContent: "space-between",
};
