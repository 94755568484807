import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import InsightTabPanel from "components/Insights/InsightTabPanel";
import OrdersInsights from "components/Insights/OrdersInsights";
import RevenueInsights from "components/Insights/RevenueInsights";
import UsersInsights from "components/Insights/UsersInsights";
import DateRange from "components/Pickers/DateRange";
import dayjs from "dayjs";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getInsights, resetInsightsState } from "store/slices/insightsSlice";
import useQueryParams from "utils/hooks/useQueryParams";

const userInsightsKeyValues = {
  newUsers: "newUsers",
  activeUsers: "activeUsers",
  totalUsers: "totalUsers",
  sessionsPerUser: "sessionsPerUser",
  sessionsStarted: "sessions",
  avgSessionDuration: "averageSessionDuration",
  bounceRate: "bounceRate",
  screenPageViews: "screenPageViews",
  purchases: "ecommercePurchases",
  totalPurchasers: "totalPurchasers",
};

const orderInsightsKeyValues = {
  addToWishlist: "keyEvents:add_to_wishlist",
  addToCarts: "addToCarts",
  cartToViewRate: "cartToViewRate",
  checkouts: "checkouts",
};

const revenueInsightsKeyValues = {
  avgPurchaseRevenue: "averagePurchaseRevenue",
  totalAdRevenue: "totalAdRevenue",
  totalRevenue: "totalRevenue",
  taxAmount: "taxAmount",
};

function Insights() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const { loading, insights } = useSelector((state) => state.insights);
  const [tabValue, setTabValue] = useState(0);

  const tabProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  const handleDateChange = useCallback(
    (range) => {
      navigate({
        search: createSearchParams({
          ...params,
          from: dayjs(range.startDate).format("YYYY-MM-DD"),
          to: dayjs(range.endDate).format("YYYY-MM-DD"),
        }).toString(),
      });
    },
    [navigate, params]
  );

  const filterInsightsByTabValue = useMemo(() => {
    if (insights) {
      const data = {};
      switch (tabValue) {
        case 0:
          Object.entries(userInsightsKeyValues).forEach(([key, keyword]) => {
            data[key] = insights[keyword] ?? 0;
          });
          break;
        case 1:
          Object.entries(orderInsightsKeyValues).forEach(([key, keyword]) => {
            data[key] = insights[keyword] ?? 0;
          });
          break;
        case 2:
          Object.entries(revenueInsightsKeyValues).forEach(([key, keyword]) => {
            data[key] = insights[keyword] ?? 0;
          });
          break;
        default:
          return {};
      }
      return data;
    } else return {};
  }, [insights, tabValue]);

  useEffect(() => {
    const data = {
      keywords: [
        ...Object.values(userInsightsKeyValues),
        ...Object.values(orderInsightsKeyValues),
        ...Object.values(revenueInsightsKeyValues),
      ].map((keyword) => ({ name: keyword })),

      ...(params?.from && { from: params.from }),
      ...(params?.to && { to: params.to }),
    };

    dispatch(getInsights(data));
  }, [dispatch, params.from, params.to]);

  useEffect(() => () => () => dispatch(resetInsightsState()), [dispatch]);

  return (
    <Box py={4} display="flex" flexDirection="column" gap={2}>
      <Box>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.5rem" }}
        fontWeight={600}
      >
        Insights
      </Typography>
      {loading ? (
        <Box
          pt="10vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : Object.keys(insights).length ? (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Tabs
                indicatorColor="none"
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    backgroundColor: "#068ECF",
                    color: "#fff",
                    borderRadius: 1,
                    fontSize: "1.2rem",
                  },
                  "& .MuiTab-root": {
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  },
                }}
                value={tabValue}
                onChange={handleTabChange}
              >
                <Tab label="Users" {...tabProps(0)} />
                <Tab label="Orders" {...tabProps(1)} />
                <Tab label="Revenues" {...tabProps(2)} />
              </Tabs>
            </Box>
            <Box>
              <DateRange onChange={handleDateChange} />
            </Box>
          </Box>
          <InsightTabPanel value={tabValue} index={0}>
            <UsersInsights insights={filterInsightsByTabValue} />
          </InsightTabPanel>
          <InsightTabPanel value={tabValue} index={1}>
            <OrdersInsights insights={filterInsightsByTabValue} />
          </InsightTabPanel>
          <InsightTabPanel value={tabValue} index={2}>
            <RevenueInsights insights={filterInsightsByTabValue} />
          </InsightTabPanel>
        </>
      ) : (
        <Typography
          variant="h6"
          component="h2"
          color="text.disabled"
          fontWeight={600}
          textAlign="center"
          pt={5}
        >
          No data found
        </Typography>
      )}
    </Box>
  );
}

export default Insights;
