import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { memo } from "react";

function PageTitle({ title, loading, ...props }) {
  return (
    <Box display="flex" alignItems="baseline" gap={1}>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "1.75rem", md: "2.25rem" }}
        fontWeight="500"
        {...props}
      >
        {title ?? "Page Title"}
      </Typography>
      {loading && (
        <Typography
          variant="body2"
          color="primary.main"
          sx={{
            animation: "flicker 2s infinite",
            "@keyframes flicker": {
              "0%": { opacity: 1 },
              "50%": { opacity: 0.5 },
              "100%": { opacity: 1 },
            },
          }}
        >
          {`( Updating )`}
        </Typography>
      )}
    </Box>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

PageTitle.defaultProps = {
  title: "Page Title",
  loading: false,
};

export default memo(PageTitle);
