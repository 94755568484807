import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setVariantOption } from "store/slices/variantsSlice";
import { capitalizeFirstLetter } from "utils/helpers/capitalizeFirstLetter";
import generateAttributeCombinations from "utils/helpers/variantAttributes";
import EditVariantSection from "./EditVariantSection";
import VariantForm from "./VariantForm";

function ProductVariants({ formik }) {
  const dispatch = useDispatch();
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [attributes, setAttributes] = useState([]);

  const variantOption = useSelector((state) => state?.variants?.variantOption);
  const product = useSelector((state) => state.products.product);
  const variants = useSelector((state) => state?.products?.product?.variants);

  const filteredAttributes = attributes?.filter(
    (attribute) =>
      !variants.some((variant) => {
        const variantAttribute = variant.selectedAttributes.reduce(
          (obj, attribute) => {
            obj[attribute.name.toLowerCase()] = attribute.value;
            return obj;
          },
          {}
        );
        return JSON.stringify(attribute) === JSON.stringify(variantAttribute);
      })
  );

  useEffect(() => {
    if (product && product.attributes) {
      setAttributes(generateAttributeCombinations(product?.attributes));
    }
  }, [product]);

  const handleChange = (_, option) => {
    dispatch(setVariantOption(option));
    if (option) {
      const array = Object.entries(option)?.map(([name, value]) => ({
        name: name.charAt(0).toUpperCase() + name.slice(1),
        value,
      }));
      setSelectedAttributes(array);
    }
  };

  return (
    <StyledMuiCard
      title="Add Variants"
      subTitle="Add variant like color, size, etc"
    >
      <CardContent>
        <Stack direction="column" gap={3} p={{ xs: 1, md: 1.5 }}>
          <Autocomplete
            name="productOption"
            options={filteredAttributes}
            value={variantOption}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            sx={{ maxWidth: 460 }}
            getOptionLabel={(option) => {
              return Object.entries(option)
                .map(
                  ([key, value]) =>
                    `${capitalizeFirstLetter(key)}: ${capitalizeFirstLetter(
                      value
                    )}`
                )
                .join(" ");
            }}
            renderInput={(params) => (
              <StyledTextField
                fullWidth
                {...params}
                label="Variant"
                helperText={"Please select an option to add variant"}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={JSON.stringify(option)}>
                <Box display="flex" gap={1}>
                  {Object.entries(option)?.map(([key, value]) => (
                    <Typography key={key} sx={{ textTransform: "capitalize" }}>
                      {`${key}: ${value} `}
                    </Typography>
                  ))}
                </Box>
              </li>
            )}
          />
          {variantOption && (
            <VariantForm
              formik={formik}
              selectedAttributes={selectedAttributes}
            />
          )}
          <EditVariantSection formik={formik} />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductVariants.propTypes = {
  formik: PropTypes.object.isRequired,
};

ProductVariants.defaultProps = {
  formik: {},
};
export default memo(ProductVariants);
