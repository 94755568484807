import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PaymentsIcon from "@mui/icons-material/Payments";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import InsightCard from "./InsightCard";
import InsightsBarChart from "./InsightsBarChart";
import InsightsDonutChart from "./InsightsDonutChart";

function RevenueInsights({ insights }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const revenue = useSelector((state) => state.insights.revenue);

  const getKeywordIcon = useCallback((title) => {
    switch (title) {
      case "avgPurchaseRevenue":
        return <ShoppingCartIcon />;
      case "totalAdRevenue":
        return <LocalAtmIcon />;
      case "totalRevenue":
        return <PaymentsIcon />;
      case "taxAmount":
        return <PointOfSaleIcon />;
      default:
        return;
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={2}>
        {[
          "avgPurchaseRevenue",
          "totalAdRevenue",
          "totalRevenue",
          "taxAmount",
        ].map((keyword) => (
          <Grid item xs={6} xl={isOpen ? 6 : 3} xxl={3} key={keyword}>
            <InsightCard
              keyword={keyword}
              value={insights?.[keyword]}
              icon={getKeywordIcon(keyword)}
              isSingleRowed={true}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <InsightsBarChart
            title="Revenue By Markets"
            data={{
              saudia: revenue.saudia[0] ?? {},
              pakistan: revenue.pakistan[0] ?? {},
              china: revenue.china[0] ?? {},
            }}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <InsightsDonutChart
            title="Top Categories"
            data={revenue?.categoriesRevenue}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

RevenueInsights.propTypes = {
  insights: PropTypes.object.isRequired,
};

export default RevenueInsights;
