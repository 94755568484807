import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";

const regions = {
  KSA: "ksa",
  PAK: "pak",
  CHINA: "china",
  ALL: "all",
};

function ProductLocation({ formik }) {
  // Destructure formik
  const regionsArray = Object.keys(regions)?.map((key) => ({
    label: key,
    value: regions[key],
  }));

  // Safeguard against undefined values
  // const regionValue = formik.values?.region || [];
  // const originValue = formik.values?.origin || "";

  return (
    <StyledMuiCard
      title="Product Location"
      subTitle="Please enter the location of the product such as region and origin"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Origin
            </Typography>
            <Autocomplete
              name="origin"
              options={regionsArray}
              value={
                regionsArray?.find(
                  (option) => option?.value === formik.values?.origin
                ) || null
              }
              onChange={(_, region) => {
                formik.setFieldValue("origin", region?.value || "");
              }}
              getOptionLabel={(option) => option?.label || ""}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  placeholder="Select origin"
                  {...params}
                  error={Boolean(
                    formik.touched?.origin && formik.errors?.origin
                  )}
                  helperText={
                    formik.touched.origin && !!formik.errors.origin
                      ? formik.errors.origin
                      : "Please select an origin"
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?.value}>
                  {option?.label}
                </li>
              )}
            />
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Region
            </Typography>
            <Autocomplete
              multiple
              name="region"
              options={regionsArray}
              value={formik.values?.region.map((val) =>
                regionsArray?.find((region) => region?.value === val)
              )}
              onChange={(_, selectedRegions) => {
                const regionValues = selectedRegions?.map(
                  (region) => region?.value
                );
                formik.setFieldValue("region", regionValues);
              }}
              getOptionLabel={(option) => option?.label}
              onBlur={formik.handleBlur}
              isOptionEqualToValue={(option, value) =>
                option?.value === value?.value
              }
              renderInput={(params) => (
                <StyledTextField
                  fullWidth
                  placeholder="Select multiple regions"
                  {...params}
                  error={Boolean(formik.touched.region && formik.errors.region)}
                  helperText={
                    formik.touched.region && !!formik.errors.region
                      ? formik.errors.region
                      : "Please select a region"
                  }
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.value}>
                  <Checkbox checked={selected} sx={{ marginRight: 1 }} />
                  {option?.label}
                </li>
              )}
            />
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

ProductLocation.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(ProductLocation);
