import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PlusIcon from "assets/icons/PlusIcon.png";
import WritingIcon from "assets/icons/WritingIcon.svg";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAdminAddress, setDefaultAddress } from "store/slices/checkoutSlice";
import { CARD_HEADER_BACKGROUND } from "styles/colors";
import AddAddress from "./AddAddress";
function AddressSection({ phoneNumber }) {
  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.checkout.addresses ?? []);
  const cartId = useSelector((state) => state.cart?.cartData?.id ?? "");
  const userId = useSelector((state) => state.cart?.userId ?? "");
  const defaultAddress = useSelector(
    (state) => state.cart?.cartData?.user?.defaultAddress ?? ""
  );
  const [addressOption, setAddressOption] = useState("");
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (phoneNumber) {
      const id = setTimeout(() => {
        dispatch(getAdminAddress(phoneNumber));
      }, 500);
      return () => clearTimeout(id);
    }
  }, [dispatch, phoneNumber]);

  useEffect(() => {
    if (defaultAddress.id) {
      setAddressOption(
        JSON.stringify(
          addresses.find((address) => address.id === defaultAddress.id)
        )
      );
    }
  }, [addresses, defaultAddress]);

  const handleClose = () => {
    setOpen(false);
    if (edit) {
      setEdit(false);
    }
  };
  const handleEdit = () => {
    setOpen(true);
    setEdit(true);
  };
  const handleOpen = () => {
    edit && setEdit(false);
    setOpen(true);
  };
  const handleSelect = useCallback(
    (e) => {
      setAddressOption(e.target.value);
      const address = JSON.parse(e.target.value);
      const addressData = {
        addressId: address?.id,
        cartId,
        userId,
      };
      dispatch(setDefaultAddress(addressData));
    },
    [cartId, dispatch, userId]
  );

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 1.5,
        height: "350px",
      }}
    >
      <CardHeader
        action={
          <div>
            <IconButton aria-label="add" onClick={() => handleOpen()}>
              <img src={PlusIcon} alt="add" />
            </IconButton>
            <IconButton aria-label="edit" onClick={() => handleEdit()}>
              <img src={WritingIcon} alt="edit" />
            </IconButton>
          </div>
        }
        title="Address"
        subheader="Select the address of the office or warehouse"
        sx={{
          bgcolor: CARD_HEADER_BACKGROUND,
          "& .MuiCardHeader-subheader": {
            fontSize: "0.9rem",
          },
          ".MuiCardHeader-action": {
            margin: "auto",
          },
        }}
      />

      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Address
            </Typography>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Select
                  fullWidth
                  sx={{ textTransform: "capitalize" }}
                  value={addressOption}
                  onChange={handleSelect}
                  disabled={addresses.length < 1}
                >
                  {addresses.map((address) => (
                    <MenuItem
                      key={address.id}
                      value={JSON.stringify(address)}
                      sx={{
                        textAlign: "justify",
                        textTransform: "capitalize",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <Box>
                          <Typography ml={2}>{address.address}</Typography>
                          <Typography
                            ml={2}
                            sx={{
                              fontSize: {
                                md: "14px",
                                xs: "10px",
                              },
                            }}
                          >
                            {address?.phone} , {address?.city}
                          </Typography>
                        </Box>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  fontSize="1rem"
                  py="5px"
                >
                  {addresses.length < 1
                    ? "There are no address against this user. Add new address"
                    : "Please select the address from the list"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
      <Modal keepMounted={false} open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <AddAddress
            handleClose={handleClose}
            isEditing={edit}
            phoneNumber={phoneNumber}
          />
        </Box>
      </Modal>
    </Card>
  );
}

AddressSection.propTypes = {
  formik: PropTypes.object,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(AddressSection);

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "100%", md: 800 },
  height: { xs: "100%", md: "fit-content" },
  background: "#FFFFFF",
  borderRadius: "5px",
  overflowX: "auto",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
