import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RevenueIcon from "assets/icons/RevenueIcon.svg";
import SalesIcon from "assets/icons/SalesIcon.svg";
import SkusIcon from "assets/icons/SkusIcon.svg";
import UsersIcon from "assets/icons/UsersIcon.svg";
import VideosIcon from "assets/icons/VideosIconKeyNumbers.svg";
import ViewIcon from "assets/icons/ViewIcon.svg";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  KEY_NUMBERS_BACKGROUND_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
} from "styles/colors";

const KeyNumberTile = ({ count, title }) => {
  const imageIcon = useCallback((title) => {
    switch (title) {
      case "Users":
        return UsersIcon;
      case "Revenue Generated":
        return RevenueIcon;
      case "Sales":
        return SalesIcon;
      case "SKUs":
        return SkusIcon;
      case "Videos":
        return VideosIcon;
      case "Views":
        return ViewIcon;
      default:
        return;
    }
  }, []);

  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  return (
    <Box textAlign="center">
      <Box
        height="50px"
        width="50px"
        backgroundColor={KEY_NUMBERS_BACKGROUND_COLOR}
        borderRadius="50%"
        margin="auto"
        sx={{
          backgroundImage: `url(${imageIcon(title)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contained",
          backgroundPosition: "center",
        }}
      />
      <NumberFormatter
        sx={{
          color: STATS_NUMBER_FORMATTER_COLOR,
          fontSize: "1.813rem",
          fontWeight: "bold",
        }}
        number={count}
      />
      <Typography
        fontSize={{ xl: isOpen ? "0.851rem" : "0.875rem" }}
        color="#6e6b7b"
      >
        {title}
      </Typography>
    </Box>
  );
};
export default memo(KeyNumberTile);

KeyNumberTile.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

KeyNumberTile.defaultProps = {
  title: "",
  count: "0",
};
