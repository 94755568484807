import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DataGridSearch from "components/DataGridSearch";
import Actions from "components/Sellers/Actions";
import DataGrid from "components/Sellers/DataGrid";
import SellerForm from "components/Sellers/SellerForm";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import PropTypes from "prop-types";
import { memo } from "react";

const Sellers = ({ update }) => {
  if (update) {
    return <SellerForm />;
  }

  return (
    <Box py={2}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={600}
        paddingTop={1}
      >
        Sellers
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Actions />
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="fullname"
          searchQueryParam="value"
        />
      </Box>
      <DataGrid />
    </Box>
  );
};

Sellers.propTypes = {
  update: PropTypes.bool,
};

Sellers.defaultProps = {
  update: false,
};

export default memo(Sellers);
