import styled from "@emotion/styled";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import ResetEmptyState from "assets/images/resetEmptyState.svg";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetProfilePassword } from "services/authServices";
import { PRIMARY_LIGHT } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function ResetPasswordForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { token } = useQueryParams();
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ password }) => {
      resetProfilePassword(password, token)
        .then(() => {
          toast.success("Password updated successfully");
          navigate("/login");
        })
        .catch(() => {
          setShowEmptyState(true);
        });
    },
  });

  return (
    <Container>
      {showEmptyState ? (
        <>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            minHeight="100vh"
            maxWidth={{ sm: "260px", md: "300px", lg: "410px", xl: "540px" }}
            m="auto"
            spacing={{ xs: 2, md: 2.4, lg: 4 }}
          >
            <img src={ResetEmptyState} alt="empty-state" width={200} />
            <Typography
              variant="h3"
              component="h2"
              fontWeight="bold"
              fontSize={{ xs: "1.75rem", sm: "1rem", md: "2rem", lg: "2.8rem" }}
              lineHeight={1}
              textAlign="center"
            >
              Password Reset Link Expired
            </Typography>
            <Typography
              component="p"
              color="text.secondary"
              fontSize={{
                xs: "0.25rem",
                sm: "0.5rem",
                md: "1rem",
                lg: "1.25rem",
              }}
              lineHeight={1.5}
              textAlign="center"
            >
              Your password reset link has expired. Please request a new link to
              continue with resetting your password.
            </Typography>
            <Button
              variant="contained"
              type="submit"
              onClick={() => navigate("/recover-password")}
              size={isSmall ? "small" : "large"}
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "0.75rem", md: "1.2rem", xl: "1.25rem" },
              }}
            >
              Get Link
            </Button>
          </Stack>
        </>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Stack
            direction="column"
            alignContent="center"
            justifyContent="center"
            minHeight="100vh"
            maxWidth={{ sm: "260px", md: "300px", lg: "410px", xl: "540px" }}
            m="auto"
            spacing={{ xs: 2, md: 2.4, lg: 4 }}
          >
            <Typography
              variant="h3"
              component="h2"
              fontWeight="bold"
              fontSize={{ xs: "1.75rem", sm: "1rem", md: "2rem", lg: "3rem" }}
              lineHeight={1}
            >
              Reset Password
            </Typography>
            <Typography
              component="p"
              color="text.secondary"
              fontSize={{
                xs: "0.25rem",
                sm: "0.5rem",
                md: "1rem",
                lg: "1.25rem",
              }}
              lineHeight={1.5}
            >
              Please enter your new password to continue
            </Typography>
            <StyledTextField
              fullWidth
              name="password"
              label="New Password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              size={isSmall ? "small" : "large"}
              onChange={formik.handleChange}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              inputProps={{
                autoComplete: "off",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              size={isSmall ? "small" : "large"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                !!formik.errors.confirmPassword
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              type="submit"
              size={isSmall ? "small" : "large"}
              sx={{
                fontWeight: "bold",
                fontSize: { sm: "0.75rem", md: "1.2rem", xl: "1.25rem" },
              }}
            >
              Reset
            </Button>
          </Stack>
        </form>
      )}
    </Container>
  );
}
export default ResetPasswordForm;
const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: PRIMARY_LIGHT,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required*")
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      "Password must contain at least one uppercase and one lowercase letter and one number and a special character"
    )
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .required("Required*")
    .trim()
    .oneOf([Yup.ref("password"), null], "Passwords do not match"),
});
