import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import CardTitle from "components/CardTitle";
import StyledCard from "components/Dashboard/StyledCardContent";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { STATS_NUMBER_FORMATTER_COLOR } from "styles/colors";
function CardVideoStats({ title, totalVideos, currentMonth, lastMonth }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const difference = useMemo(() => {
    if (!isNaN(currentMonth) && !isNaN(lastMonth)) {
      return Math.abs(currentMonth - lastMonth);
    } else {
      return 0;
    }
  }, [currentMonth, lastMonth]);

  return (
    <Card sx={{ height: "100%" }}>
      <StyledCard>
        <Box minHeight="42px">
          <CardTitle title={title} />
        </Box>
        <NumberFormatter
          sx={{
            color: STATS_NUMBER_FORMATTER_COLOR,
            fontSize: "2.25rem",
            fontWeight: "bold",
            height: "42px",
          }}
          number={totalVideos}
        />
        <Typography fontSize="0.875rem" color="#707070">
          Total {title}
        </Typography>
        <NumberFormatter
          sx={{
            color: STATS_NUMBER_FORMATTER_COLOR,
            fontSize: "2.25rem",
            fontWeight: "bold",
            height: "42px",
          }}
          number={currentMonth}
        />
        <Typography fontSize="0.875rem" color="#707070">
          This Month
        </Typography>
        <Stack
          direction="row"
          display={{
            lg: "content",
            xl: isOpen ? "contents" : "flex",
            md: isOpen ? "contents" : "flex",
          }}
        >
          <Box display="flex" gap={0.7} alignItems="center">
            <img
              src={currentMonth > lastMonth ? ArrowUp : ArrowDown}
              alt="arrow"
            />
            <NumberFormatter
              fontSize="0.875rem"
              number={difference}
              color={currentMonth > lastMonth ? "#0ea552" : "#ea5455"}
            />
          </Box>
          <Typography
            fontSize="14px"
            color="#707070"
            lineHeight={2.06}
            marginLeft="4px"
          >
            vs last month
          </Typography>
        </Stack>
      </StyledCard>
    </Card>
  );
}

export default memo(CardVideoStats);
CardVideoStats.propTypes = {
  title: PropTypes.string.isRequired,
  totalVideos: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
