import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import CardTitle from "components/CardTitle";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopSearchedKeywords } from "store/slices/dashboardSlice";
import useQueryParams from "utils/hooks/useQueryParams";

function TopSearchedKeywords() {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const { loading, keywords } = useSelector(
    (state) => state.dashboard.topSearchedKeywords
  );

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("clicks");
  const [cardHeight, setCardHeight] = useState("auto");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = keywords.length
    ? [...keywords]?.sort((a, b) => {
        if (orderBy === "clicks") {
          return order === "asc" ? a.clicks - b.clicks : b.clicks - a.clicks;
        } else if (orderBy === "impressions") {
          return order === "asc"
            ? a.impressions - b.impressions
            : b.impressions - a.impressions;
        }
        return 0;
      })
    : [];

  useEffect(() => {
    dispatch(
      getTopSearchedKeywords({
        from: params?.from
          ? dayjs(params?.from).format("YYYY-MM-DD")
          : dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        to: params?.to
          ? dayjs(params?.to).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, params?.from, params?.to]);

  useEffect(() => {
    const OrdersLineChart = document.getElementById("dashboard-line-chart");

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === OrdersLineChart) {
          setCardHeight(entry.contentRect.height);
        }
      }
    });

    if (OrdersLineChart) {
      resizeObserver.observe(OrdersLineChart);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Card
      sx={{
        p: 2,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        overflow="hidden"
        position="relative"
        height={cardHeight}
      >
        <Stack
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <CardTitle title="Top 20 Keywords" />
        </Stack>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : keywords?.length ? (
          <TableContainer
            sx={{
              height: "auto",
              overflowY: "auto",
              scrollBehavior: "smooth",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "none",
              },
              "&::-webkit-scrollbar-thumb": {
                minHeight: 25,
                backgroundColor: "primary.light",
                borderRadius: 1,
              },
            }}
          >
            <Table size="small" stickyHeader>
              <TableHead
                sx={{
                  "& .MuiTableCell-head": {
                    minWidth: "200x",
                    width: "fit-content",
                    fontWeight: 700,
                    color: "text.primary",
                  },
                  "&.MuiTableCell-body": {
                    p: 0,
                  },
                }}
              >
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ width: "100% !important" }}>
                    Keyword
                  </TableCell>
                  <TableCell
                    align="right"
                    sortDirection={orderBy === "clicks" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "clicks"}
                      direction={orderBy === "clicks" ? order : "asc"}
                      onClick={() => handleRequestSort("clicks")}
                    >
                      Clicks
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="right"
                    sortDirection={orderBy === "impressions" ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === "impressions"}
                      direction={orderBy === "impressions" ? order : "asc"}
                      onClick={() => handleRequestSort("impressions")}
                    >
                      Impressions
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.length
                  ? sortedData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <TableCell>{row.keys[0]}</TableCell>
                        <TableCell align="right">
                          {parseInt(row.clicks).toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {parseInt(row.impressions).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        ) : !loading && !keywords.length ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              variant="body2"
              textAlign="center"
              color="text.disabled"
            >
              No data found
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Card>
  );
}

export default TopSearchedKeywords;
