import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import { PRIMARY } from "styles/colors";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function StoreMediaSection({ formik }) {
  const dispatch = useDispatch();
  const storeImageInputRef = useRef(null);
  const logoImageInputRef = useRef(null);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [storeImage, setStoreImage] = useState(formik.values?.storeImage ?? []);
  const [logo, setLogo] = useState(formik.values?.logo ?? []);

  const storeImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 1200, 400, false, true, 3 / 1, "3 / 1")
          .then((img) => {
            storeImageInputRef.current.value = "";
            formik.setFieldValue("storeImage", img);
            setStoreImage(URL.createObjectURL(img));
          })
          .catch((error) => {
            storeImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [formik, dispatch]
  );

  const logoImageHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 500, 500, false, true, 1 / 1, "1 / 1")
          .then((img) => {
            logoImageInputRef.current.value = "";
            formik.setFieldValue("logo", img);
            setLogo(URL.createObjectURL(img));
          })
          .catch((error) => {
            logoImageInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [formik, dispatch]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload image relevant to the collection"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Category Main Image */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Store Logo Image
            </Typography>
            <Box>
              {formik.values?.logo ? (
                <img
                  src={formik.values?.logo ? logo : ""}
                  alt="Store Logo Media"
                  height="auto"
                  width={isSmall ? "150px" : isLarge ? "400px" : "500px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => logoImageInputRef.current.click()}
                />
              ) : (
                <Box
                  width="600px"
                  style={{
                    cursor: "pointer",
                    border: " 1px #068ECF dashed",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="200px"
                  alt="banner"
                  onClick={() => logoImageInputRef.current.click()}
                >
                  <AddIcon sx={{ color: PRIMARY }} />
                </Box>
              )}
              <input
                type="file"
                ref={logoImageInputRef}
                style={{ display: "none" }}
                id="update-store-image"
                hidden
                onChange={logoImageHandler}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
              />
              {Boolean(formik?.errors?.logo) && (
                <Typography color="error.light">
                  {formik.errors.logo}*
                </Typography>
              )}
            </Box>
          </Stack>
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Store Banner Image
            </Typography>
            <Box>
              {formik.values?.storeImage ? (
                <img
                  src={formik.values?.storeImage ? storeImage : ""}
                  alt="Store Main Media"
                  height="auto"
                  width={isSmall ? "500px" : isLarge ? "800px" : "900px"}
                  style={{ cursor: "pointer" }}
                  onClick={() => storeImageInputRef.current.click()}
                />
              ) : (
                <Box
                  width="600px"
                  style={{
                    cursor: "pointer",
                    border: " 1px #068ECF dashed",
                  }}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="200px"
                  alt="banner"
                  onClick={() => storeImageInputRef.current.click()}
                >
                  <AddIcon sx={{ color: PRIMARY }} />
                </Box>
              )}
              <input
                type="file"
                ref={storeImageInputRef}
                style={{ display: "none" }}
                id="update-store-image"
                hidden
                onChange={storeImageHandler}
                accept="image/x-png,image/png,image/jpeg,image/jpg"
              />
              {Boolean(formik?.errors?.storeImage) && (
                <Typography color="error.light">
                  {formik.errors.storeImage}*
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

StoreMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(StoreMediaSection);
