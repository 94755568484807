import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import OrderDetails from "components/Orders/OrderDetails";
import { memo, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getOrder, resetOrderState } from "store/slices/ordersSlice";

function OrderDetailsPage() {
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.orders.orderLoading);
  const order = useSelector((state) => state.orders.order);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const marginTopBottom = "15px";
  const marginLeftRight = "30px";
  const pageMargins = useMemo(() => {
    return `@page { margin: ${marginTopBottom} ${marginLeftRight} !important; }`;
  }, []);

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch(getOrder({ orderId: params?.id }));
    }, 500);
    return () => {
      id && clearTimeout(id);
    };
  }, [dispatch, params?.id]);

  useEffect(() => {
    return () => {
      dispatch(resetOrderState());
    };
  }, [dispatch]);

  if (!order && loading) {
    return (
      <Box
        height={200}
        my={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!loading && !order)
    return (
      <Box mt={2}>
        <Typography
          variant="h6"
          component="h2"
          textAlign="center"
          color="text.secondary"
        >
          No order found !
        </Typography>
      </Box>
    );

  return (
    <Stack direction="column" gap={2} py={2} px={1}>
      <style>{pageMargins}</style>
      <OrderDetails ref={componentRef} />
      <Box textAlign="right">
        <Button
          variant="contained"
          onClick={handlePrint}
          size="large"
          sx={{ minWidth: { xs: "auto", md: "150px" } }}
        >
          Print
        </Button>
      </Box>
    </Stack>
  );
}

export default memo(OrderDetailsPage);
