import Box from "@mui/material/Box";
import NotificationForm from "components/Notifications/NotificationForm";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo } from "react";

const Notifications = () => {
  // if (create || update) {
  //   return <NotificationForm />;
  // }
  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={2} px={1}>
        <CustomBreadcrumbs />
      </Box>
      {/* <NotificationActions /> */}
      {/* <NotificationDataGrid /> */}
      <NotificationForm />
    </Box>
  );
};

export default memo(Notifications);
