import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import BasicInfoSection from "components/Orders/OrderDetails/BasicInfoSection";
import BillingAddressSection from "components/Orders/OrderDetails/BillingAddressSection";
import OrderNote from "components/Orders/OrderDetails/OrderNote";
import OrderStatus from "components/Orders/OrderDetails/OrderStatus";
import PackagesSection from "components/Orders/OrderDetails/PackagesSection";
import ShippingAddressSection from "components/Orders/OrderDetails/ShippingAddressSection";
import SummarySection from "components/Orders/OrderDetails/SummarySection";
import { forwardRef } from "react";
import { useSelector } from "react-redux";

const OrderDetails = forwardRef((_, ref) => {
  const order = useSelector((state) => state.orders.order);

  return (
    <Box my={2}>
      <Typography
        variant="h4"
        fontWeight="600"
        fontSize={{ md: "1rem", lg: "1.5rem" }}
        mb={2}
      >
        Order Details
      </Typography>
      <OrderStatus order={order} />
      <Box ref={ref} my={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BasicInfoSection />
          </Grid>
          <Grid item xs={12}>
            <PackagesSection />
          </Grid>
          {order?.orderNote && (
            <Grid item xs={12}>
              <OrderNote />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Stack direction="column" gap={2}>
              <ShippingAddressSection />
              <BillingAddressSection />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <SummarySection />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

OrderDetails.displayName = "OrderDetails";

export default OrderDetails;
