import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DoneIcon from "@mui/icons-material/Done";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import placeholderImg from "assets/images/imagePlaceholder.png";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledSwitch from "components/ToggleButton";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteCategory,
  featuredCategory,
  getCategories,
  updateCategory,
} from "store/slices/categoriesSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import PlusIcon from "../../assets/icons/PlusIcon.png";
import WritingIcon from "../../assets/icons/WritingIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import eyeIcon from "../../assets/icons/eyeIcon.svg";

const DraggableRow = ({ row, index, loading, layoutMode }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row?.id });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLaptop = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  const [showTextField, setShowTextField] = useState(false);
  const [isFeatured, setIsFeatured] = useState(row?.featured);
  const [updatedValue, setUpdatedValue] = useState("");
  const [updatedIndexValue, setUpdatedIndexValue] = useState("");
  const [showSerialField, setShowSerialField] = useState(false);

  const fetchData = useCallback(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const handleChange = (row, event) => {
    const isFeatured = event.target.checked;
    setIsFeatured(isFeatured);
    const data = {
      ...row,
      featured: isFeatured,
    };

    dispatch(featuredCategory(data)).then(() => {
      fetchData();
    });
  };

  const viewSubCategories = useCallback(
    (id) => {
      navigate(id);
    },
    [navigate]
  );

  const addSubCategory = useCallback(
    (id) => {
      navigate(`/products/categories/${id}/subcategory`);
    },
    [navigate]
  );
  const updateProductCategory = useCallback(
    (id) => {
      navigate(`/products/categories/update/${id}`);
    },
    [navigate]
  );

  const handleClickOpen = useCallback(
    (categoryId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(categoryId));
    },
    [dispatch]
  );
  const handleChangeCategoryName = (e) => {
    setUpdatedValue(e.target.value);
  };
  const handleChangeSerialNumber = (e) => {
    setUpdatedIndexValue(e.target.value);
  };
  const handleNameDoubleClick = () => {
    setShowTextField(true);
  };
  const handleSerialDoubleClick = () => {
    setShowSerialField(true);
  };
  const handleSaveCategoryName = () => {
    if (row.id) {
      const data = {
        name: updatedValue,
        id: row.id,
      };
      dispatch(updateCategory(data)).then(() => {
        setShowTextField(false);
        dispatch(getCategories());
      });
    }
  };
  const handleSaveSerialNumber = () => {
    if (row.id) {
      const data = {
        index: updatedIndexValue,
        id: row.id,
      };
      dispatch(updateCategory(data)).then(() => {
        setShowSerialField(false);
        dispatch(getCategories());
      });
    }
  };
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid
      item
      sm={layoutMode === true ? 6 : 12}
      md={layoutMode === true ? 4 : 12}
      padding="1.2px"
      ref={setNodeRef}
      style={style}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "10px",
        }}
      >
        <Box>
          <Grid container alignItems="center">
            <Grid item xs={layoutMode === false ? 1 : 2} textAlign="center">
              <IconButton {...attributes} {...listeners}>
                <DragIndicatorIcon sx={{ mt: "5px", cursor: "pointer" }} />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              {loading ? (
                <Skeleton variant="rectangular" width={20} height={20} />
              ) : showSerialField ? (
                <FormControl
                  variant="standard"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Input
                    id="component-simple"
                    defaultValue={row.index}
                    onChange={handleChangeSerialNumber}
                  />
                  <DoneIcon
                    color="primary"
                    fontSize="small"
                    sx={{ cursor: "pointer" }}
                    onClick={handleSaveSerialNumber}
                  />
                </FormControl>
              ) : (
                <Typography onDoubleClick={handleSerialDoubleClick}>
                  {index + 1}
                </Typography>
              )}
            </Grid>
            <Grid item xs={layoutMode === false ? 2 : 3}>
              <Box sx={{ display: "flex" }} marginY="0.8px">
                {loading ? (
                  <Skeleton variant="rectangular" width={60} height={60} />
                ) : (
                  <img
                    src={row?.mainImage ? row?.mainImage : placeholderImg}
                    height={
                      isTablet
                        ? "45px"
                        : isLaptop
                        ? "55px"
                        : isDesktop
                        ? "60px"
                        : "60px"
                    }
                    width={
                      isTablet
                        ? "45px"
                        : isLaptop
                        ? "55px"
                        : isDesktop
                        ? "60px"
                        : "60px"
                    }
                    style={{
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    alt={row?.name}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={layoutMode === false ? 3 : 5}>
              {loading ? (
                <Skeleton variant="rectangular" width={120} height={30} />
              ) : showTextField ? (
                <FormControl
                  variant="standard"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Input
                    id="component-simple"
                    defaultValue={row.name}
                    onChange={handleChangeCategoryName}
                  />
                  <DoneIcon
                    color="primary"
                    fontSize="small"
                    sx={{ cursor: "pointer" }}
                    onClick={handleSaveCategoryName}
                  />
                </FormControl>
              ) : (
                <Typography
                  onDoubleClick={handleNameDoubleClick}
                  sx={{
                    textTransform: "capitalize",
                    color: "#000000",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box !important",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    whiteSpace: "normal",
                  }}
                >
                  {row?.name}
                </Typography>
              )}
            </Grid>
            {layoutMode === false ? (
              <>
                <Grid item xs={2}>
                  <Typography textAlign={{ xs: "center", md: "left" }}>
                    {loading ? (
                      <Skeleton variant="rectangular" width={40} height={30} />
                    ) : row?.commission ? (
                      row?.commission + "%"
                    ) : (
                      0 + "%"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={1} justifyContent="center">
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={
                      loading ? (
                        <Skeleton animation="wave" height={40} width={50} />
                      ) : (
                        <StyledSwitch
                          checked={isFeatured}
                          onChange={(event) => handleChange(row, event)}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box display="flex" justifyContent="center" sx={actionStyles}>
                    {loading ? (
                      <Skeleton variant="circular" width={30} height={30} />
                    ) : (
                      row?.subCategories.length > 0 && (
                        <Tooltip title="View Sub Category">
                          <Button
                            variant="text"
                            className="button-styles"
                            onClick={() => viewSubCategories(row?.id)}
                          >
                            <img src={eyeIcon} alt="view" width={18} />
                          </Button>
                        </Tooltip>
                      )
                    )}
                    <Tooltip title="Update">
                      {loading ? (
                        <Skeleton variant="circular" width={30} height={30} />
                      ) : (
                        <Button
                          variant="text"
                          className="button-styles"
                          onClick={() => updateProductCategory(row?.id)}
                        >
                          <img src={WritingIcon} alt="delete" width={18} />
                        </Button>
                      )}
                    </Tooltip>
                    <Tooltip title="Delete">
                      {loading ? (
                        <Skeleton variant="circular" width={30} height={30} />
                      ) : (
                        <Button
                          variant="text"
                          sx={{
                            minWidth: "20px",
                          }}
                          onClick={() => handleClickOpen(row?.id)}
                        >
                          <img src={deleteIcon} alt="edit" width={18} />
                        </Button>
                      )}
                    </Tooltip>
                    <Tooltip title="Add Sub Category">
                      {loading ? (
                        <Skeleton variant="circular" width={30} height={30} />
                      ) : (
                        <Button
                          variant="text"
                          className="button-styles"
                          sx={{
                            borderRadius: "50%",
                          }}
                          onClick={() => addSubCategory(row?.id)}
                        >
                          <img src={PlusIcon} alt="view" width={18} />
                        </Button>
                      )}
                    </Tooltip>
                  </Box>{" "}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
        <ConfirmDelete deleteAction={deleteCategory} />
      </Box>
    </Grid>
  );
};

DraggableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    commission: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mainImage: PropTypes.string,
    subCategories: PropTypes.arrayOf(PropTypes.object),
    category: PropTypes.string.isRequired,
    featured: PropTypes.bool.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  moveRow: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  layoutMode: PropTypes.bool.isRequired,
};

export default DraggableRow;

const actionStyles = {
  "& .button-styles": { minWidth: "10px" },
};
