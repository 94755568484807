import Box from "@mui/material/Box";
import SettingSideTabs from "components/Settings/index.jsx";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo } from "react";

function Settings() {
  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <SettingSideTabs />
    </Box>
  );
}

export default memo(Settings);
