import { Stack, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/system/Box";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  getCities,
  updateAddress,
} from "store/slices/checkoutSlice";
import { getProvinces } from "store/slices/storesSlice";
import * as Yup from "yup";

function AddForm({ addressType, isEditing, handleClose, phoneNumber }) {
  const dispatch = useDispatch();
  const defaultAddress = useSelector(
    (state) => state.cart.cartData?.user?.defaultAddress ?? {}
  );
  const provinces = useSelector((state) => state.stores.provinces);

  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);

  const handleProvinceInput = useCallback(
    (value) => {
      if (value) {
        const selectedProvince = provinces?.find(
          (province) => province.province === value
        );
        setCities(() => selectedProvince?.cities ?? []);
      } else {
        setCities(() => []);
        setAreas(() => []);
      }
    },
    [provinces]
  );

  const handleCityInput = useCallback(
    (value) => {
      if (value) {
        const selectedCity = cities.find((city) => city.city === value);
        setAreas(() => selectedCity?.areas ?? []);
      } else {
        setAreas(() => []);
      }
    },
    [cities]
  );

  const [initialValues, setInitialValues] = useState({
    address: "",
    addressType: "",
    city: "",
    country: "",
    province: "",
    area: "",
    zipCode: "",
    // city_code: "",
    fullname: "",
    phone: "",
  });

  const validationSchema = useMemo(() => {
    // Yup.addMethod(Yup.string, "phoneNumber", function (message) {
    //   return this.test(
    //     "phoneNumber",
    //     message || "Invalid phone number",
    //     function (value) {
    //       if (!value) {
    //         return true;
    //       }
    //       let number = value;
    //       if (number[0] !== "+") number = "+" + number;
    //       const phoneNumber = parsePhoneNumber(number);
    //       return phoneNumber ? phoneNumber.isValid() : false;
    //     }
    //   );
    // });

    return Yup.object().shape({
      fullname: Yup.string()
        .trim()
        .required("required")
        .min(4, "Too short")
        .max(25, "Too long"),
      phone: Yup.string().trim().required("required"),
      // .phoneNumber("Please enter a valid phone number"),
      address: Yup.string().trim().required("required").min(15, "Too short"),
      addressType: Yup.string().required("required"),
      province: Yup.string().when("localType", {
        is: (local) => local === "international",
        then: () => Yup.string(),
        otherwise: () => Yup.string().trim().required("required"),
      }),
      city: Yup.string().when("localType", {
        is: (local) => local === "international",
        then: () =>
          Yup.string().trim().required("required").max(20, "Too long"),
        otherwise: () => Yup.string(),
      }),
      area: Yup.string().trim().required("required"),
      // city_code: Yup.string().when("localType", {
      //   is: (local) => local === "local",
      //   then: () => Yup.string().trim().required("required"),
      //   otherwise: () => Yup.string(),
      // }),
      country: Yup.string().when("localType", {
        is: (local) => local === "international",
        then: () =>
          Yup.string().trim().required("required").max(20, "Too long"),
        otherwise: () => Yup.string(),
      }),
      state: Yup.string().when("localType", {
        is: (local) => local === "international",
        then: () =>
          Yup.string().trim().required("required").max(15, "Too long"),
        otherwise: () => Yup.string(),
      }),
      zipCode: Yup.string().when("localType", {
        is: (local) => local === "international",
        then: () =>
          Yup.string().trim().required("required").max(10, "Too long"),
        otherwise: () => Yup.string(),
      }),
      localType: Yup.string(),
    });
  }, []);

  useEffect(() => {
    if (!cities.length) {
      dispatch(getCities());
    }
  }, [dispatch, cities]);

  useEffect(() => {
    if (addressType === "local") {
      dispatch(
        getProvinces(process.env.REACT_APP_ENV === "development" ? "PK" : "SA")
      );
    }
  }, [dispatch, addressType]);

  useEffect(() => {
    setInitialValues({
      address: "",
      addressType: addressType || "",
      city: "",
      country: "",
      province: "",
      area: "",
      zipCode: "",
      fullname: "",
      phone: "",
    });
  }, [addressType]);

  const handleSubmit = (values, { setSubmitting }) => {
    let data = {};
    if (addressType === "international") {
      data.fullname = values.fullname;
      data.address = values.address;
      data.addressType = values.addressType;
      data.city = values.city;
      data.province = values.province;
      data.zipCode = values.zipCode;
      data.country = values.country;
      data.localType = addressType;
      data.area = values.area;
      delete data.addressLine2;
      delete data.city_code;
    } else {
      data.fullname = values.fullname;
      data.address = values.address;
      data.addressType = values.addressType;
      data.city = values.city;
      data.province = values.province;
      data.zipCode = values.zipCode;
      data.country = values.country;
      data.localType = addressType;
      data.area = values.area;
    }
    data.phone = "+" + values.phone;
    if (isEditing) {
      data.addressId = defaultAddress.id;
      data.localType = addressType;

      return dispatch(updateAddress({ data, phoneNumber }))
        .unwrap()
        .then((response) => {
          if (!response.code) {
            handleClose();
          }
          setSubmitting(false);
        });
    } else {
      return dispatch(addAddress({ data, phoneNumber }))
        .unwrap()
        .then((response) => {
          if (!response.code) {
            handleClose();
          }
          setSubmitting(false);
        });
    }
  };
  return (
    <Formik
      validateOnBlur
      validateOnChange
      validateOnMount
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        getFieldProps,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Box component="form" mt={2} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                {...getFieldProps("fullname")}
                error={Boolean(touched.fullname && errors.fullname)}
                helperText={
                  touched.fullname && !!errors.fullname
                    ? errors.fullname
                    : "Enter receiver name"
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInput
                country={addressType === "local" ? "pk" : undefined} // Set to Pakistan for local addresses
                value={values.phone}
                onBlur={setFieldValue}
                countryCodeEditable={false}
                disableDropdown={addressType === "local"}
                onChange={(number, countryData) => {
                  setFieldValue("phone", number);
                  setFieldValue("country", countryData.name);
                }}
                inputProps={{
                  name: "phone",
                }}
                inputStyle={{ width: "100%" }}
              />
            </Grid>

            {addressType === "local" && (
              <>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="address"
                    inputProps={{ maxLength: 90 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    {...getFieldProps("address")}
                  />
                </Grid>
                {/* Province Autocomplete */}
                <Grid item xs={6} lg={6}>
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      fontSize="1.15rem"
                      color="text.secondary"
                    >
                      Province
                    </Typography>
                    <Autocomplete
                      name="province"
                      options={provinces}
                      value={
                        provinces.find(
                          (province) => province.province === values.province
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue("province", value?.province || "");
                        if (!value?.province) {
                          setFieldValue("city", "");
                          setFieldValue("area", "");
                        }
                        handleProvinceInput(value?.province || "");
                      }}
                      onBlur={handleBlur}
                      isOptionEqualToValue={(option, value) =>
                        option.province === value.province
                      }
                      getOptionLabel={(option) => option.province || ""}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          {...params}
                          hiddenLabel={true}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.province}>
                          {option.province}
                        </li>
                      )}
                    />
                  </Stack>
                </Grid>

                {/* City Autocomplete */}
                <Grid item xs={6} lg={6}>
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      fontSize="1.15rem"
                      color="text.secondary"
                    >
                      City
                    </Typography>
                    <Autocomplete
                      name="city"
                      options={cities}
                      value={
                        cities.find((city) => city.city === values.city) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue("city", value?.city || "");
                        if (!value?.city) {
                          setFieldValue("area", "");
                        }
                        handleCityInput(value?.city || "");
                      }}
                      onBlur={handleBlur}
                      isOptionEqualToValue={(option, value) =>
                        option.city === value.city
                      }
                      disabled={!values.province}
                      getOptionLabel={(option) => option.city || ""}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          {...params}
                          hiddenLabel={true}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.city}>
                          {option.city}
                        </li>
                      )}
                    />
                  </Stack>
                </Grid>

                {/* Area Autocomplete */}
                <Grid item xs={6} lg={6}>
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      fontSize="1.15rem"
                      color="text.secondary"
                    >
                      Area
                    </Typography>
                    <Autocomplete
                      name="area"
                      options={areas}
                      value={areas.find((area) => area === values.area) || null}
                      onChange={(_, value) => {
                        setFieldValue("area", value || "");
                      }}
                      onBlur={handleBlur}
                      disabled={!values.city}
                      renderInput={(params) => (
                        <StyledTextField
                          fullWidth
                          {...params}
                          hiddenLabel={true}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option}>
                          {option}
                        </li>
                      )}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={6} lg={6}>
                  <Stack direction="column" gap={1}>
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      fontSize="1.15rem"
                      color="text.secondary"
                    >
                      Zip Code
                    </Typography>
                    <StyledTextField
                      fullWidth
                      type="number"
                      variant="outlined"
                      required
                      inputProps={{ maxLength: 20 }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      {...getFieldProps("zipCode")}
                    />
                  </Stack>
                </Grid>
              </>
            )}
            {addressType === "international" && (
              <>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Address"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="address"
                    inputProps={{ maxLength: 90 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Country"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="country"
                    disabled
                    inputProps={{ maxLength: 20 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Province"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="province"
                    inputProps={{ maxLength: 15 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.province}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="City"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="city"
                    inputProps={{ maxLength: 20 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Area"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="area"
                    inputProps={{ maxLength: 20 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.area}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Zip code"
                    variant="outlined"
                    required
                    fullWidth
                    type="text"
                    name="zipCode"
                    inputProps={{ maxLength: 10 }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zipCode}
                  />
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12} sm={6} lg={6}>
              <TextField
                select
                name="addressType"
                label="Address Type"
                value={values.addressType}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              >
                <MenuItem value="home">Home</MenuItem>
                <MenuItem value="office">Office</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={12}>
              <Button
                disabled={isSubmitting}
                color="primary"
                variant="contained"
                type="submit"
                sx={{ marginTop: "10px" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}

AddForm.propTypes = {
  addressType: PropTypes.string,
  handleClose: PropTypes.func,
  isEditing: PropTypes.bool,
  phoneNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default memo(AddForm);
