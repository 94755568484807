export default function generateAttributeCombinations(attributes) {
  if (attributes.length === 1) {
    return attributes[0].values?.map((value) => ({
      [attributes[0].name]: value,
    }));
  }

  const [firstAttribute, ...remainingAttributes] = attributes;
  const remainingCombinations =
    generateAttributeCombinations(remainingAttributes);

  return firstAttribute.values?.flatMap((value) =>
    remainingCombinations?.map((combination) => ({
      [firstAttribute.name]: value,
      ...combination,
    }))
  );
}
