import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCategory } from "store/slices/categoriesSlice";

function BasicInformationSection({ formik }) {
  const [categoryName, setCategoryName] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    if (formik.values?.category) {
      dispatch(getCategory(formik.values.category)).then((res) => {
        setCategoryName(res?.payload?.name);
      });
    }
  }, [dispatch, formik.values.category]);

  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="You can upload your video ad or video. Please enter title,select thumbnail,
       upload the video and select the products from below list to showcase along with video"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={3}
          p={{ xs: 1, md: 1.5 }}
        >
          <Stack direction="column" gap={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Video Title
                </Typography>
                <StyledTextField
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("title")}
                  error={Boolean(formik.touched.title && formik.errors.title)}
                  helperText={
                    formik.touched.title && !!formik.errors.title
                      ? formik.errors.title
                      : "Enter a descriptive title to help customer find your video"
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Video Category
                </Typography>
                <StyledTextField
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("categoryName")}
                  value={categoryName || ""}
                  error={Boolean(
                    formik.touched.category && formik.errors.category
                  )}
                  disabled={!formik.values?.category} // Disable if no category is selected
                  InputProps={{
                    readOnly: !!formik.values?.category, // Set as read-only if category is selected
                  }}
                />
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

BasicInformationSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BasicInformationSection);
