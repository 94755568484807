import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getStores } from "store/slices/filtersSlice";
import { FILTERS_BACKGROUND_COLOR, FILTER_TEXT_COLOR } from "styles/colors";
import useDebounce from "utils/hooks/useDebounceValue";
import useQueryParams from "utils/hooks/useQueryParams";

function FilterByStore() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const { stores, loading } = useSelector((state) => state.filters);
  const [inputValue, setInputValue] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);

  const deferredValue = useDebounce(inputValue, 500);

  const handleChange = useCallback(
    (_, newValue) => {
      let _params = { ...params };
      if (newValue?.seller) _params.user = newValue?.seller;
      else if (!newValue && _params?.user) {
        delete _params.user;
        setInputValue(() => "");
      }
      setSelectedStore(() => newValue);
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  useEffect(() => {
    if (deferredValue.length > 1 && deferredValue.length < 3) return;
    else if (!deferredValue.length) dispatch(getStores());
    else if (deferredValue.length > 3)
      dispatch(getStores({ name: "brandName", value: deferredValue }));
  }, [dispatch, deferredValue, params?.user]);

  useEffect(() => {
    if (params?.user && stores?.length && selectedStore === null) {
      const _store = stores.find((store) => store.seller === params?.user);
      if (!_store) {
        dispatch(getStores({ seller: params?.user }));
      } else setSelectedStore(() => _store);
    }
  }, [dispatch, params?.user, selectedStore, stores]);

  return (
    <Autocomplete
      sx={{ minWidth: 170, maxWidth: 180 }}
      size="small"
      getOptionLabel={(stores) => stores.brandName}
      isOptionEqualToValue={(option, value) =>
        option.brandName === value.brandName
      }
      options={stores}
      value={selectedStore}
      noOptionsText="No Options Available"
      onInputChange={(_, value) => setInputValue(value)}
      onChange={handleChange}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Store"
          fullWidth
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "1.063rem",
              height: 44,
            },
            "& .MuiInputBase-root": {
              height: 44,
              backgroundColor: FILTERS_BACKGROUND_COLOR,
              cursor: "pointer",
            },
            input: {
              color: FILTER_TEXT_COLOR,
              "&::placeholder": {
                opacity: 1,
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}

export default memo(FilterByStore);
