import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";
import { getUserCart } from "./cartSlice";

export const getAdminAddress = createAsyncThunk(
  "checkout/getAdminAddress",
  async (phoneNumber, { rejectWithValue, dispatch }) => {
    try {
      const response = await http.get(`/address/admin/${phoneNumber}`);
      const responseData = getResponseData(response);
      if (response?.status === 200) {
        dispatch(getUserCart(responseData[0]?.user));
      } else return responseData;
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      // toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const getCities = createAsyncThunk(
  "checkout/getCities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/address/cities`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const setDefaultAddress = createAsyncThunk(
  "checkout/setDefaultAddress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/auth/admin/default-address`, data);
      if (response?.status === 201) {
        toast.success("Address added successfully");
      }
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const addAddress = createAsyncThunk(
  "checkout/addAddress",
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await http.post(
        `/address/admin/${data?.phoneNumber}`,
        data?.data
      );
      const responseData = getResponseData(response);

      const cartId = getState().cart?.cartData?.id ?? getState().cartData?._id;
      const userId = getState().cart?.userId ?? "";
      dispatch(
        setDefaultAddress({
          addressId: responseData?.id ?? "",
          cartId,
          userId,
        })
      );

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const updateAddress = createAsyncThunk(
  "checkout/updateAddress",
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await http.patch(
        `/address/admin/${data?.phoneNumber}`,
        data?.data
      );
      const userId = getState().cart?.userId ?? "";
      dispatch(getUserCart(userId));
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
export const handlePayment = createAsyncThunk(
  "checkout/handlePayment",
  async (data, { dispatch, rejectWithValue, getState }) => {
    try {
      const response = await http.post(`/cart/admin/payment-method`, data);
      const userId = getState().cart?.userId ?? "";
      dispatch(getUserCart(userId));
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const placeAdminOrder = createAsyncThunk(
  "checkout/placeAdminOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/orderDetail/admin`, data);
      toast.success("Order has been placed successfully!");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);
const initialState = {
  results: [],
  addresses: [],
  loading: false,
  error: null,
  cities: [],
  referralCode: "",
  walletNumber: "",
  isSubmitting: false,
  orderDetails: {},
  paymentMethod: "cod",
  transactionFailed: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetErrorState: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminAddress.fulfilled, (state, action) => {
        state.addresses = action.payload;
      })
      .addCase(getAdminAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(setDefaultAddress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setDefaultAddress.fulfilled, (state, action) => {
        state.results = action?.payload;
      })
      .addCase(setDefaultAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        state.addresses.push(action.payload);
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        const updatedAddressIndex = state.addresses.findIndex(
          (address) => address.id === action.payload.id
        );
        state.addresses.splice(updatedAddressIndex, 1, action.payload);
      })
      .addCase(placeAdminOrder.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(placeAdminOrder.fulfilled, (state, action) => {
        state.orderDetails = action.payload;
        state.isSubmitting = false;
      })
      .addCase(placeAdminOrder.rejected, (state) => {
        state.isSubmitting = false;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        const _cities = [...action.payload];
        _cities.splice(0, 1);
        state.cities = _cities.map((city) => ({
          label: city.CITY_NAME,
          city_code: city.CITY_CODE,
        }));
      });
  },
});

export const { resetErrorState } = checkoutSlice.actions;
export default checkoutSlice.reducer;
