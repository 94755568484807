import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ThumbnailPlaceholder from "assets/images/ThumbnailPlaceholder.svg";
import VideoPlaceholder from "assets/images/VideoPlaceholder.svg";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import PropTypes from "prop-types";
import { memo, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setImageValidationError,
  setIsImageValidationDialogOpen,
} from "store/slices/uiSlice";
import validateImageAspectRatio from "utils/helpers/validateImageAspectRatio";

function VideoMediaSection({ formik }) {
  const dispatch = useDispatch();
  const thumbnailInputRef = useRef(null);
  const videoRef = useRef(null);
  const [video, setVideo] = useState(formik.values.video ?? []);
  const [thumbnail, setThumbnail] = useState(formik.values.thumbnail ?? []);

  const thumbnailHandler = useCallback(
    (e) => {
      const image = e.target.files[0];
      if (image) {
        // function params
        // (image, height, width, fixedDimension, ratioValidation, aspectRatio, aspectRatioString)
        validateImageAspectRatio(image, 300, 400, false, true, 3 / 4, "3 / 4")
          .then((img) => {
            thumbnailInputRef.current.value = "";
            formik.setFieldValue("thumbnail", img);
            setThumbnail(URL.createObjectURL(img));
          })
          .catch((error) => {
            thumbnailInputRef.current.value = "";
            dispatch(setIsImageValidationDialogOpen(true));
            dispatch(setImageValidationError(error?.message ?? ""));
          });
      }
    },
    [formik, dispatch]
  );

  const videoHandler = useCallback(
    (e) => {
      formik.setFieldValue("video", e.target.files[0]);
      setVideo(URL.createObjectURL(e.target.files[0]));
      e.target.value = "";
    },
    [formik]
  );

  return (
    <StyledMuiCard
      title="Media Section"
      subTitle="Upload image and video relevant to the Video"
    >
      <CardContent>
        <Box display="flex" flexWrap="wrap" gap={4}>
          {/* Category Thumbnail */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Video Thumbnail
            </Typography>
            <Box>
              <img
                ref={thumbnailInputRef}
                src={formik.values.thumbnail ? thumbnail : ThumbnailPlaceholder}
                alt="Video Thumbnail"
                width="300px"
                height="400px"
                style={{ cursor: "pointer" }}
                onClick={() => thumbnailInputRef.current.click()}
              />
              <input
                type="file"
                style={{ display: "none" }}
                hidden
                ref={thumbnailInputRef}
                onChange={thumbnailHandler}
                accept="image/x-png,image/png,image/jpeg,image/jpg/*"
              />
              {Boolean(formik.errors.thumbnail) && (
                <Typography color="#ef5350">
                  {formik.errors.thumbnail}*
                </Typography>
              )}
            </Box>
          </Stack>

          {/* Category Video file */}
          <Stack direction="column" gap={2}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Video
            </Typography>
            <Box display="flex" flexDirection="column" gap={2}>
              <video
                ref={videoRef}
                controls
                src={formik.values.video ? video : null}
                alt="video"
                height="400px"
                width="280px"
                style={{ cursor: "pointer" }}
                poster={formik.values.video ? null : VideoPlaceholder} // Show the thumbnail if no video
                onClick={() => videoRef.current.click()}
              />
              <input
                type="file"
                style={{ display: "none" }}
                hidden
                ref={videoRef}
                accept="video/mp4,video/x-m4v,video/*"
                onChange={videoHandler}
              />
              {Boolean(formik.errors.video) && (
                <Typography color="#ef5350">{formik.errors.video}*</Typography>
              )}
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

VideoMediaSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(VideoMediaSection);
