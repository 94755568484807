import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import CurrencyFormatter from "components/CurrencyFormatter";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { deleteVoucher, getVouchers } from "store/slices/vouchersSlice";
import { ACTIVE_COLOR } from "styles/colors";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const dispatch = useDispatch();
  const { results, loading, totalPages } = useSelector(
    (state) => state.vouchers
  );
  const params = useQueryParams();
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    dispatch(getVouchers(params));
  }, [dispatch, params]);

  const handleClickOpen = useCallback(
    (userId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(userId));
    },
    [dispatch]
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchData();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchData]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 40,
        flex: 0.2,
        headerAlign: "center",
        align: "center",
        filterable: false,
      },
      {
        field: "title",
        headerName: "Title",
        minWidth: 160,
        flex: 2,
        renderCell: (params) => (
          <Typography
            sx={{
              textTransform: "capitalize",
              color: "#000000",
              textOverflow: "ellipsis",
              overflow: "hidden",
              display: "-webkit-box !important",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              whiteSpace: "normal",
            }}
          >
            {params?.row?.title}
          </Typography>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 70,
        flex: 0.7,
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.amount} />
        ),
      },
      {
        field: "limit",
        headerName: "Limit",
        headerAlign: "center",
        align: "center",
        minWidth: 70,
        flex: 0.7,
        renderCell: (params) => (
          <Typography>{params?.row?.limit ? params?.row?.limit : 0}</Typography>
        ),
      },
      {
        field: "type",
        headerName: "Voucher Type",
        headerAlign: "center",
        align: "center",
        minWidth: 70,
        flex: 0.7,
        renderCell: (params) => (
          <Typography sx={{ textTransform: "capitalize" }}>
            {params?.row?.type}
          </Typography>
        ),
      },
      {
        field: "voucher",
        headerName: "Voucher Code",
        headerAlign: "center",
        align: "center",
        minWidth: 70,
        flex: 0.7,
        renderCell: (params) => (
          <Box
            sx={{
              width: "150px",
              overflow: "hidden",
            }}
          >
            <Typography
              noWrap
              sx={{
                textOverflow: "ellipsis",
              }}
            >
              {params?.row?.voucher}
            </Typography>
          </Box>
        ),
      },
      {
        field: "quantity",
        headerName: "Quantity",
        headerAlign: "center",
        align: "center",
        minWidth: 70,
        flex: 0.7,
        renderCell: (params) => (
          <Typography>{params?.row?.quantity}</Typography>
        ),
      },
      {
        field: "startDate",
        headerName: "Time Duration",
        minWidth: 210,
        flex: 1.5,
        type: "dateTime",
        renderCell: (params) => (
          <Box>
            <Typography color={ACTIVE_COLOR}>
              {dayjs(params?.row?.startDate).format("YYYY/MM/DD [At] hh:mm:ss")}
            </Typography>
            <Typography>
              {dayjs(params?.row?.endDate).format("YYYY/MM/DD [At] hh:mm:ss")}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 80,
        flex: 0.7,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <Chip
            sx={{
              backgroundColor: statusBadges(params?.row?.status).bgColor,
              color: statusBadges(params?.row?.status).color,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.status}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        minWidth: 80,
        flex: 0.5,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <Box display="flex" sx={actionStyles}>
            <Button
              variant="text"
              className="button-styles"
              onClick={() => navigate(`update/${params.id}`)}
            >
              <img src={WritingIcon} alt="edit" className="icon-size" />
            </Button>
            <Button
              variant="text"
              sx={{
                minWidth: "20px",
              }}
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="edit" className="icon-size" />
            </Button>
          </Box>
        ),
      },
    ],
    [navigate, handleClickOpen]
  );
  return (
    <>
      <StyledGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteVoucher} />
    </>
  );
}

const actionStyles = {
  "& .button-styles": { minWidth: "20px", marginLeft: "4px" },
  "& .icon-size": { height: "25px", width: "25px" },
};
