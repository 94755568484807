import { store } from "store/configureStore";

export function isTokenExpired() {
  const tokens = store.getState()?.auth?.data?.tokens ?? "";
  return new Date().getTime() > new Date(tokens?.access?.expires).getTime();
}
export function isRefreshTokenExpired() {
  const tokens = store.getState()?.auth?.data?.tokens ?? "";
  return new Date().getTime() > new Date(tokens?.refresh?.expires).getTime();
}
