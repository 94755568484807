import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { memo } from "react";
import { useSelector } from "react-redux";
import { CARD_HEADER_BACKGROUND } from "styles/colors";

function ShippingAddressSection() {
  const order = useSelector((state) => state.fullOrders.order);

  return (
    <Card>
      <CardContent
        sx={{
          p: 0,
          "&:last-Child": {
            p: 0,
          },
        }}
      >
        <Box bgcolor={CARD_HEADER_BACKGROUND} p={2}>
          <Typography variant="h6" fontWeight="bold">
            Shipping Address
          </Typography>
        </Box>
        <Box p={2}>
          <Box display="flex" gap={2}>
            <Chip
              label={order?.orderAddress?.addressType?.toUpperCase() ?? "N/A"}
              color="primary"
              sx={{ mb: 1 }}
            />
            <Chip
              label={order?.orderAddress?.localType.toUpperCase() ?? "N/A"}
              color="primary"
              sx={{ mb: 1 }}
            />
          </Box>
          <Typography
            variant="body1"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {order?.customer?.fullname ?? ""}
          </Typography>
          <Typography
            variant="body1"
            textTransform="capitalize"
            sx={{
              overflowWrap: "break-word",
            }}
          >
            {order?.orderAddress?.address ?? ""}
          </Typography>
          <Typography variant="body1">
            {order?.orderAddress?.phone ?? ""}
          </Typography>
          <Typography variant="body1">
            Province: {order?.orderAddress?.province ?? ""}
          </Typography>
          <Typography variant="body1">
            City: {order?.orderAddress?.city ?? ""}
          </Typography>
          <Typography variant="body1">
            Area: {order?.orderAddress?.area ?? ""}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default memo(ShippingAddressSection);
